<template>
  <div class="login">
    <el-container>
      <el-main>
        <router-view>
        </router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
  export default {
    name: "Login",
    data() {

      return {

      };

    },
    methods: {

    }
  }
</script>

<style scoped>
  .el-main {
    width: 100%;
    height: 100%;
  }

  /*.el-main {*/
  /*  background-color: #BFD7ED;*/
  /*  color: #333;*/
  /*  height: 100vh;*/
  /*}*/

</style>