const community = {
    state: {
        post: {},
        viewing_posts: {},
    },
    mutations: {
        SET_POST: (state, post) => {
            state.post = post;
        },
        SET_VIEWING_POSTS: (state, viewing_posts) => {
            state.viewing_posts = viewing_posts;
        },
    }
}

export default community