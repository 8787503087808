import request from '@/utils/request'

export function get_departments(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/home/get_dp',
        method: 'post',
        data: data
    })
}

export function get_fav_departments(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/home/get_fdp',
        method: 'post',
        data: data
    })
}

export function insert_fav_department(email, dpId) {
    const data = {
        email: email,
        dpId: dpId, //院所ID
    }
    return request({
        url: '/home/insert_fdp',
        method: 'post',
        data: data
    })
}


export function remove_fav_department(email, dpId) {
    const data = {
        email: email,
        dpId: dpId, //院所ID
    }
    return request({
        url: '/home/delete_fdp',
        method: 'post',
        data: data
    })
}

export function get_dp_type(email, dpId, infoType) {
    const data = {
        email: email,
        dpId: dpId, //院所ID
        infoType: infoType
    }
    return request({
        url: '/home/my_file4',
        method: 'post',
        data: data
    })
}

export function get_headline_news(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/home/get_current_news',
        method: 'post',
        data: data
    })
}

export function get_fav_news(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/home/get_fnews',
        method: 'post',
        data: data
    })
}

/*
 * 说明：获取收藏的新闻
 * 请求参数：
 * 字段  说明 类型 备注 是否必填
 * email 邮箱 string 是
 * infoType 新闻类型 int 1 表示保研，0 表示考研，2 表示全部，可根据用户的选择传输对应的参数 是
 * dpId 院系ID int 如果用户选择具体的院系，就填写该院系的ID号，否则填-1，表示全选，这块也改成下拉框吧，下拉框内的数据会从/home/get_fnews这个接口返回的dps 获得 是
 * year 年份 string 如，’2023’表示选择2023年的信息，这块改成下拉框吧，就提供全部、2024、2023、2022、2021，这五个选项，如果用户选择全部，就传输空字符串’’，否则就传输对应的年份的字符串，如选择了2024，就传输字符串’2024’ 是
 * content 新闻 string 即用户填写在新闻框里的真实内容，如果用户没有填内容，就传输空字符串'' 是
 */
export function get_filtered_fav_news(email, infoType, dpId, year, content) {
    const data = {
        email: email,
        infoType: infoType,
        dpId: dpId,
        year: year,
        content: content,
    }
    return request({
        url: '/home/fuzzy_get_fnews',
        method: 'post',
        data: data
    })
}

export function insert_fav_news(email, newsId) {
    const data = {
        email: email,
        newsId: newsId, //院所ID
    }
    return request({
        url: '/home/insert_fnews',
        method: 'post',
        data: data
    })
}

export function remove_fav_news(email, newsId) {
    const data = {
        email: email,
        newsId: newsId, //院所ID
    }
    return request({
        url: '/home/delete_fnews',
        method: 'post',
        data: data
    })
}