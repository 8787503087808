import Vue from 'vue'
import Vuex from 'vuex'
import getters from "./getters";
import user from "./modules/user"
import file from "./modules/file";
import image from "./modules/image"
import doc from "./modules/doc"
import home from "./modules/home"
import community from "./modules/community"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    file,
    image,
    doc,
    home,
    community,
  },
  getters
})
