<template>
  <div style="text-align: center">
    <el-row type="flex" justify="center" class="formRow">
      <el-button class="newBtn" @click="$router.push('/intelligence/new_plan')" icon="el-icon-plus">新建</el-button>
      <div class="title">我的计划书</div>
      <el-input class="search" placeholder="搜索标题" v-model="searchKeywords" clearable @clear="clearSearch" @keyup.enter.native="handleSearch"/>
      <el-button class="btns" @click="handleSearch">搜索</el-button>
    </el-row>
    <el-table :data="curView_page" v-loading="loading" stripe @row-click="handleRowClick"
        :default-sort = "{prop: 'modify_time', order: 'descending'}">
      <el-table-column prop="proId" label="序号" width="80px" sortable></el-table-column>
      <el-table-column prop="title" label="标题" sortable></el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="150px" sortable></el-table-column>
      <el-table-column prop="modify_time" label="修改时间" width="150px" sortable></el-table-column>
      <el-table-column prop="status"label="进度" width="150px" show-overflow-tooltip sortable>
        <template slot-scope="scope">
          <el-select v-model="scope.row.status" placeholder="状态" @change='updateStatus(scope.row)'>
            <el-option label="未开始" value=1></el-option>
            <el-option label="进行中" value=2></el-option>
            <el-option label="已完成" value=3></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column id="deleteCol" prop="link" label="操作" width="100px">
        <template slot-scope="scope">
          <el-button id="deleteBtn" class="el-icon-delete" type="text" @click.stop="handleDelete(scope.$index, scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[5,10,15]" :page-size="pagesize" layout="total,jumper,prev, pager, next,sizes" :total="files_count">
    </el-pagination>
  </div>
</template>

<script>
import { get_plans, delete_plan, update_plan } from "@/network/intelligence";

export default {
  name: "Plans",
  data() {
    return {
      plans: [],
      plansShowing: [],
      curView_page: [],
      searchKeywords: '',
      currentPage:1,
      pagesize:10,
      files_count:0,
      loading: false,
      email: '',
      isInSearch: false,
    }
  },
  methods: {
    clearSearch() {
      this.plansShowing = this.plans;
      this.isInSearch = false;
      this.hadleGetFilesListApi();
    },
    updateStatus(row) {
      update_plan(this.email, row.proId, row.title, row.content, row.start_time, row.due_time, row.status).then((res) => {
        if (res.flag === 0) {
          this.$message({
            type: 'warning',
            message: '状态更新失败，请稍后重试'
          });
          this.fetchData();
        }
      })
    },
    handleDelete(index, row) {
      this.$confirm('是否要删除计划', '系统提示', {
        confirmButtonText: '确认',
        cancelButtonText: '返回',
        type: 'info'
      }).then(() => {
        this.loading = true;
        delete_plan(this.email, row.proId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已删除计划'
            });
            this.fetchData();
            this.isInSearch && this.handleSearch();
          }
          else {
            this.$message({
              type: 'warning',
              message: '删除计划失败，请稍后重试'
            });
          }
        });
      }).catch(() => {});
      document.activeElement.blur();
    },
    handleSearch() {
      this.isInSearch = true;
      this.plansShowing = this.plans.filter((p)=>{
        return p.title.indexOf(this.searchKeywords) !== -1;
      })
      document.activeElement.blur();
      this.hadleGetFilesListApi();
    },
    //分页 初始页currentPage、初始每页数据数pagesize和数据testpage--->控制每页几条
    handleSizeChange:function(size){
      this.pagesize = size;
      this.hadleGetFilesListApi();
      console.log(this.pagesize);
    },
    // 控制页面的切换
    handleCurrentChange: function(currentPage) {
      this.currentPage = currentPage;
      this.hadleGetFilesListApi();
    },
    //对所有数据进行分页处理 发送请求
    hadleGetFilesListApi() {
      this.curView_page = this.plansShowing.slice((this.currentPage-1)*this.pagesize, this.currentPage*this.pagesize);
      this.files_count = this.plansShowing.length;
      this.loading = false;
    },
    handleRowClick(row) {
      this.$router.push({
        name: 'new_plan', params: { 
          plan: row
        }
      });
    },
    fetchData() {
      this.loading = true;
      get_plans(this.email).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          this.plans = res.data;
          this.plans.forEach(plan => {
            plan['status'] = plan.status.toString();
          });
          this.plansShowing = this.plans;
        }
        this.hadleGetFilesListApi();
      }).catch((error) => {
        console.log(error);
      });
      document.activeElement.blur();
    },
  },
  created() {
    this.email = this.$store.getters.email;
    this.fetchData();
  },
}
</script>

<style scoped>
.newBtn {
  height: 40px;
  align-items: center;
  margin-right: 20px;
  border: none;
}
.newBtn:hover {
  color: #f3e6f6;
  background-color: #7a557b;
}
.title {
  margin-right: 20px;
  border-radius: 10px;
  border: 1px #7a557b solid;
  background-color: white;
  color: #7a557b;
  text-align: center;
  align-content: center;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  width: 50%;
}
.formRow {
  margin: 20px;
}
.btns {
  align-items: center;
  margin-left: 10px;
  border: none;
}
.btns:hover {
  background-color: rgb(226, 226, 226);
  color: #7a557b;
}
.el-table {
  border-radius: 5px;
  margin: 10px auto;
}
.search {
  width: 300px;
}

.searchNickname {
  width: 200px;
}

.search ::v-deep .el-input__inner:focus{
  border-color: #7a557b;
}

#deleteBtn {
  border: 0px;
  background-color: transparent;
  color: #7a557b;
}

#deleteBtn:hover {
  background-color: transparent;
  color: #d6c5db;
}
.el-pagination {
  margin: 20px 0;
}
::v-deep .el-select-dropdown__item.selected {
  color: #7a557b;
}

::v-deep .el-input__inner:focus, ::v-deep .el-select .el-input.is-focus .el-input__inner{
  border-color: #7a557b;
}
::v-deep .el-input__inner {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
</style>