<template>
  <div class="firstShow" v-loading="loading">
    <div class="fixedHead">
      <el-row type="flex" justify="start" class="formRow">
        <div class="title">时间流</div>
      </el-row>
      <div class="hint">鼠标悬停在节点可查看计划书详情。点击节点可显示计划书详情，再次点击可关闭详情</div>
      <div class="utils">
        <div class="statusDescDiv">
          <div class="statusDesc">
            <div class="samepleDot white"></div>
            <div class="statusText">未开始</div>
          </div>
          <div class="statusDesc">
            <div class="samepleDot blue"></div>
            <div class="statusText">进行中</div>
          </div>
          <div class="statusDesc">
            <div class="samepleDot gold"></div>
            <div class="statusText">已完成</div>
          </div>
        </div>
        <div class="sliderDiv">
          <span class="demonstration">时间流比例缩放</span>
          <el-slider v-model="enlargement" :format-tooltip="changeTimelineSize"></el-slider>
        </div>
      </div>
    </div>
    <div class="plan-list">
      <div class="flex-viewport" :style="{'width': `${timelineLength}`+ 'px' }">
        <ul class="slides list">
          <li class="planList" :style="{'padding-left': `${plan.dynamicInterval}` + 'px' }" v-for="(plan, index) in timeline">
            <div :class="['dot', plan.color]" @click="togglePlanDetail(plan)">
            </div>
            <div class="planDetail hidden">
              <p class="planTitle">{{ plan.title }}</p>
              <p>状态：{{ plan.status }}</p>
              <p>开始时间：{{ plan.start_time }}</p>
              <p>结束时间：{{ plan.due_time }}</p>
            </div>
            <div class="planDetail" v-if="plan.showing">
              <el-button class="closeBtn" icon="el-icon-error" @click.native="togglePlanDetail(plan)"></el-button>
              <p class="planTitle">{{ plan.title }}</p>
              <p>状态：{{ plan.status }}</p>
              <p>开始时间：{{ plan.start_time }}</p>
              <p>结束时间：{{ plan.due_time }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { get_timeline } from "@/network/intelligence";

export default {
  name: "PlansTimeline",
  data() {
    return {
      timeline: [],
      loading: true,
      email: '',
      timelineDefaultLength: 0,
      timelineLength: 0,
      showing: false,
      enlargement: 10,
    };
  },
  methods: {
    changeTimelineSize(val) {
      this.timeline.forEach(t => {
        t['dynamicInterval'] = (t.interval/5) * val/10;
      });
      this.timelineLength = this.timelineDefaultLength * val/10 + 100;
    },
    togglePlanDetail(plan) {
      plan.showing = !plan.showing;
      this.$forceUpdate();
    },
    fetchData() {
      get_timeline(this.email).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          this.timeline = res.data;
          this.timeline.forEach(t => {
            this.timelineDefaultLength += t.interval/5;
            t['dynamicInterval'] = t.interval /5;
            t['color'] = t.status === 1 ? 'white' :(t.status === 2 ? 'blue' : 'gold') //1 未开始，2 正在进行，3 已完成
            t['status'] = t.status === 1 ? '未开始' :(t.status === 2 ? '正在进行' : '已完成') //1 未开始，2 正在进行，3 已完成
            t['showing'] = false;
          });
          this.timelineLength = this.timelineDefaultLength + 100;
          this.enlargement = Math.floor((950/this.timelineDefaultLength) * 10);
        }
        this.loading = false;
      }).catch((error) => {
        console.log(error);
      });
    },
  },
  created() {
    this.email = this.$store.getters.email;
    this.fetchData();
  },
}
</script>

<style scoped>
::v-deep .el-slider__bar {
  background-color: #7a557b;
}
::v-deep .el-slider__runway {
  background-color: #ac9bad;
}
::v-deep .el-slider__button {
  border: 2px solid #7a557b;
  background-color: #FFF;
}
.sliderDiv {
  width: 300px;
  float: right;
}
.utils {
  display: inline;
}
.fixedHead {
  position: fixed;
  z-index: 1000;
}
.planList {
  width: 0px;
}
.planTitle {
  color: #7a557b;
  text-wrap: wrap;
  overflow-wrap: break-word;
}
.hint {
  text-align: start;
  font-weight: 500;
  color: #7a7a7a;
  margin-top: 20px;
}

.title {
  margin-right: 20px;
  border-radius: 10px;
  border: 1px #7a557b solid;
  background-color: white;
  color: #7a557b;
  text-align: center;
  align-content: center;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  width: 900px;
}
.closeBtn {
  position: absolute;
  top: 245px;
  right: -5px;
  border: none;
  padding: 0;
  background-color: transparent;
}
.closeBtn:hover {
  color: #7a557b;
  background-color: transparent;
}
.planDetail {
  margin-top: 250px;
  text-align: start;
  padding: 0 10px;
  height: 180px;
  overflow: auto;
  border: #C7C7C7 1px solid;
  background-color: white;
  border-radius: 10px;
  width: 240px;
  float: inline;
}

.dot:hover + .planDetail {
  display: block;
}

.plan-list {
  padding-top: 100px;
}
 
.plan-list .flex-viewport {
  padding: 0 50px;
  overflow: auto; 
  position: relative;
  height: 450px;
  align-self: center;
}
 
.plan-list .flex-viewport:before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  border-top: 1px solid #C7C7C7;
  content: '';
}
 
.plan-list .slides li {
  position: relative;
  height: 300px;
  float: left; 
  display: block;
}
 
.plan-list .slides li .dot {
  position: absolute;
  top: 265px;
  z-index: 1000;
}
.statusDescDiv {
  text-align: start;
  width: fit-content;
  float: left;
}
.statusDesc {
  display:inline-block;
  text-align: start;
  width: 50px;
  margin-left: 20px;
}

.statusText {
  display: inline;
}

.samepleDot {
  margin: 10px 0;
  height: 100%;
}

.samepleDot:before {
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; 
}
.samepleDot:after {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: -16px;
  margin-bottom: 4px;
  content: '';
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.plan-list .slides li .dot:before {
  position: absolute;
  top: -49px;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  content: '';
}

.plan-list .slides li .dot:hover:before {
  background-color: #d1b5d2;
}

.plan-list .slides li .dot:after {
  position: absolute;
  top: -45px;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  content: '';
}
 
.plan-list .slides li .dot:hover:after {
  background-color: #7a557b;
}

.white:before {
  background-color: #cdcdcd;
}
.white:after {
  background-color: #acacac;;
}

.blue:before {
  background-color: #a9b9ef;
}
.blue:after {
  background-color: #536bb9;;
}

.gold:before {
  background-color: #d8c45f;
}
.gold:after {
  background-color: #bba63d;;
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
}
 
.hidden {
  display: none;
}
</style>