<template>
  <div class="container">
    <el-container class="home">
      <el-page-header @back="goBack" content="DAWN - 个人定位报告"/>
      <el-main class="belowTop">
        <h1>保研个人定位报告</h1>
        <el-form class="form" :model="fillForm" ref="fillForm" :rules="rules" label-width="auto">
          <!-- Page 1 -->
          <div v-if="currentPage === 1">
            <el-col :span="12">
              <el-form-item label="姓名" prop="name">
                <el-input v-model="fillForm.name" placeholder="若不填写，则默认使用账户昵称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="性别" prop="sex">
              <el-select v-model="fillForm.sex" placeholder="性别" :popper-append-to-body="false">
                <el-option label="女" value=0></el-option>
                <el-option label="男" value=1></el-option>
                <el-option label="不选择" value=2></el-option>
              </el-select>
            </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="本科学校" prop="school">
                <el-input v-model="fillForm.school"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="本科专业" prop="major">
                <el-input v-model="fillForm.major"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="绩点排名" prop="rank">
                <el-input v-model="fillForm.rank"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="专业人数" prop="studentNumber">
                <el-input v-model="fillForm.studentNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="英语四级分数" prop="cet4">
                <el-input v-model="fillForm.cet4" placeholder="若未参加过则填0"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="英语六级分数" prop="cet6">
                <el-input v-model="fillForm.cet6" placeholder="若未参加过则填0"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="国家级竞赛奖项数量" prop="contest1">
                <el-input v-model="fillForm.contest1"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="省市级竞赛奖项数量" prop="contest2">
                <el-input v-model="fillForm.contest2"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="已录用SCI论文数量" prop="sciNumber">
                <el-input v-model="fillForm.sciNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="已录用EI或IEEE检索论文数量" prop="eiNumber">
                <el-input v-model="fillForm.eiNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="已录用中文普刊论文数量" prop="ordinaryPaperNumber">
                <el-input v-model="fillForm.ordinaryPaperNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="已授权发明专利数量" prop="patentNumber">
                <el-input v-model="fillForm.patentNumber"></el-input>
              </el-form-item>
            </el-col>
          </div>
          <!-- Page 2 -->
          <div v-if="currentPage === 2">
            <el-col :span="12">
              <el-form-item label="参加的国家级重点科研项目数量" prop="project1">
                <el-input v-model="fillForm.project1"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否曾担任学生干部" prop="studentCadre">
                <el-select v-model="fillForm.studentCadre" :popper-append-to-body="false">
                  <el-option label="是" value=1></el-option>
                  <el-option label="否" value=0></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="参加的省市级重点科研项目数量" prop="project2">
                <el-input v-model="fillForm.project2"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="有无校企实习经历" prop="internship">
                <el-select v-model="fillForm.internship" :popper-append-to-body="false">
                  <el-option label="有" value=1></el-option>
                  <el-option label="无" value=0></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="参加的校级重点科研项目数量" prop="project3">
                <el-input v-model="fillForm.project3"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="研究生目标报考的院校及专业（1）" prop="target1">
                <el-input v-model="fillForm.target1" placeholder="必填"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="研究生目标报考的院校及专业（2）" prop="target2">
                <el-input v-model="fillForm.target2" placeholder="选填"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="研究生目标报考的院校及专业（3）" prop="target3">
                <el-input v-model="fillForm.target3" placeholder="选填"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="研究生目标报考的院校及专业（4）" prop="target4">
                <el-input v-model="fillForm.target4" placeholder="选填"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="研究生目标报考的院校及专业（5）" prop="target5">
                <el-input v-model="fillForm.target5" placeholder="选填"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否愿意我们通过您预留的联系方式联系您呢" prop="contact">
                <el-select v-model="fillForm.contact" @change="handleShowContact" :popper-append-to-body="false">
                  <el-option label="是" value=1></el-option>
                  <el-option label="否" value=0></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item v-model="showContact" v-if="showContact" label="手机号" prop="telephone">
                <el-input v-model="fillForm.telephone" placeholder="选填"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item v-model="showContact" v-if="showContact" label="微信号" prop="weixin">
                <el-input v-model="fillForm.weixin" placeholder="选填"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item v-model="showContact" v-if="showContact" label="QQ" prop="qq">
                <el-input v-model="fillForm.qq" placeholder="选填"></el-input>
              </el-form-item>
            </el-col>
          </div>

          <!-- Navigation Buttons -->
          <el-col :span="24">
            <div class="btnsDiv">
              <el-button class="btns prevBtn" v-if="currentPage > 1" @click="prevPage">上一页</el-button>
              <el-button class="btns nextBtn" v-if="currentPage < totalPages" @click="nextPage">下一页</el-button>
              <el-button class="btns submitBtn" v-if="currentPage === totalPages" @click="handleSubmitForm">一键生成报告</el-button>
            </div>
          </el-col>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>   
<script>
  import {request_get_information} from "@/network/profile";
  import {get_report} from "@/network/ai";
  export default {
    name: "AiReport",
    data() {
      const checkInt = (rule, value, callback) => {
        if (value) {
          var reg = /^[0-9]*$/;
          if (reg.test(value) === false) {
            callback(new Error('只可输入整数'));
          } else {
            callback();
          }
        } else {
          callback();
        }
      };
      const checkPhone = (rule, value, callback) => {
        if (value) {
          var reg = /^\d{11}$/;
          if (reg.test(value) === false) {
            callback(new Error('请输入11位数字的手机号'));
          } else {
            callback();
          }
        } else {
          callback();
        }
      };
      return {
        email: '',
        currentPage: 1,
        totalPages: 2,
        loading: false,
        showContact: false,
        fillForm: {
          name: '', sex: '', school: '', major: '', rank: '', studentNumber: '', cet4: '', cet6: '', 
          contest1: '', contest2: '', sciNumber: '', eiNumber: '',ordinaryPaperNumber: '',
          patentNumber: '', project1: '', project2: '', project3: '', studentCadre: '', internship: '', 
          target1: '', target2: '', target3: '', target4: '', target5: '', contact: '', telephone: '', weixin: '', qq: '',
        },
        rules: {
          sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
          school: [{ required: true, message: '请填写学校', trigger: 'blur' }],
          major: [{ required: true, message: '请填写专业', trigger: 'blur' }],
          rank: [
            { required: true, trigger: 'change', validator: checkInt},
            { required: true, message: '此项为必填，不能为空', trigger: 'blur' }
          ],
          studentNumber: [
            { required: true, trigger: 'change', validator: checkInt},
            { required: true, message: '此项为必填，不能为空', trigger: 'blur' }
          ],
          cet4: [
            { required: true, trigger: 'change', validator: checkInt},
            { required: true, message: '请填写英语四级分数, 若未参加过则填0', trigger: 'blur'}
          ],
          cet6: [
            { required: true, trigger: 'change', validator: checkInt},
            { required: true, message: '请填写英语六级分数, 若未参加过则填0', trigger: 'blur'}
          ],
          contest1: [
            { required: true, trigger: 'change', validator: checkInt},
            { required: true, message: '此项为必填，不能为空', trigger: 'blur' }
          ],
          contest2: [
            { required: true, trigger: 'change', validator: checkInt},
            { required: true, message: '此项为必填，不能为空', trigger: 'blur' }
          ],
          sciNumber: [
            { required: true, trigger: 'change', validator: checkInt},
            { required: true, message: '此项为必填，不能为空', trigger: 'blur' }
          ],
          eiNumber: [
            { required: true, trigger: 'change', validator: checkInt},
            { required: true, message: '此项为必填，不能为空', trigger: 'blur' }
          ],
          ordinaryPaperNumber: [
            { required: true, trigger: 'change', validator: checkInt},
            { required: true, message: '此项为必填，不能为空', trigger: 'blur' }
          ],
          patentNumber: [
            { required: true, trigger: 'change', validator: checkInt},
            { required: true, message: '此项为必填，不能为空', trigger: 'blur' }
          ],
          project1: [
            { required: true, trigger: 'change', validator: checkInt},
            { required: true, message: '此项为必填，不能为空', trigger: 'blur' }
          ],
          project2: [
            { required: true, trigger: 'change', validator: checkInt},
            { required: true, message: '此项为必填，不能为空', trigger: 'blur' }
          ],
          project3: [
            { required: true, trigger: 'change', validator: checkInt},
            { required: true, message: '此项为必填，不能为空', trigger: 'blur' }
          ],
          studentCadre: [{ required: true, message: '此项为必选，不能为空', trigger: 'blur' }],
          internship: [{ required: true, message: '此项为必选，不能为空', trigger: 'blur' },],
          target1: [{ required: true, message: '必需填第一项研究生目标报考的院校及专业', trigger: 'blur' }],
          contact: [{ required: true, message: '此项为必填，不能为空', trigger: 'blur' }],
          telephone: [{ trigger: 'change', validator: checkPhone},],
        },
      };
    },
    methods: {
      goBack() {
        this.$router.back();
      },
      nextPage() {
        this.$refs.fillForm.validate((valid) => {
          if (valid) {
            this.currentPage++;
          } else {
            this.$message({
              type: 'warning',
              message: '填写未完成/不符合要求，无法进入下一页'
            });
            return false;
          }
        });
      },
      prevPage() {
        this.currentPage--;
      },
      handleShowContact(value) {
        this.showContact = (value === '1');
      },
      handleSubmitForm() {
        if (this.fillForm.name === '') {
          request_get_information(this.email).then((res) => {
            if (res !== undefined && res !== null) {
              if (res.nickname !== undefined && res.nickname !== null){
                this.fillForm.name = res.nickname;
                this.submitForm();
              }
              else {
                this.fillForm.name = null;
                this.$message({
                  type: 'warning',
                  message: '您尚未填写过昵称，所以姓名不能留空'
                });
              }
            }
            else {
              this.$message({
                type: 'error',
                message: '获取呢称失败，请稍后重试!'
              });
            }
          })
        }
        else {
          this.submitForm();
        }
      },
      submitForm() {
        this.$refs.fillForm.validate((valid) => {
          if (valid) {
            // const url = "https://postgraduate.xsaifor.com/prod-api/intelligence/get_position_report?email="+this.email+'&form=' + JSON.stringify(this.fillForm);
            // window.open(url);
            get_report(this.email, JSON.stringify(this.fillForm));
          } else {
            this.$message({
              type: 'warning',
              message: '填写未完成/不符合要求，暂时无法生成报告'
            });
            return false;
          }
        });
      }
    },
    created() {
      this.email = this.$store.getters.email;
    },
  }
</script>

<style scoped>
  .home {
    overflow: hidden;
  }
  .el-container {
    margin: 0px;
  }
  .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .el-form {
    width: 70%;
    margin: 0 auto;
  }

  .belowTop {
    text-align: center;
    margin-top: 60px;
    height: calc(100vh - 60px);
  }
  .el-page-header {
    position: fixed;
    top: 0;
    left:0;
    padding-left: 20px;
    width:100%;
    height: 60px;
    justify-content: start;
    align-items: center;
    background-color: #7a557b;
    color: white;
    overflow-y:hidden;
    z-index: 1000;
  }
  ::v-deep .el-page-header__content {
    color: white;
  }
  .inputDiv {
    margin: 0 auto;
    width: 70%;
  }

  h1 {
    margin-bottom: 50px;
    color: #606266;
  }

  .form {
    margin-top: 20px;
  }

  .btns {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    align-items: center;
    border: 1px#7a557b;
    height: 40px;
    color: #7a557b;
    border: 1px solid #7a557b;
  }

  .btns:hover {
    color: #7a557b;
    background-color: #f3e6f6;
    border: 1px solid #f3e6f6;
  }
  .btnsDiv {
    width: 70%;
    margin: 0 auto;
  }
  .nextBtn, .submitBtn {
    margin-bottom: 20px;
    color: #f3e6f6;
    background-color: #7a557b;
  }
  ::v-deep .el-input__inner, ::v-deep .el-textarea__inner {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  }
  ::v-deep .el-input__inner:focus, ::v-deep .el-textarea__inner:focus, ::v-deep .el-select .el-input.is-focus .el-input__inner {
    border-color: #7a557b;
  }
  ::v-deep .el-select-dropdown__item.selected {
    color: #7a557b;
  }

</style>