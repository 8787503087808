<template>
  <div class="container">
    <el-container class="home">
      <el-page-header @back="goBack" content="DAWN - 草稿箱"/>
      <el-main class="belowTop">
        <el-table v-loading="loading"
          :data="curView_page" @row-click="handleRowClick" class="">
          <el-table-column prop="file_name" label="草稿标题" fixed="left"></el-table-column>
          <el-table-column prop="create_date" label="修改时间"></el-table-column>
          <el-table-column id="deleteCol" prop="link" label="删除草稿" width="100px">
            <template slot-scope="scope">
              <el-button
                id="deleteBtn" class="el-icon-delete" @click.stop="handleDelete(scope.$index, scope.row)"/>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5,10,15]"
            :page-size="pagesize"
            layout="total,jumper,prev, pager, next,sizes"
            :total="files_count">
        </el-pagination>
      </el-main>
    </el-container>
  </div>
</template>   
<script>
  import {get_all_draft_posts, delete_draft_post} from "@/network/community";
  export default {
    name: "MyDrafts",
    data() {
      return {
        drafts: [],
        curView_page: [],
        currentPage:1,
        pagesize:10,
        files_count:0,
        loading: true,
      };
    },
    methods: {
      goBack() {
        this.$router.back();
      },
      handleDelete(index, row) {
        this.$confirm('是否要删除草稿', '系统提示', {
          confirmButtonText: '确认',
          cancelButtonText: '返回',
          type: 'info'
        }).then(() => {
          delete_draft_post(this.$store.getters.email, row.cardId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已删除草稿'
              });
              this.loading = false;
              this.get_drafts();
            }
            else {
              this.$message({
                type: 'warning',
                message: '删除草稿失败，请稍后重试'
              });
            }
          })
        }).catch(() => {});
        document.activeElement.blur();
      },
      //分页 初始页currentPage、初始每页数据数pagesize和数据testpage--->控制每页几条
      handleSizeChange:function(size){
        this.pagesize = size;
        this.hadleGetFilesListApi();
      },
      // 控制页面的切换
      handleCurrentChange: function(currentPage) {
        this.currentPage = currentPage;
        this.hadleGetFilesListApi();
      },
      //对所有数据进行分页处理 发送请求
      hadleGetFilesListApi() {
        this.curView_page = this.drafts.slice((this.currentPage-1)*this.pagesize, this.currentPage*this.pagesize);
        this.files_count = this.drafts.length;
        this.loading = false;
      },
      handleRowClick(row) {
        this.$router.push({
          name: 'draft_post', params: { 
            draft: row
          }
        });
      },
      get_drafts() {
        get_all_draft_posts(this.email).then((res) => {
          if (res !== undefined && res.data !== undefined) {
            this.drafts = res.data; //后端数据查询时以控制有序
            this.hadleGetFilesListApi();
          }
          else {
            this.drafts = [];
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    created() {
      this.email = this.$store.getters.email;
      this.get_drafts();
    }
  }
</script>

<style scoped>
  .home {
    overflow: hidden;
  }
  .el-container {
    margin: 0px;
  }
  .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .belowTop {
    text-align: center;
    margin-top: 60px;
    height: calc(100vh - 60px);
  }
  .el-page-header {
    position: fixed;
    top: 0;
    left:0;
    padding-left: 20px;
    width:100%;
    height: 60px;
    justify-content: start;
    align-items: center;
    background-color: #7a557b;
    color: white;
    z-index: 1000;
  }

  .el-table {
    margin: 0 auto;
    width: 80%;
  }
  ::v-deep .el-page-header__content {
    color: white;
  }
  .deleteCol {
    text-align: right;
  }

  #deleteBtn {
    border: 0px;
    background-color: transparent;
    color: #7a557b;
    font-size: 17px;
  }
  
  #deleteBtn:hover {
    background-color: transparent;
    color: #d6c5db;
  }

  .el-pagination {
    margin-top: 20px;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #7a557b;
  }

</style>