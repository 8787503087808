<template>
  <div class="motaichuang">
    <div class="kuang">
      <header><span></span><i @click="closeBox">x</i></header>
      <article ref="box">
        <el-row>
          <el-col :span="6" :offset="18"><el-button round @click="toRegister">注册</el-button></el-col>
        </el-row>
        <el-row><el-col :offset="0"><h1> 密码登录</h1></el-col></el-row>
        <el-row>
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="ruleForm.email" @keyup.enter.native="handleLogin"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="pass">
              <el-input type="password" v-model="ruleForm.password" autocomplete="off" @keyup.enter.native="handleLogin"></el-input>
            </el-form-item>
            <el-form-item>
              <el-col :span="6"><el-button type="primary" @click.native.prevent="handleLogin" v-loading="btnLoading">登录</el-button></el-col>
              <el-col :span="6" :offset="0"><el-button @click="skipEmail">邮箱验证登录</el-button></el-col>
              <el-col :span="6" :offset="4"><el-button @click="forgetPassword">忘记密码</el-button></el-col>
            </el-form-item>
          </el-form>
        </el-row>
      </article>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import {register} from "@/network/login";
import {Message} from "element-ui";

export default {
  name: 'LoginPasswordNew',
  data() {
    return {
      ruleForm: {
        password: '',
        email: ''
      },
      rules: {
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        email: [
          { required: true, message: "邮箱地址不能为空", trigger: "blur" },
          {
            type: "email",
            message: "'请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
      },
      btnLoading: false,
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        console.log(route);
        this.redirect = route.query && route.query.redirect;
        console.log(this.redirect);
      },
      immediate: true
    }
  },
  methods: {
    closeBox() {
      this.$emit("closeBox");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleLogin(){
      this.btnLoading = true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          Cookies.set("email", this.ruleForm.email, { expires: 30 });
          Cookies.set("password", this.ruleForm.password, { expires: 30 });
          this.$store.dispatch("Login_password", this.ruleForm).then((flag) => {
            if(flag===1){
              this.$router.push({ path: "/home" }).catch(()=>{});
            }
            else{
              console.log(flag);
            }
            this.btnLoading = false;
          }).catch((e) => {
            console.log(e);
          });
        } else {
          this.btnLoading = false;
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    skipEmail(){
      this.$emit("closeBox");
      this.$emit("LoginEmail");
    },
    toRegister() {
      this.$emit('closeBox');
      this.$emit('Register');
    },
    forgetPassword(){
      this.$router.push('/login/reset_password_request');
    }
  }
}
</script>

<style>
.motaichuang{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.5);
}
.kuang{
  width: 30vw; /* box 宽度 */
  height: 60vh;  /* box 高度 */
  background-color: rgba(255,255,255,1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 1vw;
  padding: 1em;
  box-sizing: border-box;
}

.kuang > header{
  width: 100%;
  height: 10%;
  font-size: 1.3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.kuang > header > i{
  font-size: 1.3em;
  cursor: pointer;
  color: #a3a3a3;
  transition: all 0.5s;
}
.kuang > header > i:hover{
  color: #333;
  transition: all 0.5s;
}

.kuang > article{
  width: 100%;
  height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 0.5em;
  display: flex;
  flex-direction: column;
}

.kuang > article::-webkit-scrollbar{
  width: 0.5em;
}
.kuang > article::-webkit-scrollbar-thumb{
  border-radius: 1em;
  background-color: rgba(144,147,153,.3);
}
.kuang > article::-webkit-scrollbar-thumb:hover{
  background-color: rgba(144,147,153,.6);
}

.kuang > footer{
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kuang > footer > button{
  height: 80%;
  width: 15%;
  outline: none;
  border: none;
  background-color: #409eff;
  color: #fff;
  border-radius: 2em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.5s;
}
.kuang > footer > button:hover{
  background-color: #66b1ff;
  transition: all 0.5s;
}
.kuang > footer > button:active{
  background-color: #3a8ee6;
  transition: all 0.5s;
}
</style>
