<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import {getToken, removeToken} from "@/utils/auth";
import {Message} from "element-ui";

export default {
  name: 'app',
  data:{
    haveUnread: false
  },
  methods:{
    jump_to_csfqiu() {
      // //location.href="https://www.xsaifor.com/" // 直接跳转
      // window.open("https://www.xsaifor.com/", '_blank')  //打开新页面跳转
    },
    handleClick(command) {
      if (command === "logout") {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push('/login/');
        })
      } else if (command === "profile") {
        this.$router.push('/profile/');
      } else if (command === "resetPsd") {
        this.$router.push('/reset_password')
      }
    },
    getStatus() {
      let token = getToken();
      if (token === 'null' || token === ''|| typeof token ==='undefined') {
        return "未登录";
      } else {
        return "已登录";
      }
    }
  },
  components: {
  },
  created () {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store") ) {
      this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload",()=>{
      sessionStorage.setItem("store",JSON.stringify(this.$store.state))
    })

  }
}
</script>

<style>
#app {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body { 
  margin: 0; 
}
</style>
