<template>
  <div class="container">
    <el-container class="home">
      <el-page-header @back="goBack" content="DAWN - 文件上传"/>
      <el-main class="belowTop">
        <div id="pageName">
          文件上传
        </div>
        <div class="inputDiv titleDiv">
          <el-input placeholder="请输入文件名称" v-loading="loading"
                    v-model="title" clearable @keyup.enter.native="handleSearch">
          </el-input>
        </div>
        <div class="inputDiv contentDiv">
          <el-input placeholder="请输入文件简介" v-model="content" v-loading="loading"
                    clearable type="textarea" :rows="3" class="inputReply" maxlength="200" show-word-limit></el-input>
        </div>

        <div class="inputDiv">
          <input id="upload" ref="fileRef" type="file" @change="onFileChange" />
        </div>
        <div class="btns">
          <el-button class="upperBtns bottomBtns postBtn" @click="handleFile" :disabled="loading">上传文件</el-button>
          <el-button class="upperBtns bottomBtns cancelBtn" @click="handleCancelFile" :disabled="loading">取消上传</el-button>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import {upload_file} from "@/network/file";
export default {
  name: "UploadFile",
  data() {
    return {
      title: '',
      content: '',
      email: '',
      draftId: '',
      editingDraft: false,
      loading: false,
      options: [
        { value: 'option1', text: '选项1' },
        { value: 'option2', text: '选项2' },
        { value: 'option3', text: '选项3' }
      ],
      // 选中的值
      selectedOption: ''
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`Cancel the transfert of ${ file.name } ?`);
    },
    onFileChange(e) {
      // 获取文件列表
      const files = e.target.files;
      // 这里假设只选择一个文件
      if (files.length > 0) {
        this.selectedFile = files[0];
        if (!files) {
          return; // 如果没有选择文件，则直接返回
        }

        // 定义允许的文件类型，以防用户随便上传什么不符合要求的文件
        // const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'];
        const testmsg = this.selectedFile.name.substring(this.selectedFile.name.lastIndexOf('.') + 1);
        const extension2 = testmsg === 'pdf';
        const extension3 = testmsg === 'docx';
        const extension4 = testmsg === 'txt';
        // 检查文件类型是否在允许的类型中
        if (!extension2 && !extension3 && !extension4) {
          // 如果文件类型不在允许列表中，设置错误信息
          this.$message({
            type: 'warning',
            message: '只允许上传pdf, docx, txt格式的文件！'
          });
          // 可选：清除已选择的文件
          event.target.value = null;
        }
      }
    },
    handleCancelFile() {
      this.$confirm('放弃会使未上传的内容丢失，是否确认', '系统提示', {
        confirmButtonText: '确认放弃上传',
        cancelButtonText: '留在当前页面',
        type: 'info'
      }).then(() => {
        this.$router.back();
      }).catch(() => {});
      document.activeElement.blur();
    },
    handleFile() {
      this.loading = true;
      // let inputDOM = this.$refs.fileRef;
      // // 通过DOM取文件数据
      // const files = inputDOM.files;
      const files = this.selectedFile;
      if(files.length < 1){
        this.$message({
          type: 'warning',
          message: '未选择文件'
        });
      }
      else {
        const formData = new FormData();
        formData.append('email', this.email);
        formData.append('filename', this.title);
        formData.append('introduction', this.content);
        formData.append("file", files);
        const config = {
          headers: { "Content-Type": "multipart/form-data" }, //跟后端约定发送的数据类型为form-data对象类型
        };
        upload_file(formData, config).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '上传成功'
            });
            this.$router.push('/file/home');
            this.loading = false;
          }
          else {
            this.$message({
              type: 'warning',
              message: '上传失败，请稍后重试'
            });
          }
          this.loading = false;
        })
      }
    },
  },
  created() {
    this.email = this.$store.getters.email;
  }
}
</script>

<style scoped>
.home {
  overflow: hidden;
}
.el-container {
  margin: 0px;
}
.container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.belowTop {
  text-align: center;
  margin-top: 60px;
  height: calc(100vh - 60px);
}

#pageName {
  width: 70%;
  margin: 20px auto;
  font-size: 30px;
}

.el-page-header {
  position: fixed;
  top: 0;
  left:0;
  padding-left: 20px;
  width:100%;
  height: 60px;
  justify-content: start;
  align-items: center;
  background-color: #7a557b;
  color: white;
  overflow-y:hidden;
  z-index: 1000;
}
::v-deep .el-page-header__content {
  color: white;
}
.inputDiv {
  margin: 0 auto;
  width: 70%;
}

.titleDiv {
  margin-bottom: 20px;
  margin-top: 20px;
}

.contentDiv {
  min-height: 50px;
}

.upperBtns {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  align-items: center;
  border: 1px#7a557b;
  height: 40px;
  color: #7a557b;
  border: 1px solid #7a557b;
}

.upperBtns:hover {
  color: #7a557b;
  background-color: #f3e6f6;
  border: 1px solid #f3e6f6;
}
.bottomBtns {
  margin-top: 20px;
}
.btns {
  width: 15%;
  margin: 0 auto;
}
.saveAsBtn, .postBtn {
  margin-bottom: 20px;
  color: #f3e6f6;
  background-color: #7a557b;
}
.saveBtn, .saveAsBtn {
  float: left;
}
.cancelBtn {
  float: right;
}
.postBtn {
  float: left;
}
::v-deep .el-input__inner, ::v-deep .el-textarea__inner {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
}
::v-deep .el-input__inner:focus, ::v-deep .el-textarea__inner:focus {
  border-color: #7a557b;
}
#upload {
  margin: 20px 0;
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
}
input[type=file]::file-selector-button {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  background-color: white;
  border-radius: 5px;
  border: solid 1px #7a557b;
  color: #7a557b;
  padding: 4px 8px;
}

input[type=file]::file-selector-button:hover {
  background-color: #7a557b;
  color: #f3e6f6;
}
</style>