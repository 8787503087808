<template>
  <div class="container">
    <el-container class="home">
      <el-header class="app_header">
        <el-button id="mainButton" type="text" @click="$router.push('/home')">DAWN</el-button>
        <div class="topBarButtons">
          <el-button type="text" @click="$router.push('/home/headline_news')">头条新闻</el-button>
          <el-button type="text" @click="$router.push('/community/home')">经验交流</el-button>
          <el-button type="text" @click="$router.push('/file/home')">文件共享</el-button>
          <el-button type="text" @click="$router.push('/ai/home')">智能AI</el-button>
          <el-button type="text" @click="$router.push('/course/home')">自我提升</el-button>
          <el-button type="text" @click="$router.push('/intelligence')">智能教研</el-button>
          <el-button type="text" @click="$router.push('/contact_us')">联系我们</el-button>

          <el-badge is-dot v-if="hvNotice" class="app_notification">
            <el-dropdown @command="handleClickNotification">
              <span class="el-dropdown-link"><i class="el-icon-bell"></i></span>
              <el-dropdown-menu slot="dropdown" class = "app_window">
                <el-dropdown-item command="system">系统通知</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-badge>
          <el-dropdown v-else class="app_notification" @command="handleClickNotification">
            <span class="el-dropdown-link"><i class="el-icon-bell"></i></span>
            <el-dropdown-menu slot="dropdown" class = "app_window">
              <el-dropdown-item command="system">系统通知</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown class = "app_dropdown" @command="handleClickDropdown">
            <span class="el-dropdown-link"><i class="el-icon-user-solid"></i></span>
            <el-dropdown-menu slot="dropdown" class = "app_window">
              <el-dropdown-item command="profile">个人中心</el-dropdown-item>
              <el-dropdown-item command="resetPsd">修改密码</el-dropdown-item>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main class="belowTop">
        <div class="searchRow">
          <el-row type="flex" justify="start">
            <el-select v-model="runBy" clearable placeholder="全部/自营" :popper-append-to-body="false">
              <el-option key="all" label="全部" value='0'/>
              <el-option key="self-run" label="自营" value='1'/>
            </el-select>
            <el-select v-model="lessonCat" multiple placeholder="课程分类(多选)" :popper-append-to-body="false">
              <el-option v-for="item in lessonCatOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-select v-model="newOrHot" clearable placeholder="最新/最火" :popper-append-to-body="false">
              <el-option key="latest" label="最新" value="0"/>
              <el-option key="popular" label="最火" value="1"/>
            </el-select>
            <div class="search">
              <el-input placeholder="搜索内容" v-model="keywords" clearable @clear="handleClear"
                        @keyup.enter.native="handleSearch"></el-input>
            </div>
            <el-button class="btns" @click="handleSearch" icon="el-icon-search">查找</el-button>
            <el-button class="btns" @click="handleClearFilter" icon="el-icon-refresh">清空筛选</el-button>
          </el-row>
        </div>
        <div class="searchRow">
          <el-row type="flex">
            <div class="classTypeDiv">课程类型：</div>
            <el-button-group>
              <el-button class="btns multiSelectBtns" :id="highlightGroup.referSelect" @click="toogleBtns('referSelect',1)">保研</el-button>
              <el-button class="btns multiSelectBtns" :id="highlightGroup.examSelect" @click="toogleBtns('examSelect',2)">考研</el-button>
              <el-button class="btns multiSelectBtns" :id="highlightGroup.englishSelect" @click="toogleBtns('englishSelect',3)">大学英语</el-button>
              <el-button class="btns multiSelectBtns" :id="highlightGroup.compSelect" @click="toogleBtns('compSelect',4)">竞赛</el-button>
              <el-button class="btns multiSelectBtns" :id="highlightGroup.projectSelect" @click="toogleBtns('projectSelect',5)">项目</el-button>
              <el-button class="btns multiSelectBtns" :id="highlightGroup.freeSelect" @click="toogleBtns('freeSelect')">免费</el-button>
            </el-button-group>
            <div class="myBtnsDiv">
              <el-button class="myBtn" :icon="isMyCourse ? 'el-icon-back': 'el-icon-reading'" @click="viewMyCourse">{{ myCourseText }}</el-button>
              <el-button class="myBtn" :icon="isMyCollection ? 'el-icon-back': 'el-icon-star-off'" @click="viewMyCollection">{{ myCollectionText }}</el-button>
            </div>
          </el-row>
        </div>
        <div class="cardDiv" v-loading="loading">
          <el-row>
            <el-col :span="8" v-for="item in courses_page" :key="item.coId">
              <el-card class="courseCards">
                <div class="collectNumDiv">{{ item.collectNumber }}</div>
                <el-button id="collectBtn" class="el-icon-star-off" v-if="item.isCollect===0" 
                  @click.stop="handleCollect(item)"/>
                <el-button id="collectBtn" class="el-icon-star-on" v-else @click.stop="handleCollect(item)"/>
                <div class="imgDiv" @click="courseClicked(item)">
                  <img :src="item.image" alt="Base64 Image" class="courseImg">
                </div>
                <div class="descriptionDiv">{{ item.description }}</div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </el-main>
    <el-pagination
        background class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pagesize"
        layout="total,jumper,prev, pager, next"
        :total="files_count">
    </el-pagination>
    </el-container>
  </div>
</template>

<script>
  import {get_notifications} from "@/network/notification";
  import {get_all_course,collect_course,uncollect_course,fuzzy_search_courses,get_my_courses,get_collected_courses} from "@/network/course"
  
  export default {
    name: "CourseHome",
    data() {
      return {
        email: '',
        currentPage:1,
        pagesize:6,
        files_count:0,
        keywords: '',
        loading: true,
        hvNotice: false,
        imgsLoading: false,
        runBy: '',
        lessonCat: [],
        isFree: 0,
        filterList: [], //int list
        lessonCatOptions: [{value: 1,label: '计算机'}, {value: 2,label: '经管'}, {value: 3,label: '心理学'}, {value: 4,label: '人工智能'}, {value: 5,label: '其他'}],
        newOrHot: '',
        highlightGroup: {
          referSelect: 'notHighlighted',
          examSelect: 'notHighlighted',
          englishSelect: 'notHighlighted',
          compSelect: 'notHighlighted',
          projectSelect: 'notHighlighted',
          freeSelect: 'notHighlighted',
        },
        courses: [{description: '课程描述', isCollect: 0}],
        courses_page: [],
        isMyCourse: false,
        isMyCollection: false,
        myCourseText: '我的课程',
        myCollectionText: '我的收藏',
        myCourseIcon : 'el-icon-reading',
      }
    },
    methods: {
      viewMyCourse() {
        this.loading = true;
        this.isMyCourse = !this.isMyCourse;
        this.isMyCollection = false;
        this.myCollectionText = this.isMyCollection ? '返回全部课程':'我的收藏';
        this.myCourseIcon = this.isMyCourse ? 'el-icon-back': 'el-icon-reading';
        this.myCourseText = this.isMyCourse ? '返回全部课程':'我的课程';
        if (this.isMyCourse) {
          get_my_courses(this.email).then((res) => {
            this.courses = res.data;
            this.hadleGetFilesListApi();
            this.loading = false;
          })
        }
        else {
          this.getCourses();
        }
      },
      viewMyCollection() {
        this.loading = true;
        this.isMyCollection = !this.isMyCollection;
        this.isMyCourse = false;
        this.myCourseText = this.isMyCourse ? '返回全部课程':'我的课程';
        this.myCollectionText = this.isMyCollection ? '返回全部课程':'我的收藏';
        if (this.isMyCollection) {
          get_collected_courses(this.email).then((res) => {
            this.courses = res.data;
            this.hadleGetFilesListApi();
            this.loading = false;
          })
        }
        else {
          this.getCourses();
        }
      },
      handleClearFilter() {
        document.activeElement.blur();
        this.highlightGroup = {
          referSelect: 'notHighlighted',
          examSelect: 'notHighlighted',
          englishSelect: 'notHighlighted',
          compSelect: 'notHighlighted',
          projectSelect: 'notHighlighted',
          freeSelect: 'notHighlighted',
        };
        this.keywords = '';
        this.runBy = '';
        this.lessonCat = [];
        this.isFree = 0;
        this.filterList = [];
        this.newOrHot = [];
        this.loading = true;
        this.getCourses();
      },
      handleSearch() {
        this.loading = true;
        document.activeElement.blur();
        let tempFilterList = this.filterList.length === 0 ? [1,2,3,4,5]:this.filterList
        fuzzy_search_courses(this.email, Number(this.runBy), this.lessonCat, this.isFree, Number(this.newOrHot), this.keywords, this.filterList).then((res) => {
          this.courses = res.data;
          this.hadleGetFilesListApi();
          this.loading = false;
        })
      },
      handleCollect(item) {
        if (item.isCollect === 0) {
          item.isCollect = 1;
          item.collectNumber ++;
          collect_course(this.$store.getters.email, item.coId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已收藏'
              });
            }
            else {
              item.isCollect = 0;
              item.collectNumber --;
              this.$message({
                type: 'warning',
                message: '收藏失败，请稍后重试'
              });
            }
          })
        }
        else {
          item.isCollect = 0;
          item.collectNumber --;
          uncollect_course(this.$store.getters.email, item.coId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已取消收藏'
              });
            }
            else {
              item.isCollect = 1;
              item.collectNumber ++;
              this.$message({
                type: 'warning',
                message: '取消收藏失败，请稍后重试'
              });
            }
          })
        }
      },
      toogleBtns(selection, type) {
        this.highlightGroup[selection] = this.highlightGroup[selection] === 'notHighlighted' ? 'highlighted' : 'notHighlighted';
        switch(selection) {
          case 'freeSelect':
            this.isFree = this.isFree === 1 ? 0 : 1;
            break;
          default:
            this.updateFilter(type);
        }
        document.activeElement.blur();
        this.loading = true;
        this.handleSearch();
      },
      updateFilter(filter) {
        if (this.filterList.includes(filter)) {
          this.filterList = this.filterList.filter(val => val !== filter);
        }
        else {
          this.filterList.push(filter);
        }
      },
      handleClear() {
        this.keywords = '';
      },
      //分页 初始页currentPage、初始每页数据数pagesize和数据testpage--->控制每页几条
      handleSizeChange:function(size){
        this.pagesize = size;
        this.hadleGetFilesListApi();
        console.log(this.pagesize);
      },
      // 控制页面的切换
      handleCurrentChange: function(currentPage) {
        this.currentPage = currentPage;
        this.hadleGetFilesListApi();
      },
      //对所有数据进行分页处理 发送请求
      hadleGetFilesListApi() {
        this.courses_page = this.courses.slice((this.currentPage-1)*this.pagesize, this.currentPage*this.pagesize);
        this.files_count = this.courses.length;
      },
      courseClicked(course) {  //跳转到原文
        //location.href=course.link // 直接跳转
        window.open(course.link, '_blank')  //打开新页面跳转
      },
      handleClickNotification(command) {
        if (command === "system") {
          this.$router.push('/notification/');
        }
      },
      handleClickDropdown(command) {
        if (command === "logout") {
          this.$store.dispatch('LogOut').then(() => {
            this.$router.push('/login/');
          })
        }
        else if (command === "profile") {
          this.$router.push('/profile/');
        }
        else if (command === "resetPsd") {
          this.$router.push('/reset_password/');
        }
      },
      getCourses() {
        get_all_course(this.email).then((res) => {
          this.courses = res.data; this.hadleGetFilesListApi();
          this.loading = false;
        })
      }
    },
    created() {
      this.email = this.$store.getters.email;
      this.getCourses();
      get_notifications(this.email).then((res) => {
        let notices = res.data;
        notices.forEach(notice => {
          if (notice.isRead === 0) {
            this.hvNotice = true;
          }
        });
      })
    },
  }
  </script>
  
<style scoped>
  .classTypeDiv {
    align-self: center;
  }
  .descriptionDiv {
    width: 70%;
    margin: 0 auto;
    flex-wrap: wrap;
    line-height: 1.15;
    word-break: break-all;
  }
  .myBtnsDiv {
    position: absolute;
    right: 0;
  }
  .myBtn {
    float: right;
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    align-items: center;
    height: 40px;
    background-color: #7a557b;
    color: #f3e6f6;
    border: 1px solid #7a557b;
    margin-left: 10px;
  }
  .myBtn:hover {
    background-color: white;
    color: #7a557b;
  }
  .imgDiv {
    width: 70%;
    margin: 0 auto;
    cursor: pointer;
  }
  .courseImg {
    height: 150px;
    max-width: 220px;
    margin: auto;
  }
  .collectNumDiv {
    float: right;
  }
  #collectBtn {
    padding: 0;
    float: right;
    border: 0px;
    background-color: transparent;
    color: #7a557b;
    font-size: 17px;
  }

  #collectBtn:hover {
    background-color: transparent;
    color: #d6c5db;
  }
  #highlighted {
    background-color: #7a557b;
    color: #f3e6f6;
  }
  .btns {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    align-items: center;
    height: 40px;
    color: #7a557b;
    border: 1px solid #C0C4CC;
    font-weight: 600;
  }

  .btns:hover {
    color: #f3e6f6;
    background-color: #7a557b;
  }
  .multiSelectBtns {
    border: 1px solid #7a557b;
  }

  .multiSelectBtns:hover, #highlighted:hover {
    color: #7a557b;
    background-color: #f3e6f6;
  }
  ::v-deep .el-input__inner {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    font-weight: bold;
  }

  ::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected,
  ::v-deep .el-select-dropdown__item.selected {
    color: #7a557a;
  }

  .el-select {
    margin-right: 20px;
  }

  .el-container {
    margin: 0px;
  }
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .search {
    width: 50%;
  }
  .search ::v-deep .el-input__inner{
    height:40px;
    border-radius: 10px;
    float:right;
    margin-right: 10px;
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  }
  ::v-deep .el-input__inner:focus, ::v-deep .el-select .el-input.is-focus .el-input__inner{
    border-color: #7a557b;
  }
  .searchRow {
    height: auto;
    margin: 10px 30px;
  }
  .el-header {
    position: fixed;
    top: 0;
    left:0;
    width:100%;
    justify-content: center;
    align-items: center;
    background-color: #7a557b;
    z-index: 1000;
    overflow: hidden;
  }

  .app_header .el-button {
    color: white;
    margin: 10px;
  }

  .el-main {
    /* background-color: #f3e6f6; */
    color: #333;
    text-align: center;
    flex: 1;
    overflow: hidden;
  }

  .belowTop {
    margin-top: 60px;
    height: calc(100vh - 60px);
    width: 100%;
    flex: 1;
  }

  #mainButton {
    position: absolute;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    left: 0;
    font-size: 30px;
    margin-left: 30px;
    top: 0;
    margin-top: 0;
  }

  .app_dropdown {
    position: absolute;
    right: 0;
    top: 20px;
    margin-right: 30px;
  }

  .el-dropdown-menu__item:hover {
    background-color: #f3e6f6;
    color: #7a517c;
  }

  .el-icon-user-solid {
    color: white;
    font-size: 20px;
  }

  .app_notification {
    position: absolute;
    right: 50px;
    top: 20px;
    margin-right: 30px;
  }

  ::v-deep .el-badge__content.is-dot {
    border: none;
  }

  .el-icon-bell {
    color: white;
    font-size: 20px;
  }
  
  .el-input__inner:focus {
    border-color: #7a557b;
  }
  
  .search ::v-deep .el-input__inner:focus{
    border-color: #7a557b;
  }
  
  .pagination {
    margin-bottom: 20px;
  }
  
</style>