<template>
  <div style="text-align: center">
    <el-row type="flex" justify="end">
      <el-col>
        <div class = "search">
          <el-input placeholder="搜索新闻" v-model="keywords" 
            clearable @clear="handleSearch"
            @keyup.enter.native="handleSearch" suffix-icon="el-icon-search">
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-table :data="tableData_page" @row-click="rowClicked" 
      @selection-change="handleSelectionChange" class="clickable-rows" v-loading="loading">
      <el-table-column v-if="selectingMultiple" type="selection" width="50"/>
      <el-table-column prop="file_name" label="新闻名称"  fixed="left"></el-table-column>
      <el-table-column prop="dpName" label="发布院校"></el-table-column>
      <el-table-column prop="newsType" label="类型" width=70></el-table-column>
    </el-table>
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5,10,15]"
        :page-size="pagesize"
        layout="total,jumper,prev, pager, next,sizes"
        :total="files_count">
    </el-pagination>
  </div>
</template>

<script>
  import {get_headline_news} from "@/network/home";
  
  export default {
    name: "HeadlineNews",
    data() {
      return {
        tableDataAll: [
          {
            "newsType": '',
            "fid": 0,
            "infoType": 0,
            "dpName": 0,
            "file_name": "加载中",
            "link": "https://www.xsaifor.com/"
          }
        ],
        tableData: [
          {
            "newsType": '',
            "fid": 0,
            "infoType": 0,
            "dpName": 0,
            "file_name": "加载中",
            "link": "https://www.xsaifor.com/"
          }
        ],
        tableData_page: [
          {
            "newsType": '',
            "fid": 0,
            "infoType": 0,
            "dpName": 0,
            "file_name": "加载中",
            "link": "https://www.xsaifor.com/"
          }
        ],
        selectingMultiple: false,
        selected: [],
        departments: [
          {
            "fid": 0,
            "infoType": 0,
            "dpName": 0,
            "file_name": "加载中",
            "link": "https://www.xsaifor.com/"
          }
        ],
        currentPage:1,
        pagesize:10,
        files_count:0,
        information_type: true, // true: 保研， false: 考研
        keywords: '',
        loading: true,
      }
    },
    methods: {
      handleSearch() {
        this.tableData = this.tableDataAll.filter((p)=>{
          return p.file_name.indexOf(this.keywords) !== -1;
        })
        this.hadleGetFilesListApi();//刷新页面
      },
      //分页 初始页currentPage、初始每页数据数pagesize和数据testpage--->控制每页几条
      handleSizeChange:function(size){
        this.pagesize = size;
        this.hadleGetFilesListApi();
        console.log(this.pagesize);
      },
      // 控制页面的切换
      handleCurrentChange: function(currentPage) {
        this.currentPage = currentPage;
        this.hadleGetFilesListApi();
      },
      //对所有数据进行分页处理 发送请求
      hadleGetFilesListApi() {
        this.tableData_page = this.tableData.slice((this.currentPage-1)*this.pagesize, this.currentPage*this.pagesize);
        this.files_count = this.tableData.length;
      },
      rowClicked(row) {  //跳转到原文
        //location.href=row.link // 直接跳转
        window.open(row.link, '_blank')  //打开新页面跳转
      },
      selectMultipleClicked() {
        //显示/隐藏 批量操作勾选框
        this.selectingMultiple = !this.selectingMultiple;
      },
      handleSelectionChange(selection) {
        this.selected = selection;
      },
    },
    created() {
      const email = this.$store.getters.email;
      this.departments = this.$store.getters.departments;
      get_headline_news(email).then((res) => {
        if (res !== undefined && res.files !== undefined) {
          this.tableDataAll = res.files;
          this.tableDataAll.forEach(tableNews => {
            let foundDp = this.departments.find(dp => dp.fid === tableNews.dpId);
            tableNews['dpName'] = foundDp.dp_name;
            tableNews['newsType'] = tableNews.infoType === 1 ? '保研':'考研';  // 此处修改一下，原来写的是infoType === 0 为保研
          });
          this.tableData = this.tableDataAll;//后端数据查询时以控制有序
          this.hadleGetFilesListApi();
        }
        else {
          this.tableDataAll = [];
          this.tableData = [];
        }
        this.loading = false;
      }).catch((error) => {
        console.log(error);
      })
    },
  }
  </script>
  
  <style scoped>
  .clickable-rows {
    width: 100%;
    height: 80%;
    margin-top: 20px;
    cursor: default;
    border-style: solid;
    border-color: #09073c;
  }
  
  #collectBtn {
    border: 0px;
    background-color: transparent;
    color: #7a557b;
    font-size: 17px;
  }
  
  #collectBtn:hover {
    background-color: transparent;
    color: #d6c5db;
  }
  
  .el-table {
    border: 0;
  }

  .el-input__inner:focus {
    border-color: #7a557b;
  }
  
  .search ::v-deep .el-input__inner{
    height:40px;
    border-radius: 40px;
    width: 300px;
    float:right;
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  }
  .search ::v-deep .el-input__inner:focus{
    border-color: #7a557b;
  }
  
</style>