import request from '@/utils/request'


// 发送邮件
// reutrn: flag: int
export function send_email(email, sender_email, sender_nickname, receiver_information) {
    const data = {
        email: email,
        sender_email: sender_email,
        sender_nickname: sender_nickname,
        receiver_information: receiver_information,
    }
    return request({
        url: '/intelligence/intelligent_email',
        method: 'post',
        data: data,
    })
}

// 获取邮件模板列表
// reutrn: data: []
export function get_email_templates(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/intelligence/get_email_templates',
        method: 'post',
        data: data,
    })
}

// 获取我自己的所有计划书
// reutrn: data: []
export function get_plans(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/intelligence/get_prospectus',
        method: 'post',
        data: data,
    })
}

// 新建计划书
// reutrn: flag: int
export function create_plan(email, title, content, start_time, due_time) {
    const data = {
        email: email,
        title: title,
        content: content,
        start_time: start_time,
        due_time: due_time
    }
    return request({
        url: '/intelligence/create_prospectus',
        method: 'post',
        data: data,
    })
}

// 更新计划书
// reutrn: flag: int
export function update_plan(email, proId, title, content, start_time, due_time, status) {
    const data = {
        email: email,
        proId: proId,
        title: title,
        content: content,
        start_time: start_time,
        due_time: due_time,
        status: status
    }
    return request({
        url: '/intelligence/modify_prospectus',
        method: 'post',
        data: data,
    })
}

// 删除指定计划书
// reutrn: flag: int
export function delete_plan(email, proId) {
    const data = {
        email: email,
        proId: proId
    }
    return request({
        url: '/intelligence/delete_prospectus',
        method: 'post',
        data: data,
    })
}

// 获取时间流
// reutrn: data: []
export function get_timeline(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/intelligence/get_time_stream',
        method: 'post',
        data: data,
    })
}