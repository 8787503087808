<template>
  <div class="container">
    <el-container class="home">
      <el-page-header @back="goBack" content="DAWN - 创建帖子"/>
      <el-main class="belowTop">
        <!-- <div class="btns">
          <el-button class="upperBtns saveAsBtn" @click="handleSaveNewDraft">创建草稿</el-button>
          <el-button v-if="editingDraft" class="upperBtns saveBtn" @click="handleSaveDraft">保存草稿</el-button>
          <el-button class="upperBtns postBtn" @click="handlePost">发布帖子</el-button>
          <el-button class="upperBtns cancelBtn" @click="handleCancelPost">放弃发布</el-button>
        </div> -->
        <div class="inputDiv titleDiv">
          <el-input placeholder="请输入帖子标题" v-loading="loading"
          v-model="title" clearable @keyup.enter.native="handleSearch">
          </el-input>
        </div>
        <div class="inputDiv contentDiv">
          <el-input placeholder="请输入帖子内容" v-model="content" v-loading="loading"
          clearable type="textarea" :rows="24" class="inputReply" maxlength="3000" show-word-limit></el-input>
        </div>
        <div class="btns">
          <el-button class="upperBtns bottomBtns saveAsBtn" @click="handleSaveNewDraft" :disabled="loading">创建草稿</el-button>
          <el-button v-if="editingDraft" class="upperBtns bottomBtns saveBtn" @click="handleSaveDraft" :disabled="loading">保存草稿</el-button>
          <el-button class="upperBtns bottomBtns postBtn" @click="handlePost" :disabled="loading">发布帖子</el-button>
          <el-button class="upperBtns bottomBtns cancelBtn" @click="handleCancelPost" :disabled="loading">放弃发布</el-button>
        </div>
      </el-main>
    </el-container>
  </div>
</template>   
<script>
  import {create_post, save_new_draft_post, save_draft_post, delete_draft_post} from "@/network/community";
  export default {
    name: "CreatePost",
    data() {
      return {
        title: '',
        content: '',
        email: '',
        draftId: '',
        editingDraft: false,
        loading: false,
      };
    },
    methods: {
      goBack() {
        this.$router.back();
      },
      handleCancelPost() {
        this.$confirm('放弃会使未保存的内容丢失，是否确认', '系统提示', {
          confirmButtonText: '确认放弃发布',
          cancelButtonText: '留在当前页面',
          type: 'info'
        }).then(() => {
          this.$router.back();
        }).catch(() => {});
        document.activeElement.blur();
      },
      handlePost() {
        this.loading = true;
        create_post(this.email, this.content, this.title).then((res) => {
          if (res.flag === 1) {
            delete_draft_post(this.email, this.draftId).then((res) => {
              if (res.flag === 1) {
                this.$message({
                  type: 'success',
                  message: '发布成功'
                });
                this.$router.push('/community/home');
              }
              else {
                this.$message({
                  type: 'warning',
                  message: '发布失败，请稍后重试'
                });
              }
              this.loading = false;
            });
          }
          else {
            this.$message({
              type: 'warning',
              message: '发布失败，请稍后重试'
            });
          }
          this.loading = false;
        })
      },
      handleSaveDraft() {
        this.loading = true;
        save_draft_post(this.email, this.content, this.title, this.draftId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已保存'
            });
            this.$confirm('保存成功，是否返回之前页面', '系统提示', {
              confirmButtonText: '离开当前页面',
              cancelButtonText: '留在当前页面',
              type: 'info'
            }).then(() => {
              this.$router.back();
            }).catch(() => {});
          }
          else {
            this.$message({
              type: 'warning',
              message: '保存失败，请稍后重试'
            });
          }
          this.loading = false;
        })
        document.activeElement.blur();
      },
      handleSaveNewDraft() {
        this.loading = true;
        save_new_draft_post(this.email, this.content, this.title).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已保存'
            });
            this.$confirm('保存成功，是否返回之前页面', '系统提示', {
              confirmButtonText: '离开当前页面',
              cancelButtonText: '留在当前页面',
              type: 'info'
            }).then(() => {
              this.$router.back();
            }).catch(() => {});
            this.editingDraft = true;
          }
          else {
            this.$message({
              type: 'warning',
              message: '保存失败，请稍后重试'
            });
          }
          this.loading = false;
        })
      },
    },
    created() {
      this.email = this.$store.getters.email;
      let draft = {};
      if (this.$route.params.draft !== undefined && this.$route.params.draft !== null) {
        draft = this.$route.params.draft;
        // this.$store.commit("SET_DRAFT", draft);
        this.editingDraft = true;
        this.title = draft.file_name;
        this.content = draft.content;
        this.draftId = draft.cardId;
      }
    }
  }
</script>

<style scoped>
  .home {
    overflow: hidden;
  }
  .el-container {
    margin: 0px;
  }
  .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .belowTop {
    text-align: center;
    margin-top: 60px;
    height: calc(100vh - 60px);
  }
  .el-page-header {
    position: fixed;
    top: 0;
    left:0;
    padding-left: 20px;
    width:100%;
    height: 60px;
    justify-content: start;
    align-items: center;
    background-color: #7a557b;
    color: white;
    overflow-y:hidden;
    z-index: 1000;
  }
  ::v-deep .el-page-header__content {
    color: white;
  }
  .inputDiv {
    margin: 0 auto;
    width: 70%;
  }

  .titleDiv {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .contentDiv {
    min-height: 500px;
  }

  .upperBtns {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    align-items: center;
    border: 1px#7a557b;
    height: 40px;
    color: #7a557b;
    border: 1px solid #7a557b;
  }

  .upperBtns:hover {
    color: #7a557b;
    background-color: #f3e6f6;
    border: 1px solid #f3e6f6;
  }
  .bottomBtns {
    margin-top: 20px;
  }
  .btns {
    width: 70%;
    margin: 0 auto;
  }
  .saveAsBtn, .postBtn {
    margin-bottom: 20px;
    color: #f3e6f6;
    background-color: #7a557b;
  }
  .saveBtn, .saveAsBtn {
    float: left;
  }
  .cancelBtn {
    float: right;
  }
  .postBtn {
    float: right;
  }
  ::v-deep .el-input__inner, ::v-deep .el-textarea__inner {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  }
  ::v-deep .el-input__inner:focus, ::v-deep .el-textarea__inner:focus {
    border-color: #7a557b;
  }
</style>