<template>
  <div style="text-align: center">
    <el-row type="flex" justify="center" class="formRow">
      <el-button class="topLongBtn" @click="$router.push('/ai/personal_report')">
        为更方便您的使用，使用此功能需先填写写个人定位报告，如未填写，可点击此处跳转
      </el-button>
    </el-row>
    <el-form :model="mailForm" status-icon :rules="rules" ref="mailForm" class="mailFormClass" v-loading="loading">
      <div class="topForm">
        <el-row :gutter="10" v-for="(item, index) in mailForm.recipients" :key="index">
          <el-col span=11>
            <el-form-item :prop="'recipients.'+ index + '.r_email'" label="收件人"
              :rules="[{ required: true, message: '收件人邮箱不能为空',trigger: 'blur' },
                      {type: 'email',message: '请输入正确的邮箱地址',trigger: ['blur', 'change']}]">
              <el-input v-model="item.r_email" autocomplete="off" placeholder="输入接收方邮箱" class="emailInput" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col span=8>
            <el-form-item :prop="'recipients.'+ index + '.r_name'"
              :rules="{ required: true, message: '收件人称谓不能为空',trigger: 'blur' }">
              <el-input v-model="item.r_name" autocomplete="off" placeholder="输入接收方称谓" class="nameInput" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col span=5>
            <el-button v-if="item.temId > 0" class="formBtns selectBtn" @click="openTemplatesPopup(index)">已选择</el-button>
              <el-button v-else class="formBtns selectBtn" @click="openTemplatesPopup(index)">选择模版</el-button>
              <el-button class="formBtns" @click="handleRemove(index)">删除</el-button>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button class="formBtns" icon="el-icon-plus"  @click="handleAdd"></el-button>
        </el-form-item>
      </div>
      <el-row class="bottomForm" :gutter="20">
        <el-col span=10>
          <el-form-item prop="senderEmail">
            <el-input v-model="mailForm.senderEmail" autocomplete="off" placeholder="输入发送邮箱" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col span=8>
          <el-form-item prop="senderName">
            <el-input v-model="mailForm.senderName" autocomplete="off" placeholder="输入发送人昵称" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col span=5>
          <el-form-item>
            <el-button class="formBtns" @click="handleSend">一键发送</el-button>
            <el-button class="formBtns" @click="handleClear">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <MailTemplates ref="mailTemplates" class="popup" @childFn="getSelectedTemp"/>
  </div>
</template>

<script>
import MailTemplates from './MailTemplates.vue';
import { get_email_templates, send_email } from "@/network/intelligence";

export default {
  name: "Mail",
  data() {
    return {
      activeIndex: '/intelligence/mail',
      keywords: '',
      loading: true,
      email: '',
      templates: [],
      mailForm: {
        recipients: [{r_email: '', r_name: '', temId: -1}],
        senderEmail: '',
        senderName: '',
      },
      rules: {
        senderName: [
          { required: true, trigger: "blur", message: "请输入您的昵称" }
        ],
        senderEmail: [
          { required: true, message: "邮箱地址不能为空", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
      },
    }
  },
  components: {
    MailTemplates,
  },
  methods: {
    handleClear() {
      this.loading = true;
      this.$refs.mailForm.resetFields();
      this.mailForm.recipients = [{r_email: '', r_name: '', temId: -1}];
      this.loading = false;
    },
    handleSend() {
      this.$refs.mailForm.validate((valid) => {
        this.loading = true;
        if (valid) {
          send_email(this.email, this.mailForm.senderEmail, this.mailForm.senderName, this.mailForm.recipients).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已成功发送'
              });
              this.handleClear();
            }
            else if (res.flag === 2) {
              this.$message({
                type: 'info',
                message: '使用此功能前请先填写个人定位报告'
              });
            }
            else {
              this.$message({
                type: 'warning',
                message: '发送邮件失败，请稍后重试'
              });
            }
            this.loading = false;
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    getSelectedTemp(itemIdx, temId) {
      this.mailForm.recipients[itemIdx]["temId"] = temId;
    },
    handleRemove(idx) {
      document.activeElement.blur();
      this.mailForm.recipients.splice(idx, 1);
    },
    handleAdd() {
      document.activeElement.blur();
      this.mailForm.recipients.push({r_email: '', r_name: '', temId: -1});
    },
    openTemplatesPopup(idx) {
      this.$refs.mailTemplates.openPopup(this.templates, idx);
    },

  },
  created() {
    this.email = this.$store.getters.email;
    get_email_templates(this.email).then((res) => {
      if (res !== undefined && res.data !== undefined) {
        this.templates = res.data;
      }
      this.loading = false;
    }).catch((error) => {
      console.log(error);
    });
  },
}
</script>

<style scoped>
.selectBtn {
  width: 105px;
}
.topLongBtn {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  margin-right: 20px;
  border-radius: 10px;
  border: 1px #7a557b solid;
  background-color: white;
  color: #7a557b;
  text-align: center;
  align-content: center;
  font-size: 18px;
  font-weight: bold;
  width: 80%;
}
.topForm {
  margin-bottom: 90px;
}
::v-deep .bottomForm {
  position: fixed;
  padding: 30px 0 10px 0;
  bottom: 0;
  right: 0;
  left: 260px;
  width: auto;
  background-color: #f3e6f6;
  margin-bottom: 0;
  text-align: center;
}
::v-deep .el-form-item__label {
  font-size: 15px;
}
.emailInput {
  width: 80%;
}
.mailFormClass {
  margin-top: 20px;
  width: 100%;
}
.formBtns {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  margin-left: 20px;
  background-color: white;
  color: #7a557b;
  font-size: 15px;
}

.formBtns:hover {
  border: 1px solid #7a557b;
  background-color: #7a557b;
  color: #f3e6f6;
}

.el-table {
  border: 0;
}

::v-deep .el-input__inner {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  font-size: 15px;
}
::v-deep .el-input__inner:focus{
  border-color: #7a557b;
}

.word_button {
  height: 30px;
  width: 90px;
  border: 0;
  background: transparent;
  margin: 10px;
  cursor: pointer;
  color: #7a557b;
}
.word_button:hover {
  color: #9a7d85;
}
</style>