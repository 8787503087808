<template>
  <div class="container">
    <el-container class="home">
      <el-page-header @back="goBack" content="DAWN - 关注/粉丝列表"/>
      <el-main class="belowTop">
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane label="关注" name="following">
            <el-table
              :data="curView_page" class="" @row-click="userRowClicked" >
              <el-table-column prop="nickname" label="用户名" fixed="left"></el-table-column>
              <el-table-column prop="cardNumber" label="发帖数量"></el-table-column>
              <el-table-column id="unfollowCol" prop="link" label="取消关注" width="100px">
                <template slot-scope="scope">
                  <el-button
                    id="unfollowBtn" class="el-icon-error" @click.stop="handleUnfollow(scope.$index, scope.row)"/>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[5,10,15]"
                :page-size="pagesize"
                layout="total,jumper,prev, pager, next,sizes"
                :total="files_count">
            </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="粉丝" name="followers">
            <el-table
              :data="curView_page" class="" @row-click="userRowClicked" >
              <el-table-column prop="nickname" label="用户名" fixed="left"></el-table-column>
              <el-table-column prop="cardNumber" label="发帖数量"></el-table-column>
            </el-table>
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[5,10,15]"
                :page-size="pagesize"
                layout="total,jumper,prev, pager, next,sizes"
                :total="files_count">
            </el-pagination>
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>
  </div>
</template>   
<script>
  import {unfollow_user, get_all_followers, get_all_following} from "@/network/community";
  export default {
    name: "CreatePost",
    data() {
      return {
        activeName: "following",
        followers: [],
        following: [],
        curView_page: [],
        currentPage:1,
        pagesize:10,
        files_count:0,
      };
    },
    methods: {
      goBack() {
        this.$router.back();
      },
      handleTabClick() {
        document.activeElement.blur();
        this.curView_page = this.activeName === 'following' ? this.following : this.followers;
      },
      userRowClicked(row) {
        this.$router.push({
          name: 'posts_view', params: { 
            uid: row.uid,
          }
        });
      },
      handleUnfollow(index, row) {
        this.$confirm('是否要取消关注' + row.nickname, '系统提示', {
          confirmButtonText: '确认',
          cancelButtonText: '返回',
          type: 'info'
        }).then(() => {
          unfollow_user(this.$store.getters.email, row.uid).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已取消关注'
              });
            }
            else {
              this.$message({
                type: 'warning',
                message: '取消关注失败，请稍后重试'
              });
            }
          })
        }).catch(() => {});
      },
      //分页 初始页currentPage、初始每页数据数pagesize和数据testpage--->控制每页几条
      handleSizeChange:function(size){
        this.pagesize = size;
        this.hadleGetFilesListApi();
      },
      // 控制页面的切换
      handleCurrentChange: function(currentPage) {
        this.currentPage = currentPage;
        this.hadleGetFilesListApi();
      },
      //对所有数据进行分页处理 发送请求
      hadleGetFilesListApi() {
        let curData = (this.activeName === 'following') ? this.following : this.followers;
        this.curView_page = curData.slice((this.currentPage-1)*this.pagesize, this.currentPage*this.pagesize);
        this.files_count = curData.length;
      },
    },
    created() {
      this.email = this.$store.getters.email;
      if (this.$route.params.follow_type !== undefined && this.$route.params.follow_type !== null) {
        this.activeName = this.$route.params.follow_type;
      }
      get_all_following(this.email).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          this.following = res.data; //后端数据查询时以控制有序
          this.hadleGetFilesListApi();
        }
        else {
          this.following = [];
        }
      }).catch((error) => {
        console.log(error);
      });
      get_all_followers(this.email).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          this.followers = res.data; //后端数据查询时以控制有序
          this.hadleGetFilesListApi();
        }
        else {
          this.followers = [];
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }
</script>

<style scoped>
  .home {
    overflow: hidden;
  }
  .el-container {
    margin: 0px;
  }
  .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .belowTop {
    text-align: center;
    margin-top: 60px;
    height: calc(100vh - 60px);
  }
  .el-page-header {
    position: fixed;
    top: 0;
    left:0;
    padding-left: 20px;
    width:100%;
    height: 60px;
    justify-content: start;
    align-items: center;
    background-color: #7a557b;
    color: white;
    overflow-y:hidden;
    z-index: 1000;
  }

  .el-tabs {
    margin: 0 auto;
    width: 70%;
  }
  ::v-deep .el-page-header__content {
    color: white;
  }
  ::v-deep .el-tabs__item:hover {
    color: #7a557b;
  }
  ::v-deep .el-tabs__item.is-top.is-active {
    color: #7a557b;
  }

  ::v-deep .el-tabs__item {
    width: 50px;
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #7a557b;
  }
  .unfollowCol {
    text-align: right;
  }

  #unfollowBtn {
    border: 0px;
    background-color: transparent;
    color: #7a557b;
    font-size: 17px;
  }
  
  #unfollowBtn:hover {
    background-color: transparent;
    color: #d6c5db;
  }

  .el-pagination {
    margin-top: 20px;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #7a557b;
  }

</style>