import request from '@/utils/request'

// 获取会员信息
export function get_member_information(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/member/get_member_information',
        method: 'post',
        data: data
    })
}