import request from '@/utils/request'

// 获取所有课程
// return: data: []
export function get_all_course(email) {
    const data = {
        email: email
    }
    return request({
        url: '/courses/get_all_courses',
        method: 'post',
        data: data,
    })
}

// 获取所有自营课程
// return: data: []
export function get_self_support_course(email) {
    const data = {
        email: email
    }
    return request({
        url: '/courses/get_self_support_courses',
        method: 'post',
        data: data,
    })
}

// 获取我的课程
// return: data: []
export function get_my_courses(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/courses/get_my_courses',
        method: 'post',
        data: data,
    })
}

// 获取我收藏的课程
// return: data: []
export function get_collected_courses(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/courses/get_collected_courses',
        method: 'post',
        data: data,
    })
}

// 收藏指定课程
// return: flag: int
export function collect_course(email,coId) {
    const data = {
        email: email,
        coId, coId
    }
    return request({
        url: '/courses/collect_course',
        method: 'post',
        data: data,
    })
}

// 取消收藏指定课程
// return: flag: int
export function uncollect_course(email,coId) {
    const data = {
        email: email,
        coId, coId
    }
    return request({
        url: '/courses/quit_collect_course',
        method: 'post',
        data: data,
    })
}

// 模糊搜索课程
// return: data: []
export function fuzzy_search_courses(email, courseType, category, isFree, sort, content, item) {
    const data = {
        email: email,
        courseType: courseType,
        category: category,
        isFree: isFree,
        sort: sort,
        content: content,
        item: item
    }
    return request({
        url: '/courses/fuzzy_search_courses',
        method: 'post',
        data: data,
    })
}
