<template>
  <div style="text-align: center">
    <el-row type="flex" justify="end">
      <el-col id="switch" :span="6">
        <el-switch
          v-model="kaoYan" active-text="保研" inactive-text="考研" active-color="#7a557b"
          inactive-color="#9a7d85" clearable @change="handleGetNews">
        </el-switch>
      </el-col>
      <el-col id="dpName"><p>{{ curDpName }}</p> </el-col>
      <el-col :span="6">
        <div class = "search">
          <el-input
              placeholder="搜索新闻" v-model="keywords" clearable @clear="handleClear" 
              @keyup.enter.native="handleSearch" suffix-icon="el-icon-search">
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-table
        :data="tableData_page" @row-click="rowClicked" v-loading="loading"
        @selection-change="handleSelectionChange" class="clickable-rows"
        :default-sort="{prop: 'create_date', order: 'descending'}">
      <el-table-column v-if="selectingMultiple" type="selection" width="50">
        <!--如点击了批量操作，勾选框会显示/隐藏-->
      </el-table-column>
      <el-table-column prop="file_name" label="新闻名称" width=730></el-table-column>
      <el-table-column prop="create_date" label="发布时间" width=300></el-table-column>
      <el-table-column id="collectCol" prop="link" label="收藏" width=70 fixed="right">
        <template slot-scope="scope">
          <el-button id="collectBtn" class="el-icon-star-off" v-if="scope.row.isCollect===0" 
            @click.stop="handleCollect(scope.$index, scope.row)"/>
          <el-button id="collectBtn" class="el-icon-star-on" v-else @click.stop="handleCollect(scope.$index, scope.row)"/>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[5,10,15]" :page-size="pagesize" layout="total,jumper,prev, pager, next,sizes" :total="files_count">
    </el-pagination>
  </div>
</template>

<script>
import {get_dp_type, insert_fav_news, remove_fav_news} from "@/network/home";

export default {
  name: "DepartmentSummary",
  // provide () { //注入重新加载组件方法（子页面可以通过inject属性接收）
  //   return {
  //     reload: this.reload
  //   }
  // },
  watch: {
    '$route'(to, from) { //dpId更换要刷新本页面
      if (to.params.dpId != from.params.dpId) {
        this.curDpName = to.query.dpName;
        this.handleGetNews();
        //this.kaoYan = false;
        //刷新界面后，这个其实不用改，原来是什么就还是什么就行，因为查询的字段是按照原来的this.kaoyan的值来查的
        //或者这块可以改成this.kaoYan = false; 这体现的是页面刷新后，按钮的显示情况，那么在created的get_dp_type里面infoType直接传递false就行
        //或者这块可以改成this.kaoYan = true; 这体现的是页面刷新后，按钮的显示情况，那么在created的get_dp_type里面infoType直接传递true就行
        //或者后面created的get_dp_type函数不用改，直接把78行和77行交换一下即可，不然查询是时候是按照原来的this.kaoyan查询的，但刷新后，this.kaoyan都变成false了
      }
    }
  },
  data() {
    return {
      tableDataAll: [
        {
          "create_date": "请于左栏选择待查找的院所",
          "fid": 0,
          "file_name": "请于左栏选择待查找的院所",
          "link": "https://www.xsaifor.com/"
        }
      ],
      tableData: [
        {
          "create_date": "请于左栏选择待查找的院所",
          "fid": 0,
          "file_name": "请于左栏选择待查找的院所",
          "link": "https://www.xsaifor.com/"
        }
      ],
      tableData_page: [
        {
          "create_date": "请于左栏选择待查找的院所",
          "fid": 0,
          "file_name": "请于左栏选择待查找的院所",
          "link": "https://www.xsaifor.com/"
        }
      ],
      selectingMultiple: false,
      selected: [],
      curDpName: '',
      kaoYan: true,
      activeIndex: '/information/department_summary',
      currentPage:1,
      pagesize:10,
      files_count:0,
      information_type: false, // true: 保研， false: 考研
      keywords: '',
      loading: true,
    }
  },
  methods: {
    handleClear() {
      this.tableData = this.tableDataAll;
      this.hadleGetFilesListApi();//刷新页面
    },
    handleSearch() {
      this.tableData = this.tableDataAll.filter((p)=>{
        return p.file_name.indexOf(this.keywords) !== -1;
      })
      this.hadleGetFilesListApi();//刷新页面
    },
    //分页 初始页currentPage、初始每页数据数pagesize和数据testpage--->控制每页几条
    handleSizeChange:function(size){
      this.pagesize = size;
      this.hadleGetFilesListApi();
      console.log(this.pagesize);
    },
    // 控制页面的切换
    handleCurrentChange: function(currentPage) {
      this.currentPage = currentPage;
      this.hadleGetFilesListApi();
    },
    //对所有数据进行分页处理 发送请求
    hadleGetFilesListApi() {
      this.tableData_page = this.tableData.slice((this.currentPage-1)*this.pagesize, this.currentPage*this.pagesize);
      this.files_count = this.tableData.length;
    },
    rowClicked(row) {  //跳转到原文
      //location.href=row.link // 直接跳转
      window.open(row.link, '_blank')  //打开新页面跳转
    },
    selectMultipleClicked() {
      //显示/隐藏 批量操作勾选框
      this.selectingMultiple = !this.selectingMultiple;
    },
    handleSelectionChange(selection) {
      this.selected = selection;
    },
    handleGetNews() {
      this.keywords = '';
      const email = this.$store.getters.email;
      const departmentId = this.$route.query.dpId;
      //重新请求后端
      if (departmentId === undefined) return; //如果院所ID没有确定，则不查询信息
      this.get_dp_type(email, departmentId);
    },
    handleCollect(index, row) {
      if (row.isCollect === 0) {
        insert_fav_news(this.$store.getters.email, row.fid).then((res) => {
          if (res.flag === 1) {
            row.isCollect = 1;
            this.$message({
              type: 'success',
              message: '已收藏'
            });
          }
          else {
            this.$message({
              type: 'warning',
              message: '收藏失败，请稍后重试'
            });
          }
        })
      }
      else {
        remove_fav_news(this.$store.getters.email, row.fid).then((res) => {
          if (res.flag === 1) {
            row.isCollect = 0;
            this.$message({
              type: 'success',
              message: '已取消收藏'
            });
          }
          else {
            this.$message({
              type: 'warning',
              message: '取消收藏失败，请稍后重试'
            });
          }
        })
      }
    },
    get_dp_type(email, departmentId) {
      this.loading = true;
      get_dp_type(email, departmentId, this.kaoYan).then((res) => {//获取指定院所的信息
        if (res !== undefined && res.files !== undefined) {
          this.tableDataAll = res.files;
          this.tableData = res.files;//后端数据查询时以控制有序
          this.hadleGetFilesListApi();
        }
        else {
          this.tableDataAll = [];
          this.tableData = [];
          this.hadleGetFilesListApi();
        }
        this.loading = false;
        //this.$router.go(1);//必须刷新页面
      }).catch((error) => {
        console.log(error);
      })
    },
  },
  activated () {
    const email = this.$store.getters.email;
    const departmentId = this.$route.query.dpId;
    if (departmentId === undefined) return; //如果院所ID没有确定，则不查询信息
    this.get_dp_type(email, departmentId);
  },
  deactivated () {
    const email = this.$store.getters.email;
    const departmentId = this.$route.query.dpId;
    if (departmentId === undefined) return; //如果院所ID没有确定，则不查询信息
    this.get_dp_type(email, departmentId);
  },
  mounted() {
    // if (localStorage.getItem('reloaded')) {
    //   // The page was just reloaded. Clear the value from local storage
    //   // so that it will reload the next time this page is visited.
    //   localStorage.removeItem('reloaded');
    // } else {
    //   // Set a flag so that we know not to reload the page twice.
    //   localStorage.setItem('reloaded', '1');
    //   location.reload();
    // }
  },
  created() {
    const email = this.$store.getters.email;
    const departmentId = this.$route.query.dpId;
    this.curDpName = this.$route.query.dpName;
    if (departmentId === undefined) return; //如果院所ID没有确定，则不查询信息
    this.get_dp_type(email, departmentId);
  },
}
</script>

<style scoped>
.clickable-rows {
  width: 100%;
  height: 80%;
  margin-top: 20px;
  cursor: default;
  border-style: solid;
  border-color: #09073c;
}

#collectBtn {
  border: 0px;
  background-color: transparent;
  color: #7a557b;
  font-size: 17px;
}

#collectBtn:hover {
  background-color: transparent;
  color: #d6c5db;
}

.el-table {
  border: 0;
}

.search ::v-deep .el-input__inner{
  height:40px;
  border-radius: 40px;
  width: 300px;
  float:right;
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
}
.search ::v-deep .el-input__inner:focus{
  border-color: #7a557b;
}

#switch {
  align-self: center;
  text-align: center;
}

::v-deep .el-switch .el-switch__label--right {
  color: #D6C5DB !important;
}

::v-deep .el-switch .el-switch__label--left {
  color: #D6C5DB !important;
}

::v-deep .el-switch .el-switch__label--right.is-active {
  color: #7A517C !important;
}


::v-deep .el-switch__label.is-active {
  color:#7A517C !important;
}

.word_button {
  height: 30px;
  width: 90px;
  border: 0;
  background: transparent;
  margin: 10px;
  cursor: pointer;
  color: #7a557b;
}
.word_button:hover {
  color: #9a7d85;
}
</style>