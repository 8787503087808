<template>
  <div style="text-align: center">
    <el-row id="formRow">
      <el-form :model="formFilter" ref="formFilter" :inline="true" class="filterForm">
        <el-form-item id="typeItem">
          <el-select v-model="formFilter.type" placeholder="类型">
            <el-option label="全部" value=2></el-option>
            <el-option label="保研" value=1></el-option>
            <el-option label="考研" value=0></el-option>
          </el-select>
        </el-form-item>
        <el-form-item id="yearItem">
          <el-select v-model="formFilter.year" placeholder="新闻年份">
          <el-option label="全部" value="全部"></el-option>
          <el-option v-for="yrOpt in yearOptions" :label="yrOpt" :value="yrOpt" :key="yrOpt"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item id="dpItem">
          <el-select v-model="formFilter.department" placeholder="院校名称">
          <el-option label="全部" value="全部"></el-option>
          <el-option v-for="fDp in favDepartments" :label="fDp.department_name" :value="fDp.dpId" :key="fDp.dpId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="新闻内容(默认显示全部)"
            v-model="formFilter.content"
            clearable
            suffix-icon="el-icon-search">
          </el-input>
        </el-form-item>
        <el-form-item id="btnItem">
          <el-button type="primary" @click="handleFilter">查找新闻</el-button>
        </el-form-item>
        <el-form-item>
          <el-button id="btnClearFilter" type="primary" @click="clearForm('formFilter')">清除筛选</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-table
      :data="tableData_page" @row-click="rowClicked" v-loading="loading"
      @selection-change="handleSelectionChange" class="clickable-rows"
        :default-sort="{prop: 'collect_date', order: 'descending'}">
      <el-table-column v-if="selectingMultiple" type="selection" width="50"/>
      <el-table-column prop="file_name" label="新闻名称"  fixed="left"></el-table-column>
      <el-table-column prop="dpName" label="发布院校"></el-table-column>
      <el-table-column prop="create_date" label="发布时间" width=150></el-table-column>
      <el-table-column prop="collect_date" label="收藏时间" width=150></el-table-column>
      <el-table-column prop="newsType" label="类型" width=50></el-table-column>
      <el-table-column id="collectCol" prop="link" label="收藏" width=70>
        <template slot-scope="scope">
          <el-button
            id="collectBtn" class="el-icon-star-on" @click.stop="handleCollect(scope.$index, scope.row)"/>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5,10,15]"
        :page-size="pagesize"
        layout="total,jumper,prev, pager, next,sizes"
        :total="files_count">
    </el-pagination>
  </div>
</template>
  
<script>
  import {get_fav_news, get_filtered_fav_news, remove_fav_news} from "@/network/home";
  
  export default {
    name: "CollectedNews",
    data() {
      return {
        tableDataAll: [
          {
            "create_date": "",
            "collect_date": "",
            "newsType": '',
            "fid": 0,
            "dpId": 0,
            "infoType": '',
            "dpName": 0,
            "file_name": "加载中",
            "link": "https://www.xsaifor.com/"
          }
        ],
        tableData: [
          {
            "create_date": "",
            "collect_date": "",
            "newsType": '',
            "fid": 0,
            "dpId": 0,
            "infoType": '',
            "dpName": 0,
            "file_name": "加载中",
            "link": "https://www.xsaifor.com/"
          }
        ],
        tableData_page: [],
        selectingMultiple: false,
        selected: [],
        departments: [],
        favDepartments: [
          {
            "department_name": "",
            "dpId": 0
          }
        ],
        formFilter: {
          type: '',
          year: '',
          department: '',
          content: '',
        },
        yearOptions: [],
        curDpName: '',
        kaoYan: false,
        currentPage:1,
        pagesize:10,
        files_count:0,
        information_type: true, // true: 保研， false: 考研
        keywords: '',
        loading: true,
      }
    },
    methods: {
      handleSearch() {
        this.tableData = this.tableDataAll.filter((p)=>{
          return p.dpName.indexOf(this.keywords) !== -1;
        })
        this.hadleGetFilesListApi();//刷新页面
      },
      handleFilter() {
        const email = this.$store.getters.email;
        let tempType = this.formFilter.type === '' ? 2 : this.formFilter.type;
        let tempYear = this.formFilter.year === '全部' ? "" : this.formFilter.year;
        let tempDp = this.formFilter.department === '' || this.formFilter.department === '全部' ? -1 : this.formFilter.department;
        get_filtered_fav_news(email, tempType, tempDp, tempYear, this.formFilter.content)
        .then((res) => {
          if (res !== undefined && res.files !== undefined) {
            this.tableData = res.files;
            this.tableData.forEach(tableNews => {
              let foundDp = this.departments.find(dp => dp.fid === tableNews.dpId);
              tableNews['dpName'] = foundDp.dp_name;
              tableNews['newsType'] = tableNews.infoType === 0 ? '考研':'保研';
              let yr = tableNews.create_date.slice(0,4);
              if (!this.yearOptions.includes(yr)) {
                this.yearOptions.push(yr);
              }
            });
            this.hadleGetFilesListApi();
            this.yearOptions.sort();
          }
          else {
            this.tableData = [];
          }
        }).catch((error) => {
          console.log(error);
        })
        this.hadleGetFilesListApi();//刷新页面
      },
      clearForm(formName) {
        this.$refs[formName].resetFields();
        this.$set(this.formFilter,'type','');
        this.$set(this.formFilter,'year','');
        this.$set(this.formFilter,'department','');
        this.tableData = this.tableDataAll;
        this.hadleGetFilesListApi();//刷新页面
      },
      //分页 初始页currentPage、初始每页数据数pagesize和数据testpage--->控制每页几条
      handleSizeChange:function(size){
        this.pagesize = size;
        this.hadleGetFilesListApi();
        console.log(this.pagesize);
      },
      // 控制页面的切换
      handleCurrentChange: function(currentPage) {
        this.currentPage = currentPage;
        this.hadleGetFilesListApi();
      },
      //对所有数据进行分页处理 发送请求
      hadleGetFilesListApi() {
        this.tableData_page = this.tableData.slice((this.currentPage-1)*this.pagesize, this.currentPage*this.pagesize);
        this.files_count = this.tableData.length;
      },
      rowClicked(row) {  //跳转到原文
        //location.href=row.link // 直接跳转
        window.open(row.link, '_blank')  //打开新页面跳转
      },
      handleSelectionChange(selection) {
        this.selected = selection;
      },
      handleCollect(index, row) {
        remove_fav_news(this.$store.getters.email, row.fid).then((res) => {
          if (res.flag === 1) {
            row.isCollect = 0;
            this.$message({
              type: 'success',
              message: '已取消收藏'
            });
          }
          else {
            this.$message({
              type: 'warning',
              message: '取消收藏失败，请稍后重试'
            });
          }
        })
        this.tableDataAll.pop(row);
        this.tableData = this.tableDataAll;
        this.hadleGetFilesListApi();
      },
    },
    created() {
      const email = this.$store.getters.email;
      this.departments = this.$store.getters.departments;
      get_fav_news(email).then((res) => {
        if (res !== undefined && res.files !== undefined) {
          this.tableDataAll = res.files;
          this.tableDataAll.forEach(tableNews => {
            let foundDp = this.departments.find(dp => dp.fid === tableNews.dpId);
            tableNews['dpName'] = foundDp.dp_name;
            tableNews['newsType'] = tableNews.infoType === 0 ? '考研':'保研';
            let yr = tableNews.create_date.slice(0,4);
            if (!this.yearOptions.includes(yr)) {
              this.yearOptions.push(yr);
            }
          });
          this.tableData = this.tableDataAll;//后端数据查询时以控制有序
          this.hadleGetFilesListApi();
          this.favDepartments = res.dps;
          this.yearOptions.sort();
          this.loading = false;
        }
        else {
          this.tableDataAll = [];
          this.tableData = [];
        }
      }).catch((error) => {
        console.log(error);
      })
    },
  }
  </script>
  
  <style scoped>
  .clickable-rows {
    width: 100%;
    height: 80%;
    margin-top: 20px;
    cursor: default;
    border-style: solid;
    border-color: #09073c;
  }
  .el-input {
    width: 300px;
  }
  ::v-deep .el-input__inner {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  }
  
  #formRow {
    display: flex;
    text-align: start;
  }

  #typeItem {
    width: 90px;
  }

  #yearItem {
    width: 120px;
  }

  #btnItem {
    margin-right: 10px;
  }

  #btnClearFilter {
    background-color: white;
  }
  #btnClearFilter:hover{
    background: #9c7dae;
  }

  .el-button {
    border: none;
    text-align: center;
    background-color: #d6c5db;
    color: #303133;
    border-radius: 10px;
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  }

  .el-button:hover{
    background: #9c7dae;
    color: white;
  }

  #btnItem.el-button {
    color: white;
  }
  
  #collectBtn {
    border: 0px;
    background-color: transparent;
    color: #7a557b;
    font-size: 17px;
  }
  
  #collectBtn:hover {
    background-color: transparent;
    color: #d6c5db;
  }
  
  .el-table {
    border: 0;
  }
  
  /* .search ::v-deep .el-input__inner{
    height:40px;
    border-radius: 40px;
    width: 300px;
    float:right;
  } */
  
</style>