<template>
  <div>
    <el-container>
      <el-main>
        <h1 style="font-size: 24px;">发送重置密码邮件</h1>
        <div class="box">
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px">
            <el-form-item class="items" label="邮箱" prop="myEmail">
              <el-input v-model="ruleForm.myEmail" placeholder="邮箱号"
                        style="width:300px" clearable>
              </el-input>
            </el-form-item>
            <el-form-item class="items" label-width="0px" style="width:400px">
<!--              <el-button type="primary" @click="submitForm('ruleForm')">发送</el-button>-->
              <!-- 倒计时按钮 -->
                <el-button type="primary" :disabled="disable" :class="{ codeGetting:isGetting }"
                         @click="submitForm('ruleForm')">{{getCode}}</el-button>
                <el-button @click="skipPassword">返回登录</el-button>

            </el-form-item>
          </el-form>
        </div>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import {forget_password_request} from "@/network/login";
import Cookies from "js-cookie";

export default {
  name: "forgetPassword_request.vue",
  data() {
    return {
      ruleForm: {
        myEmail: '',
      },
      getCode: '发送',
      isGetting: false,
      disable: false,
      timer:null,
      rules: {
        myEmail: [
          { required: true, message: "邮箱地址不能为空", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
      }
    }
  },
  methods: {
    skipPassword(){
      this.$router.push('/start');
    },
    forgetPassword_request(myEmail) {
      forget_password_request(myEmail).then((res) => {
        if (res.flag === 1) {
          this.$message({
            type: 'success',
            message: '发送成功!'
          });
        }
        else if (res.flag === 0) {
          this.$message({
            type: 'warning',
            message: '发送失败'
          });
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否向邮箱' +
              this.ruleForm.myEmail + '发送重置密码邮件', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            //每次点击发送都重新设置定时器为60s
            Cookies.set("test", 60, { expires: 60000/864e5 });//expires: 60000/864e5 指cookies过期时间，这里单位是天，换算后即60s
            this.forgetPassword_request(this.ruleForm.myEmail);
            //设置定时器
            let _this=this;
            this.timer = setInterval(() => {
              if (Cookies.get("test") < 1) {
                _this.isGetting = false
                _this.disable = false
                _this.getCode = '发送'
                Cookies.set("test",6)
                clearInterval(this.timer)
              } else {
                _this.isGetting = true
                _this.disable = true
                _this.getCode = Cookies.get("test") + 's后重发'
                Cookies.set("test",Cookies.get("test")-1)
              }
            }, 1000)
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消发送'
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm.myEmail = '';
    }
  },
  created() {
  },
}
</script>

<style scoped>
.el-form {
  margin-top: 60px;
}

.el-form-item {
  margin: 20px auto;
  width: 500px;
}

.el-row {
  padding: 10px;
  margin-bottom: 20px;
}

.el-col {
  padding-left: 10px;
}

.el-main {
  width: 100%;
}

.info {
  width: 100px;
  height: 50px;
  margin-left: 50px;
}
.el-input{

  right: 50px;
}
.box {
  width: 100%;
  text-align: center;
}

.el-button {
  margin: 20px;
}
</style>