<template>
  <div>
    <el-container>
      <el-main>
        <h2>修改信息</h2>
        <div class="box" style="background: white">
          <div class="info-title">我的昵称<span class="info">{{ this.$store.getters.email }}</span></div>
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item class="items" label="昵称" prop="newNickname">
              <el-input v-model="ruleForm.newNickname" placeholder="昵称" style="width:300px" clearable></el-input>
            </el-form-item>
            <el-form-item class="items" label="姓名" prop="name">
              <el-input v-model="ruleForm.name" placeholder="姓名" style="width:300px" clearable></el-input>
            </el-form-item>
            <el-form-item class="items" label="性别" prop="sex">
              <el-input v-model="ruleForm.sex" placeholder="性别" style="width:300px" clearable></el-input>
            </el-form-item>
            <el-form-item class="items" label="出生日期" prop="birthday">
              <el-input v-model="ruleForm.birthday" placeholder="出生日期" style="width:300px" clearable></el-input>
            </el-form-item>
            <el-form-item class="items" label="学校" prop="school">
              <el-input v-model="ruleForm.school" placeholder="学校" style="width:300px" clearable></el-input>
            </el-form-item>
            <el-form-item class="items" label="专业" prop="major">
              <el-input v-model="ruleForm.major" placeholder="专业" style="width:300px" clearable></el-input>
            </el-form-item>
            <el-form-item class="items" label="学号" prop="studentNumber">
              <el-input v-model="ruleForm.studentNumber" placeholder="学号" style="width:300px" clearable></el-input>
            </el-form-item>
            <el-form-item class="items" label="密码" prop="password">
              <el-input type="password" v-model="ruleForm.password" style="width:300px" clearable></el-input>
            </el-form-item>
            <el-form-item class="items" label-width="0px" style="width:400px">
              <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-button @click="$router.back()">返回</el-button>
        <el-button @click="$router.push('/profile/reset_email')">修改邮箱</el-button>
        <el-button @click="$router.push('/profile/reset_password')">修改密码</el-button>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import {request_reset_information} from "@/network/profile";

export default {
  name: "ResetNickname.vue",
  data() {
    return {
      ruleForm: {
        newNickname: '',
        password: '',
        name:'',
        sex:'',
        birthday:'',
        school:'',
        major:'',
        studentNumber:'',
      },
      rules: {
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        newNickname: [
          { required: true, message: "昵称不能为空", trigger: "blur" }
        ],
      }
    }
  },
  methods: {
    resetInformation(newNickname, name, sex, birthday, school, major, studentNumber, password) {
      request_reset_information(this.$store.getters.email, newNickname, name, sex, birthday, school, major, studentNumber, password).then((res) => {
        if (res.flag === 1) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
        }
        else if (res.flag === 2) {
          this.$message({
            type: 'warning',
            message: '修改失败，昵称已被使用过，请重试'
          });
        }
        else if (res.flag === 3) {
          this.$message({
            type: 'warning',
            message: '修改失败，密码错误，请重试'
          });
        }
        else if (res.flag === 0) {
          this.$message({
            type: 'warning',
            message: '修改失败，请稍后再试'
          });
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否确认修改昵称为：' +
              this.ruleForm.newNickname, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.resetInformation(this.ruleForm.newNickname, this.ruleForm.name, this.ruleForm.sex, this.ruleForm.birthday,
                this.ruleForm.school, this.ruleForm.major, this.ruleForm.studentNumber, this.ruleForm.password);
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消更改'
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm.newNickname = '';
    }
  },
  created() {
  },
}
</script>

<style scoped>
.el-header {
  margin-top: 20px;
}

.el-form {
  margin-top: 20px;
  text-align: left;
}

.el-form-item {
  padding-top: 20px;
}

.el-row {
  padding: 10px;
  margin-bottom: 20px;
}

.el-col {
  padding-left: 10px;
}

.el-main {
  background-color: #6495ED;
  color: #333;
}

.items {
  text-align: left;
  margin: 5px;
}

.info, .el-input {
  width: 100px;
  height: 50px;
  margin-left: 50px;
}

.box {
  padding: 50px;
}

.info-title {
  margin-left: 40px;
  padding-right: 100px;
  text-align: left;
}

.el-button {
  margin: 20px;
  width: 100px;
}
</style>