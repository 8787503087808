import { login_with_password,login_with_email, logout, getInfo } from '@/network/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { Notification, MessageBox, Message, Loading } from 'element-ui'

const user = {
  state: {
    token: getToken(),
    name: '',
    email: '',
    avatar: '',
    roles: [],
    permissions: [],
    access_type: -1,
    prev_window: '-1',
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_ACCESS_TYPE: (state, access_type) => {
      state.access_type = access_type
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_PREV_WINDOW: (state, prev_window) => {
      state.prev_window = prev_window
    }

  },

  actions: {
    // 密码登录
    Login_password({ commit }, userInfo) {
      const email = userInfo.email.trim()
      const password = userInfo.password
      return new Promise((resolve, reject) => {
        login_with_password(email, password).then(res => {
          if(res.flag===1){
            setToken(res.token)
            commit('SET_EMAIL' ,email)
            commit('SET_ACCESS_TYPE' , 0)
            commit('SET_TOKEN', res.token)
            commit('SET_PREV_WINDOW', -1)
          }
          else if(res.flag===0){
            removeToken();
            commit('SET_TOKEN', '');
            Message({
              message: "不存在的用户名",
              type: 'error',
              duration: 5 * 1000
            })
          }
          else{
            removeToken();
            commit('SET_TOKEN', '');
            Message({
              message: "密码错误",
              type: 'error',
              duration: 5 * 1000
            })
          }
          resolve(res.flag)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 邮件验证登录
    Login_email({ commit }, userInfo) {
      const email = userInfo.email.trim()
      const vercode = userInfo.vercode
      const vercode_user = userInfo.vercode_user
      return new Promise((resolve, reject) => {
        // login_with_email(email, realCode,code).then(res => {
        //   setToken(res.token)
        //   commit('SET_EMAIL' ,email)
        //   commit('SET_TOKEN', res.token)
        //   resolve()
        login_with_email(email, vercode,vercode_user).then(res => {
          console.log(res.flag);
          if(res.flag===1){
            setToken(res.token)
            commit('SET_TOKEN', res.token)
            commit('SET_EMAIL' ,email)
            commit('SET_ACCESS_TYPE' , 0)
            commit('SET_PREV_WINDOW', -1)
          }
          else if(res.flag===0){
            removeToken();
            commit('SET_TOKEN', '');
            Message({
              message: "不存在的用户名",
              type: 'error',
              duration: 5 * 1000
            })
          }
          else{
            removeToken();
            commit('SET_TOKEN', '');
            Message({
              message: "验证码错误",
              type: 'error',
              duration: 5 * 1000
            })
          }
          resolve(res.flag)
        }).catch(error => {
          reject(error)
        })
      })
    },

//     // 获取用户信息
//     GetInfo({ commit, state }) {
//       return new Promise((resolve, reject) => {
//         getInfo().then(res => {
//           const user = res.user
//           const avatar = user.avatar == "" ? require("@/assets/images/profile.jpg") : process.env.VUE_APP_BASE_API + user.avatar;
//           console.log(avatar);
//           if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
//             commit('SET_ROLES', res.roles)
//             commit('SET_PERMISSIONS', res.permissions)
//           } else {
//             commit('SET_ROLES', ['ROLE_DEFAULT'])
//           }
//           commit('SET_NAME', user.userName)
//           commit('SET_AVATAR', avatar)
//           resolve(res)
//         }).catch(error => {
//           reject(error)
//         })
//       })
//     },
//
//     // 退出系统
    LogOut({ commit, state }) {
      commit('SET_TOKEN', '');
      commit('SET_EMAIL', '');
      commit('SET_NAME', '');
      commit('SET_ACCESS_TYPE', -1);
      commit('SET_PREV_WINDOW', -1)
      return;
    },
//
//     // 前端 登出
//     FedLogOut({ commit }) {
//       return new Promise(resolve => {
//         commit('SET_TOKEN', '')
//         removeToken()
//         resolve()
//       })
//     }
  }
}

export default user
