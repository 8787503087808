

const doc = {
    state: {
        new_doc: ''
    },
    mutations: {
        ADD_doc: (state, doc) => {
            state.new_doc = doc
        },
    }
}

export default doc