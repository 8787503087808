
import { Loading } from 'element-ui'
/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result
}


/**
 * 异步加载 script 文件-允许阻塞
 * 可指定在某个元素内加载，默认为head
 * 可传入css选择器或者dom节点
 * @param src 加载js文件的src
 * @param el 指定插入元素的位置
 */
export async function asyncLoadScript(src, el = "head") {
  let addSign = true;
  const scripts = document.getElementsByTagName("script");
  // 判断是否已经引入
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i] && scripts[i].src && scripts[i].src.indexOf(src) != -1) {
      addSign = false;
    }
  }
  if (addSign) {
    const $script = document.createElement("script");
    $script.setAttribute("type", "text/javascript");
    $script.setAttribute("src", src);
    $script.async = false;
    return new Promise((resolve, reject) => {
      try {
        if (el instanceof Element) {
          el.appendChild($script);
        } else {
          document.querySelector(el).appendChild($script);
        }
        $script.onload = function() {
          resolve($script);
        };
      } catch (e) {
        reject("loadScript 选择器未查找到相关DOM");
      }
    });
  }
}

export function starLoading() {
  // 创建一个 loading 实例并赋值给 loading 变量
  let loading = Loading.service({
      text: "Loading", // 设置 loading 文本为 "加载中"
      background: "rgba(0, 0, 0, 0.5)", // 设置 loading 遮罩层背景色为半透明黑色
      fullscreen: true,
  });
  // 返回一个包含关闭 loading 遮罩层方法的对象
  return {
      // 方法用于关闭 loading 遮罩层
      closeLoading: () => {
        loading.close(); // 调用 loading 实例的 close 方法关闭遮罩层
      }
  };
}
