

const image = {
    state: {
        new_image: ''
    },
    mutations: {
        ADD_image: (state, image) => {
            state.new_image = image
        },
    }
}

export default image