<template>
  <el-container class="home">
    <el-header class="app_header">
      <el-button id="mainButton" type="text" @click="$router.push('/home')">DAWN</el-button>
      <div class="topBarButtons">
        <el-button type="text" @click="$router.push('/home/headline_news')">头条新闻</el-button>
        <el-button type="text" @click="$router.push('/community/home')">经验交流</el-button>
        <el-button type="text" @click="$router.push('/file/home')">文件共享</el-button>
        <el-button type="text" @click="$router.push('/ai/home')">智能AI</el-button>
        <el-button type="text" @click="$router.push('/course/home')">自我提升</el-button>
        <el-button type="text" @click="$router.push('/intelligence')">智能教研</el-button>
        <el-button type="text" @click="$router.push('/contact_us')">联系我们</el-button>
        <el-badge is-dot v-if="hvNotice" class="app_notification">
          <el-dropdown @command="handleClickNotification">
            <span class="el-dropdown-link"><i class="el-icon-bell"></i></span>
            <el-dropdown-menu slot="dropdown" class = "app_window">
              <el-dropdown-item command="system">系统通知</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-badge>
        <el-dropdown v-else class="app_notification" @command="handleClickNotification">
          <span class="el-dropdown-link"><i class="el-icon-bell"></i></span>
          <el-dropdown-menu slot="dropdown" class = "app_window">
            <el-dropdown-item command="system">系统通知</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown class="app_dropdown" @command="handleClickDropdown">
          <span class="el-dropdown-link"><i class="el-icon-user-solid"></i></span>
          <el-dropdown-menu slot="dropdown" class = "app_window">
            <el-dropdown-item command="profile">个人中心</el-dropdown-item>
            <el-dropdown-item command="resetPsd">修改密码</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <!--  图标链接  -->
    <link rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
          integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
          crossorigin="anonymous">
      <el-container class="belowTop">
        <el-aside width="collapse">
          <el-menu router :default-active="activeIndex" class="el-menu-vertical">
          <el-menu-item index="/intelligence/mail"><i class="el-icon-message"></i><span slot="title">邮件投递</span></el-menu-item>
          <el-menu-item index="/intelligence/timeline"><i class="el-icon-date"></i><span slot="title">时间流</span></el-menu-item>
          <el-menu-item index="/intelligence/plans"><i class="el-icon-reading"></i> <span slot="title">计划书</span></el-menu-item>
        </el-menu>
        </el-aside>
        <el-main class="">
          <router-view></router-view>
        </el-main>
      </el-container>
  </el-container>
</template>

<script>

import {get_notifications} from "@/network/notification";

export default {
  name: "Intelligence",
  data() {
    return {
      activeIndex: this.$route.path,
      notifications: [],
      notices: [],
      email: '',
      haveUnread: false,
      departmentsAll: [],
      favDepartmentsAll: [],
      departments: [
        {
          "fid": "加载中",
          "dp_name": "加载中",
          "link": "加载中"
        }
      ],
      keywords: '',
      hvNotice: false,
    }
  },
  methods: {
    handleClickNotification(command) {
      if (command === "system") {
        this.$router.push('/notification/');
      }
    },
    handleClickDropdown(command) {
      if (command === "logout") {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push('/login/');
        })
      }
      else if (command === "profile") {
        this.$router.push('/profile/');
      }
      else if (command === "resetPsd") {
        this.$router.push('/reset_password/');
      }
    },
    handleSearchDp() {
      this.departments = this.departmentsAll.filter((p)=>{
        return p.dp_name.indexOf(this.keywords) !== -1;
      })
      this.hadleGetFilesListApi();//刷新页面
    },
  },
  created() {
    this.email = this.$store.getters.email;
    get_notifications(this.email).then((res) => {
      let notices = res.data;
      notices.forEach(notice => {
        if (notice.isRead === 0) {
          this.hvNotice = true;
        }
      });
    })
  },
}
</script>

<style scoped>
.el-menu-item {
  font-size: 15px;
}
.el-menu-vertical:not(.el-menu--collapse) {
  width: 240px;
}

.el-header {
  position: fixed;
  top: 0;
  left:0;
  width:100%;
  justify-content: center;
  align-items: center;
  background-color: #7a557b;
  z-index: 1000;
}

#mainButton {
  position: absolute;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  left: 0;
  font-size: 30px;
  margin-left: 30px;
  top: 0;
  margin-top: 0;
}

.app_header .el-button {
  color: white;
  margin: 10px;
}

.el-aside .el-menu {
  overflow-x: hidden;
  text-align: start;
  height: 100%;
  overflow-y: auto;
}

.el-menu-item, .el-submenu-title {
  background-color: transparent !important;
}

.el-submenu .el-menu-item.is-active {
  background-color: #f3e6f6 !important;
}

.el-menu-item.is-active {
  color: #7a517c !important;
  background-color: #f3e6f6 !important;
}

.el-menu-item:hover, .el-submenu:hover {
  background-color: #d6c5db !important;
}

.el-submenu-title:hover {
  background-color: #d6c5db !important;
}

.left-nav-menu {
  overflow-y: scroll;
  max-height: 400px;
}

#foldBtn {
  bottom: 0;
  left: 0;
}

.el-card {
  text-align: start;
}

.el-main {
  background-color: #f3e6f6;
  color: #333;
  text-align: center;
  flex: 1;
  overflow-y: auto;
}

.app_dropdown {
  position: absolute;
  right: 0;
  top: 20px;
  margin-right: 30px;
}

.el-dropdown-menu__item:hover {
  background-color: #f3e6f6;
  color: #7a517c;
}

.el-icon-user-solid {
  color: white;
  font-size: 20px;
}

.app_notification {
  position: absolute;
  right: 50px;
  top: 20px;
  margin-right: 30px;
}

::v-deep .el-badge__content.is-dot {
  border: none;
}

.el-icon-bell {
  color: white;
  font-size: 20px;
}

.belowTop {
  margin-top: 60px;
  height: calc(100vh - 60px);
  display: flex;
  overflow: hidden;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #9c7dae;
}

.search ::v-deep .el-input__inner{
  height:40px;
  border-radius: 10px;
  width: 200px;
  left:10px;
  right:10px;
  font-size: 12px;
  margin: 10px;
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
}

.search ::v-deep .el-icon-search{
  height:40px;
  border-radius: 10px;
  width: 50px;
  right:20px;
}
.search ::v-deep .el-input__inner:focus{
  border-color: #7a557b;
}
.el-message-box ::v-deep .el-button {
  background-color: #7a517c !important;
}
</style>