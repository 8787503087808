<template>
  <div>
    <el-page-header @back="goBack" content="DAWN">
    </el-page-header>
    <el-container>
      <el-main>
        <div class="box" style="background: white">
          <h1>修改密码</h1>
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item class="items" label="旧密码" prop="oldPassword">
              <el-input type="password" v-model="ruleForm.oldPassword" style="width:300px" clearable></el-input>
            </el-form-item>
            <el-form-item class="items" label="新密码" prop="newPassword">
              <el-input type="password" v-model="ruleForm.newPassword" style="width:300px" clearable></el-input>
            </el-form-item>
            <el-form-item class="items" label="确认新密码" prop="reNewPassword">
              <el-input type="password" v-model="ruleForm.reNewPassword" style="width:300px" clearable></el-input>
            </el-form-item>
            <el-form-item class="items" label-width="0px" style="width:400px">
            </el-form-item>
          </el-form>
          <el-button class="backBtn" @click="$router.back()">返回</el-button>
          <el-button class="editBtn" type="primary" @click="submitForm('ruleForm')">修改</el-button>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import {request_reset_password} from "@/network/profile";

export default {
  name: "ResetPassword.vue",
  data() {
    var validatePass = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('请输入新密码'));
    } else {
      if (this.ruleForm.reNewPassword !== '') {
        this.$refs.ruleForm.validateField('rePassword');
      }
      callback();
    }
  };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        oldPassword: '',
        newPassword: '',
        reNewPassword: '',
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入您的旧密码", trigger: "blur" },
        ],
        newPassword: [
          { validator: validatePass, required: true, trigger: "blur" },
        ],
        reNewPassword: [
          { validator: validatePass2, required: true, trigger: "blur" },
        ],
      }
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    resetPassword(oldPassword, newPassword) {
      request_reset_password(this.$store.getters.email, oldPassword, newPassword).then((res) => {
        if (res.flag === 1) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
        }
        else if (res.flag === 2) {
          this.$message({
            type: 'warning',
            message: '修改失败，密码错误，请重试'
          });
        }
        else if (res.flag === 0) {
          this.$message({
            type: 'warning',
            message: '修改失败，请稍后再试'
          });
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否确认修改密码?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.resetPassword(this.ruleForm.oldPassword, this.ruleForm.newPassword);
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消更改'
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm.newPassword = '';
    }
  },
  created() {
  }
}
</script>

<style scoped>
.el-page-header {
  position: fixed;
  top: 0;
  left:0;
  padding-left: 20px;
  width:100%;
  height: 60px;
  justify-content: start;
  align-items: center;
  background-color: #7a557b;
  color: white;
  overflow:hidden;
  z-index: 1000;
}

h1 {
  color: #4f4f4f;
}

::v-deep .el-page-header__content {
  color: white;
}
.el-form {
  margin-top: 60px;
}

.el-form-item {
  margin: 20px auto;
  width: 500px;
}

.el-row {
  padding: 10px;
  margin-bottom: 20px;
}

.el-col {
  padding-left: 10px;
}

.el-main {
  width: 100%;
}

.el-input{
  right: 50px;
}
.box {
  width: 100%;
  text-align: center;
  margin-top: 60px;
  /* height: calc(100vh - 60px); */
  overflow-x:hidden;
}

.el-button {
  margin: 20px;
  width: 100px;
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
}

.backBtn {
  background-color: #f3e6f6;
  border: 0;
  color: #7a557b;
}
.backBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}
.editBtn:hover  {
  background-color: #f3e6f6;
  border: 0;
  color: #7a557b;
}
.editBtn{
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}
</style>