<template>
  <el-dialog class="dialog" :visible.sync="visible" :before-close="handleClose" :append-to-body="true">
    <div style="text-align: center">
    <el-row type="flex" justify="center" class="formRow">
      <div class="title">模版选择</div>
    </el-row>
    <div v-loading="loading" class="templatesDiv">
      <el-row>
        <el-col :span="6">
          <el-card class="template" @click.native="handleRandomTemplateClick()">
            <div>随机模版</div>
            <el-button class="useBtn" @click.native="handleUseRandomTemp()">使用</el-button>
          </el-card>
        </el-col>
        <el-col :span="6" v-for="item in templates" :key="item.temId">
          <el-card class="template" @click.native="handleTemplateClick(item)">
            <div>{{ item.title }}</div>
            <el-button class="useBtn" @click.native="handleUseTemplateClick(item)">使用</el-button>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
  <MailTemplateSingle ref="mailTemplateSingle" class="popup" @childFn="getSelectedTemp"/>
  </el-dialog>
</template>

<script>
import MailTemplateSingle from './MailTemplateSingle.vue';

export default {
  name: "MailTemplates",
  props: { templates: Array },
  components: {
    MailTemplateSingle,
  },
  data() {
    return {
      visible: false,
      loading: true,
      email: '',
      templates: [],
      mailRowIdx: -1,
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getSelectedTemp(itemIdx, temId) {
      this.$emit("childFn", itemIdx, temId);
      this.handleClose();
    },
    openPopup(templates, idx) {
      this.visible = true;
      this.templates = templates;
      this.mailRowIdx = idx;
      this.loading = false;
    },
    handleTemplateClick(item) {
      this.$refs.mailTemplateSingle.openPopup(item, this.mailRowIdx);
    },
    handleRandomTemplateClick() {
      // Returns a random integer from 0 to N:
      let randomId = Math.floor(Math.random() * this.templates.length);
      this.$refs.mailTemplateSingle.openPopup(this.templates[randomId], this.mailRowIdx);
    },
    handleUseRandomTemp() {
      event.stopPropagation();
      let randomId = Math.floor(Math.random() * this.templates.length) + 1;
      this.$emit("childFn", this.mailRowIdx, randomId);
      this.handleClose();
    },
    handleUseTemplateClick(item) {
      event.stopPropagation();
      this.$emit("childFn", this.mailRowIdx, item.temId);
      this.handleClose();
    },
    initPopup() {
      this.visible = false;
    },
    handleClose() {
      this.visible = false;
    },
    closePopup() {
      this.visible = false;
    },
  },
  created() {
    this.email = this.$store.getters.email;
  },
}
</script>

<style scoped>
.el-card {
  cursor: pointer;
}
::v-deep .el-dialog {
  margin: 40px auto;
  width: 85%;
  max-height: 600px;
  overflow-y: auto;
}
.useBtn {
  position: absolute;
  width: 100px;
  bottom: 20px;
  left: 85px;
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  background-color: white;
  color: #7a557b;
  font-size: 15px;
  border: #7a557b 1px solid;
}
.useBtn:hover {
  background-color: #7a557b;
  color: #f3e6f6;
}
.template {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; */
  margin-top: 20px;
  width: 85%;
  height: 200px;
  padding: 10px;
  position: relative;
}
.templatesDiv {
  margin: 10px 0;
}
.backBtn {
  height: 40px;
  align-items: center;
  margin-right: 20px;
  border: none;
}
.backBtn:hover {
  color: #f3e6f6;
  background-color: #7a557b;
}
.title {
  margin-right: 20px;
  border-radius: 10px;
  border: 1px #7a557b solid;
  background-color: white;
  color: #7a557b;
  text-align: center;
  align-content: center;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  width: 85%;
}
</style>