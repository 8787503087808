<template>
  <div class="motaichuang">
    <div class="kuang">
      <header>
        <span></span>
        <i @click="closeBox">x</i>
      </header>
      <article ref="box">
        <el-row>
          <el-col :span="6" :offset="18"><el-button round @click="toRegister">注册</el-button></el-col>
        </el-row>
        <el-row>
          <el-col :offset="0">
            <h1>
              邮箱验证登录
            </h1>
          </el-col>
        </el-row>
        <el-row>
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="ruleForm.email" @keyup.enter.native="handleLogin"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="vercode_user">
              <el-col :span="13">
                <el-input v-model="ruleForm.vercode_user" autocomplete="off" @keyup.enter.native="handleLogin"></el-input>
              </el-col>
              <el-col :span="4" :offset="1">
                <!-- 倒计时按钮 -->
                <el-button type="primary" :disabled="disable" :class="{ codeGetting:isGetting }" @click="sendCode">{{getCode}}</el-button>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="6" :offset="2">
                <el-button type="primary" @click.native.prevent="handleLogin" :loading="btnLoading">登录</el-button>
              </el-col>
              <el-col :span="6" :offset="2">
                <el-button @click="skipPassword">密码登录</el-button>
              </el-col>
              <!--      <el-button @click="resetForm('ruleForm')">使用密码登录</el-button>-->
            </el-form-item>
          </el-form>
        </el-row>
      </article>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import {send_email} from "@/network/login";
import {Message} from "element-ui";

export default {
  name: 'LoginEmailNew',
  data() {
    return {
      ruleForm: {
        vercode_user: '',
        email: '',
        vercode:''
      },
      getCode: '获取验证码',
      isGetting: false,
      count: 60,
      disable: false,
      btnLoading: false,
      sendCodeLoading: false,
      timer:null,
      rules: {
        vercode_user: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        email: [
          { required: true, message: "邮箱地址不能为空", trigger: "blur" },
          {
            type: "email",
            message: "'请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
      }
    };
  },
  created() {
    // if(typeof Cookies.get("vercode")!=='undefined'){
    //   this.isGeting = true
    //   this.disable = true
    //   this.getCode = Cookies.get("test") + 's后重发'
    // }
    //console.log(Cookies.get("test"));
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  methods: {
    closeBox() {
      this.$emit("closeBox");
    },
    handleLogin(){
      this.btnLoading = true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          this.$store.dispatch("Login_email", this.ruleForm).then((flag) => {
            if(flag===1){
              this.$router.push({ path: "/home" }).catch(()=>{});
            }
            else{
              //console.log(flag);
            }
            this.btnLoading = false;
          }).catch((e) => {
            console.log(e);
          });
        } else {
          this.btnLoading = false;
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    skipPassword(){
      this.$emit("closeBox");
      this.$emit("LoginPsd");
    },
    toRegister() {
      this.$emit('closeBox');
      this.$emit('Register');
    },
    sendCode(){
      this.$refs.ruleForm.validateField('email', check=>{
        if(check===''){
          this.ruleForm.vercode=Math.random().toFixed(6).slice(-6);
          Cookies.set("vercode", this.ruleForm.vercode, { expires: 60000/864e5 });
          Cookies.set("test", 60, { expires: 60000/864e5 });
          send_email(this.ruleForm.email,this.ruleForm.vercode).then(res=>{
            //增加发送成功与否提示信息
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '发送成功!'
              });
            }
            else if (res.flag === 0) {
              this.$message({
                type: 'warning',
                message: '发送失败'
              });
            }
            console.log(res);
          })
          let _this=this;
          this.timer = setInterval(() => {
            // if (this.count < 1) {
            //   this.isGeting = false
            //   this.disable = false
            //   this.getCode = '获取验证码'
            //   this.count = 6
            //   clearInterval(countDown)
            // } else {
            //   this.isGeting = true
            //   this.disable = true
            //   this.getCode = this.count-- + 's后重发'
            //   console.log(Cookies.get("vercode"));
            // }
            if (Cookies.get("test") < 1) {
              _this.isGetting = false
              _this.disable = false
              _this.getCode = '获取验证码'
              Cookies.set("test",6)
              clearInterval(this.timer)
            } else {
              _this.isGetting = true
              _this.disable = true
              _this.getCode = Cookies.get("test") + 's后重发'
              Cookies.set("test",Cookies.get("test")-1)
            }
          }, 1000)
        }
      })

    }
  }
}
</script>

<style>
.motaichuang{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.5);
}
.kuang{
  width: 30vw; /* box 宽度 */
  height: 60vh;  /* box 高度 */
  background-color: rgba(255,255,255,1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 1vw;
  padding: 1em;
  box-sizing: border-box;
}

.kuang > header{
  width: 100%;
  height: 10%;
  font-size: 1.3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.kuang > header > i{
  font-size: 1.3em;
  cursor: pointer;
  color: #a3a3a3;
  transition: all 0.5s;
}
.kuang > header > i:hover{
  color: #333;
  transition: all 0.5s;
}

.kuang > article{
  width: 100%;
  height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 0.5em;
  display: flex;
  flex-direction: column;
}

.kuang > article::-webkit-scrollbar{
  width: 0.5em;
}
.kuang > article::-webkit-scrollbar-thumb{
  border-radius: 1em;
  background-color: rgba(144,147,153,.3);
}
.kuang > article::-webkit-scrollbar-thumb:hover{
  background-color: rgba(144,147,153,.6);
}

.bizhiBox{
  width: 100%;
  height: 100%;
  margin-top: 1em;
}

.bizhiBox > p{
  box-sizing: border-box;
  padding: 0.5em 1em;
  font-size: 1.1em;
  /*position: absolute;*/
  /*left: 10px;*/
  /*top: 0;*/
}

.bizhi{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content:flex-start
}

.bizhi > img{
  width: 30%;
  /*height: 35%;*/
  margin-bottom: 1em;
  cursor: pointer;
  border-radius: 0.5em;
  transition: all 0.5s;
}
.bizhi > img.active{
  transform: scale(1.1);
  border: 2px solid #409eff;
  transition: all 0.5s;
}

.kuang > footer{
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kuang > footer > button{
  height: 80%;
  width: 15%;
  outline: none;
  border: none;
  background-color: #409eff;
  color: #fff;
  border-radius: 2em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.5s;
}
.kuang > footer > button:hover{
  background-color: #66b1ff;
  transition: all 0.5s;
}
.kuang > footer > button:active{
  background-color: #3a8ee6;
  transition: all 0.5s;
}
</style>
