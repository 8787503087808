<template>
  <div>
    <el-input placeholder="点击回车即为搜索" v-model="keywords" @keyup.enter.native="handleSearch"></el-input>
    <el-table
        :data="tableData_page"
        @row-click="rowClicked"
        @selection-change="handleSelectionChange"
        style="width: 100%"
        border
        class="clickable-rows">
      <!--        :default-sort="{prop: 'create_date', order: 'descending'}">-->
      <el-table-column
          v-if="selectingMultiple"
          type="selection"
          width="50">
        <!--如点击了批量操作，勾选框会显示/隐藏-->
      </el-table-column>
      <el-table-column
          prop="file_name"
          label="新闻名称"
          width=700>
      </el-table-column>
      <el-table-column
          prop="create_date"
          label="发布时间"
          width=400>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5,10,15]"
        :page-size="pagesize"
        layout="total,jumper,prev, pager, next,sizes"
        :total="files_count">
    </el-pagination>
  </div>
</template>

<script>
import {get_user_file2, get_user_files2, remove_or_clone_user_files2} from "@/network/home";

export default {
  name: "ComprehensiveNews",
  data() {
    return {
      tableDataAll:[
        {
          "create_date": "加载中",
          "fid": "加载中",
          "file_name": "加载中"
        }
      ],  //全局数据， 备份
      tableData: [
        {
          "create_date": "加载中",
          "fid": "加载中",
          "file_name": "加载中"
        }
      ], // 搜索出的数据，动态变化
      tableData_page: [
        {
          "create_date": "加载中",
          "fid": "加载中",
          "file_name": "加载中"
        }
      ], //每页的数据
      selectingMultiple: false,
      selected: [],
      activeIndex: '/home/comprehensive_news',
      currentPage:1,
      pagesize:10,
      files_count:0,
      keywords: '',
    }
  },
  methods: {
    handleSearch() {
      this.tableData = this.tableDataAll.filter((p)=>{
        return p.name.indexOf(this.keywords) !== -1;
      })
      this.hadleGetFilesListApi();//刷新页面
    },
    //分页 初始页currentPage、初始每页数据数pagesize和数据testpage--->控制每页几条
    handleSizeChange:function(size){
      this.pagesize = size;
      this.hadleGetFilesListApi();
      console.log(this.pagesize);
    },
    // 控制页面的切换
    handleCurrentChange: function(currentPage) {
      this.currentPage = currentPage;
      this.hadleGetFilesListApi();
    },
    //对所有数据进行分页处理 发送请求
    hadleGetFilesListApi() {
      this.tableData_page = this.tableData.slice((this.currentPage-1)*this.pagesize, this.currentPage*this.pagesize);
      this.files_count = this.tableData.length;
    },
    rowClicked(row) {
      // 点击某行对应的文件后打开该文件
      get_user_file2(this.$store.getters.email, row.fid).then((res) => {
        console.log("row.fid",row.fid);
        if (res !== undefined) {
          const fileName = row.file_name;
          this.$store.commit("ADD_doc", res);
          this.$store.commit("SET_file_PERMISSION","doc");
          this.$store.commit("SET_file_NAME",fileName);
          // this.$store.commit("SET_OPEN_STATE",'exist');
          //跳转到文档
          this.$router.push('/myDoc/' + row.fid.toString());
        }
      }).catch((error) => {
        console.log(error);
      })

    },
    selectMultipleClicked() {
      //显示/隐藏 批量操作勾选框
      this.selectingMultiple = !this.selectingMultiple;
    },
    handleSelectionChange(selection) {
      this.selected = selection;
    },
  },
  mounted() {
    if (localStorage.getItem('reloaded')) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem('reloaded');
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem('reloaded', '1');
      location.reload();
    }
  },
  created() {
    const payload = this.$store.getters.email;
    get_user_files2(payload).then((res) => {//获取所有用户创建的文件
      if (res !== undefined && res.files !== undefined) {
        this.tableDataAll = res.files;
        this.tableData = res.files;//后端数据查询时以控制有序
        this.hadleGetFilesListApi();
      }
      else {
        this.tableDataAll = [];
        this.tableData = [];
        this.hadleGetFilesListApi();
      }
    }).catch((error) => {
      console.log(error);
    })
  }
}
</script>

<style scoped>
.clickable-rows {
  cursor: default;
}
.word_button {
  height: 30px;
  width: 90px;
  border: 0;
  background: transparent;
  margin: 10px;
  cursor: pointer;
  color: #003B73;
}
.word_button:hover {
  color: #0074B7;
}
</style>