<template>
  <div style="text-align: center">
    <div class = "search">
      <el-input placeholder="搜索院所" v-model="keywords" @keyup.enter.native="handleSearch" clearable 
        @clear="handleSearch" suffix-icon="el-icon-search"></el-input>
    </div>
    <el-table :data="tableData_page" @row-click="rowClicked" v-loading="loading"
      @selection-change="handleSelectionChange" class="clickable-rows">
      <el-table-column v-if="selectingMultiple" type="selection" width="50"></el-table-column>
      <el-table-column prop="dp_name" label="院所名称"></el-table-column>
      <el-table-column prop="link" label="官网网址" width=300 fixed="right"></el-table-column>
      <el-table-column id="collectCol" prop="link" label="收藏" width=70 fixed="right">
        <template slot-scope="scope">
          <el-button id="collectBtn" class="el-icon-star-off" v-if="scope.row.isCollect===0"
            @click.stop="handleCollect(scope.$index, scope.row)"/>
          <el-button id="collectBtn" class="el-icon-star-on" v-else @click.stop="handleCollect(scope.$index, scope.row)"/>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5,10,15]"
        :page-size="pagesize"
        layout="total,jumper,prev, pager, next,sizes"
        :total="files_count">
    </el-pagination>
  </div>
</template>

<script>
import {get_departments, insert_fav_department, remove_fav_department} from "@/network/home";

export default {
  name: "training_units",
  data() {
    return {
      tableDataAll: [
        {
          "fid": "加载中",
          "dp_name": "加载中",
          "link": "加载中"
        }
      ],
      tableData: [
        {
          "fid": "加载中",
          "dp_name": "加载中",
          "link": "加载中"
        }
      ],
      tableData_page: [
        {
          "fid": "加载中",
          "dp_name": "加载中",
          "link": "加载中"
        }
      ],
      selectingMultiple: false,
      selected: [],
      activeIndex: '/home/comprehensive_news',
      currentPage:1,
      pagesize:10,
      files_count:0,
      keywords: '',
      loading: true,
    }
  },
  methods: {
    handleSearch() {
      this.tableData = this.tableDataAll.filter((p)=>{
        return p.dp_name.indexOf(this.keywords) !== -1;
      })
      this.hadleGetFilesListApi();//刷新页面
    },
    //分页 初始页currentPage、初始每页数据数pagesize和数据testpage--->控制每页几条
    handleSizeChange:function(size){
      this.pagesize = size;
      this.hadleGetFilesListApi();
      console.log(this.pagesize);
    },
    // 控制页面的切换
    handleCurrentChange: function(currentPage) {
      this.currentPage = currentPage;
      this.hadleGetFilesListApi();
    },
    //对所有数据进行分页处理 发送请求
    hadleGetFilesListApi() {  //刷新页面
      this.tableData_page = this.tableData.slice((this.currentPage-1)*this.pagesize, this.currentPage*this.pagesize);
      this.files_count = this.tableData.length;
    },
    rowClicked(row) {
      // 点击某行对应的文件后打开该文件
      if (!event.target.closest('button')) {
        window.open(row.link, '_blank')  //打开新页面跳转
      }
    },
    handleCollect(index, row) {
      if (row.isCollect === 0) {
        insert_fav_department(this.$store.getters.email, row.fid).then((res) => {
          if (res.flag === 1) {
            row.isCollect = 1;
            this.$message({
              type: 'success',
              message: '已收藏'
            });
          }
          else {
            this.$message({
              type: 'warning',
              message: '收藏失败，请稍后重试'
            });
          }
        })
      }
      else {
        remove_fav_department(this.$store.getters.email, row.fid).then((res) => {
          if (res.flag === 1) {
            row.isCollect = 0;
            this.$message({
              type: 'success',
              message: '已取消收藏'
            });
          }
          else {
            this.$message({
              type: 'warning',
              message: '取消收藏失败，请稍后重试'
            });
          }
        })
      }
    },
    selectMultipleClicked() {
      //显示/隐藏 批量操作勾选框
      this.selectingMultiple = !this.selectingMultiple;
    },
    handleSelectionChange(selection) {
      this.selected = selection;
    },
  },
  // mounted() {
  //   if (localStorage.getItem('reloaded')) {
  //     // The page was just reloaded. Clear the value from local storage
  //     // so that it will reload the next time this page is visited.
  //     localStorage.removeItem('reloaded');
  //   } else {
  //     // Set a flag so that we know not to reload the page twice.
  //     localStorage.setItem('reloaded', '1');
  //     location.reload();
  //   }
  // },
  beforeCreate() {
    get_departments(this.$store.getters.email).then((res) => {
      this.tableDataAll = res.files;
      this.loading = false;
    })
  },
  watch: {
    tableDataAll: {
      handler: function() {
        this.tableData = this.tableDataAll;
        this.tableData_page = this.tableData.slice((this.currentPage-1)*this.pagesize, this.currentPage*this.pagesize);
        this.files_count = this.tableData.length;
      },
      deep: true,
      immediate: true
    }
  },
}
</script>

<style scoped>
.clickable-rows {
  width: 100%;
  height: 80%;
  margin-top: 20px;
  cursor: default;
  border-style: solid;
  border-color: #09073c;
}

.el-table {
  border: 0;
}

.el-pagination {
  margin: 20px;
}

#collectBtn {
  border: 0px;
  background-color: transparent;
  color: #7a557b;
  font-size: 17px;
}

#collectBtn:hover {
  background-color: transparent;
  color: #d6c5db;
}

.search ::v-deep .el-input__inner{
  height:40px;
  border-radius: 40px;
  width: 300px;
  float:right;
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
}
</style>