<template>
  <div class="container">
    <el-container class="home">
      <el-header class="app_header">
        <el-button class="mainButton homeBtn" type="text" @click="$router.push('/home')">DAWN</el-button>
        <el-button class="mainButton backBtn" icon="el-icon-back" @click="goBack">返回&nbsp | &nbsp&nbsp&nbsp消息通知</el-button>
      </el-header>
      <el-main class="belowTop">
        <div id="pageName">
          {{ this.title }}
        </div>
        <div class="boxes">
          <!-- <div class="box menu">
            <el-menu router :default-active="activeIndex"
                     class="el-menu-vertical-demo"
                     @select="handleSelect"
                     active-text-color="#60A3D9">
              <el-menu-item index="/profile/my_info">
                <span slot="title">个人中心</span>
              </el-menu-item>
              <el-menu-item index="/notification">
                <span slot="title">系统通知</span>
              </el-menu-item>
            </el-menu>
          </div> -->
          <div class="box notices" v-loading="loading">
            <el-card v-for="item in this.notices" :key="item.cardId">
              <el-badge is-dot v-if="item.isRead === 0" class="redDot"/>
              <div class="cardDiv postTime">{{ item.create_date }}</div>
              <div class="cardDiv postTitle">{{ item.file_name }}</div>
              <div class="cardDiv postContent">{{ item.content }}</div>
            </el-card>
            <div class="noInfo" v-if="this.notices.length === 0">暂无消息</div>
          </div>
        </div>
      </el-main>

    </el-container>
  </div>
</template>

<script>
import {get_notifications, mark_notifications_read} from "@/network/notification";
import vmson from "@/utils/vmson";

export default {
  name: "Notification.vue",
  data() {
    return {
      activeIndex: this.$route.path,
      pageName: "AIFOR - 通知",
      notices: [],
      items: [
        // { type: '', label: '消息一\n内容' },
        // { type: 'success', label: '标签二' },
        // { type: 'info', label: '标签三' },
        // { type: 'danger', label: '标签四' },
        // { type: 'warning', label: '标签五' },
        { type: 'info', label: '暂无消息' },
      ],
      title: "消息通知",
      loading: true,
    }
  },
  methods: {
    handleSelect(key) {
      if (this.activeIndex !== key) {
        this.activeIndex = key;
        this.$router.push(key);
      }
      if (key === "/notification") {
        this.title = "系统通知"
      }
    },
    goBack(){
      this.$router.back();
      this.$store.commit("SET_OPEN_STATE",'exist');
      console.log('go back');
    },
  },
  created() {
    const email = this.$store.getters.email;
    get_notifications(email).then((res) => {
      if (res !== undefined && res.data !== undefined) {
        this.notices = res.data;
      }
      else {
        this.notices = []
      }
    }).then(() =>{
      this.loading = false;
      let noticeIds = this.notices.map(notice => notice.noId);
      mark_notifications_read(email, noticeIds);//请求标记为已读
    }).catch((error) => {
      console.log(error);
    })
  }
}
</script>

<style scoped>
.el-container {
  margin: 0px;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.el-header {
  position: fixed;
  top: 0;
  left:0;
  width:100%;
  justify-content: center;
  align-items: center;
  background-color: #7a557b;
  z-index: 1000;
  overflow: hidden;
}

.app_header .el-button {
  color: white;
  margin: 10px;
}

.el-main {
  background-color: #f3e6f6;
  color: #333;
  text-align: center;
  flex: 1;
  overflow: hidden;
}

.belowTop {
  margin-top: 60px;
  height: calc(100vh - 60px);
  width: 100%;
  flex: 1;
}

.mainButton {
  position: absolute;
  top: 0;
  margin-top: 0;
}

.homeBtn {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 30px;
  margin-left: 30px;
  padding: 0;
  top: 3px;
  left: 0;
}

.backBtn {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  color: white;
  background-color: transparent;
  margin-top: 20px;
  left: 100px;
  border: none;
  font-size: 15px;
}

#pageName {
  width: 70%;
  margin: 20px auto;
  font-size: 30px;
}

.el-icon-user-solid {
  color: white;
  font-size: 20px;
}

#btnsCol {
  text-align: start;
}

.upperBtns {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  align-items: center;
  border: 1px#7a557b;
  height: 40px;
  color: #7a557b;
  border: 1px solid #7a557b;
  /* margin-top: 60px; */
}

.upperBtns:hover {
  color: #f3e6f6;
  background-color: #7a557b;
}

.boxes {
  /*margin-top: 40px;*/
  /* display: flex;
  flex: 1; */
  /*justify-content: center;*/
  /* gap: 10px; */
  width: 75%;
  margin: 0 auto;
  /* height: 500px; */
}

.box {
  /* flex: 1; */
  border: 1px solid #ccc;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  border-radius: 10px;
  margin: 0 auto;
}

.menu {
  width: 20%;
  background-color: #ffffff;
}

.notices {
  width: 80%;
  background-color: #ffffff;
}

::v-deep .el-tabs__item.is-active {
  color: #7a557b !important;
  background-color: #f3e6f6;
}

::v-deep .el-tabs__item:hover {
  color: #7a557b !important;
}

.noInfo {
  margin: 20px 0;
}

.redDot {
  float: right;
}

.cardDiv {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.el-card {
  text-align: start;
}

.postTitle {
  font-size: 16px;
  margin-top: 10px;
}

.postTime {
  margin: 5px 0;
  font-size: 11px;
}

.postContent {
  margin-top: 20px;
  font-size: 13px;
}

</style>
