<template>
  <div>
    <el-main>
      <router-view></router-view>
    </el-main>
  </div>
</template>
  
<script>
export default {
    name: "Community",
    data() {
    return {
    };
    },
    methods: {
    }
}
</script>

<style scoped>
 .el-main {
  padding: 0;
 }
</style>