const file = {
  state: {
    permission: '', //两种可能 doc, image
    file_name:'',
    file_id: -1,
    current_time:'',
    open_state:'',
    bag_path:'',
    eti_result: [],
    // class_map: {
    //   '0':'VPN-Chat',
    //   '1':'VPN-Email',
    //   '2':'VPN-File Transfer',
    //   '3':'VPN-Streaming',
    //   '4':'VPN -TraP2P',
    //   '5':'VPN-VoIP',
    //   '6':'VoIP',
    //   '7':'File Transfer',
    //   '8':'Streaming',
    //   '9':'Chat',
    //   '10':'Email',
    // },
    // class_map_c: {
    //   '0':'VPN-聊天',
    //   '1':'VPN-邮件',
    //   '2':'VPN-文件传输',
    //   '3':'VPN-视频流',
    //   '4':'VPN -下载',
    //   '5':'VPN-语音',
    //   '6':'语音',
    //   '7':'文件传输',
    //   '8':'视频流',
    //   '9':'聊天',
    //   '10':'邮件',
    // },
    // class_map_c: []
  },

  mutations: {
    SET_file_ID: (state, mindmap_id) => {
      state.file_id = mindmap_id
    },
    SET_file_PERMISSION: (state, permission) => {
      state.permission = permission
    },
    SET_file_NAME:(state, name) => {
      state.file_name=name
    },
    SET_CURRENT_TIME:(state, current) =>{
      state.current_time=current
    },
    SET_OPEN_STATE:(state, open_state) => {
      state.open_state=open_state
    },
    SET_BAG_PATH:(state, bag_path) => {
      state.bag_path = bag_path
    },
    SET_ETI_RESULT:(state, eti_result) => {
      state.eti_result = eti_result
    },
    // SET_CLASS_MAP_C:(state) => {
    //   state.class_map_c = [
    //     'VPN-聊天',
    //       'VPN-邮件',
    //       'VPN-文件传输',
    //       'VPN-视频流',
    //       'VPN -下载',
    //       'VPN-语音',
    //       '语音',
    //       '文件传输',
    //       '视频流',
    //       '聊天',
    //       '邮件',
    //   ]
    // }
  },

  actions: {

  }
}

export default file
