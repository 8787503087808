<template>
  <div>
    <el-container>
      <el-main>
        <h1 style="font-size: 24px;">更换邮箱</h1>
        <div class="box" style="background: white">
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item class="items" label="新邮箱" prop="newEmail">
              <el-input v-model="ruleForm.newEmail" placeholder="新邮箱号"
                        style="width:300px" clearable>
              </el-input>
            </el-form-item>
            <el-form-item class="items" label="密码" prop="password">
              <el-input type="password" v-model="ruleForm.password" style="width:300px" placeholder="新邮箱密码" clearable></el-input>
            </el-form-item>
            <el-form-item class="items" label-width="0px" style="width:400px">
            </el-form-item>
          </el-form>
        </div>
        <el-button @click="$router.back()">返回</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>
<!--        <el-button @click="$router.push('/profile/reset_password')">修改密码</el-button>-->
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import {request_reset_email} from "@/network/profile";

export default {
  name: "ResetEmail.vue",
  data() {
    return {
      ruleForm: {
        newEmail: '',
        password: '',
      },
      rules: {
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        newEmail: [
          { required: true, message: "邮箱地址不能为空", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
      }
    }
  },
  methods: {
    resetEmail(newEmail, password) {
      request_reset_email(this.$store.getters.email, newEmail,password).then((res) => {
        if (res.flag === 1) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
        }
        else if (res.flag === 2) {
          this.$message({
            type: 'warning',
            message: '修改失败，邮箱已被使用过，请重试'
          });
        }
        else if (res.flag === 3) {
          this.$message({
            type: 'warning',
            message: '修改失败，密码错误，请重试'
          });
        }
        else if (res.flag === 0) {
          this.$message({
            type: 'warning',
            message: '修改失败，请稍后再试'
          });
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否确认修改邮箱为：' +
              this.ruleForm.newEmail, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.resetEmail(this.ruleForm.newEmail, this.ruleForm.password);
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消更改'
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm.newEmail = '';
    }
  },
  created() {
  },
}
</script>

<style scoped>
.el-form {
  margin-top: 60px;
}

.el-form-item {
  margin: 20px auto;
  width: 500px;
}

.el-row {
  padding: 10px;
  margin-bottom: 20px;
}

.el-col {
  padding-left: 10px;
}

.el-main {
  width: 100%;
}

.info {
  width: 100px;
  height: 50px;
  margin-left: 50px;
}
.el-input{

  right: 50px;
}
.box {
  width: 100%;
  text-align: center;
}

.el-button {
  margin: 20px;
  width: 80px;
}
</style>