<template>
  <div class="container">
    <el-container class="home">
      <el-header class="app_header">
        <el-button id="mainButton" type="text" @click="$router.push('/home')">DAWN</el-button>
        <div class="topBarButtons">
          <el-button type="text" @click="$router.push('/home/headline_news')">头条新闻</el-button>
          <el-button type="text" @click="$router.push('/community/home')">经验交流</el-button>
          <el-button type="text" @click="$router.push('/file/home')">文件共享</el-button>
          <el-button type="text" @click="$router.push('/ai/home')">智能AI</el-button>
          <el-button type="text" @click="$router.push('/course/home')">自我提升</el-button>
          <el-button type="text" @click="$router.push('/intelligence')">智能教研</el-button>
          <el-button type="text" @click="$router.push('/contact_us')">联系我们</el-button>

          <el-badge is-dot v-if="hvNotice" class="app_notification">
            <el-dropdown @command="handleClickNotification">
              <span class="el-dropdown-link"><i class="el-icon-bell"></i></span>
              <el-dropdown-menu slot="dropdown" class = "app_window">
                <el-dropdown-item command="system">系统通知</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-badge>
          <el-dropdown v-else class="app_notification" @command="handleClickNotification">
            <span class="el-dropdown-link"><i class="el-icon-bell"></i></span>
            <el-dropdown-menu slot="dropdown" class = "app_window">
              <el-dropdown-item command="system">系统通知</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown class = "app_dropdown" @command="handleClickDropdown">
            <span class="el-dropdown-link"><i class="el-icon-user-solid"></i></span>
            <el-dropdown-menu slot="dropdown" class = "app_window">
              <el-dropdown-item command="profile">个人中心</el-dropdown-item>
              <el-dropdown-item command="resetPsd">修改密码</el-dropdown-item>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main class="belowTop">
        <div class="searchRow">
          <el-row type="flex" justify="start">
            <el-col id="btnsCol">
              <el-button class="upperBtns" icon="el-icon-edit" @click="$router.push('/community/create_post')">发布帖子</el-button>
              <el-button class="upperBtns" icon="el-icon-files" @click="handleMyPostsClick">我的帖子</el-button>
            </el-col>
            <el-col>
              <div class="search">
                <el-input placeholder="搜索" v-model="keywords" clearable @clear="handleClear" 
                  @keyup.enter.native="handleSearch" suffix-icon="el-icon-search"></el-input>
              </div>
            </el-col>
          </el-row>
        </div> 
        <div class="boxes">
          <div class="box news">
          <el-table :data="headlineNews" @row-click="newsRowClicked" class="clickable-rows" v-loading="newsLoading">
            <el-table-column prop="file_name" label="头条新闻" fixed="left"></el-table-column>
          </el-table>
          </div>
          <div class="box posts">
            <el-tabs type="border-card" v-model="activeName" v-loading="postsLoading">
              <el-tab-pane label="全部" name="all">
                <div>
                  <el-card v-for="item in allPosts" :key="item.cardId" @click.native="handlePostClick(item)">
                    <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete" @click="handleDelete(item)"/>
                    <el-button v-else class="postBtn followBtn" @click="toggleFollow(item)">
                      {{ item.followedText }}
                    </el-button>
                    <div class="cardDiv nickname">{{ item.nickname }}</div>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                    <div class="comment"><i class="el-icon-chat-line-square"></i>评论 {{ item.commentNumber }}</div>
                    <div v-if="item.isMyPost" class="infoRight postInfo"><i class="el-icon-chat-line-thumb"></i>赞 {{ item.likeNumber }}</div>
                    <el-button v-else class="postBtn likeBtn" icon="el-icon-thumb" @click="toggleLike(item)">{{ item.likeText }} {{ item.likeNumber }}</el-button>
                    <div v-if="item.isMyPost" class="infoRight postInfo"><i class="el-icon-chat-line-star-off"></i>收藏 {{ item.collectNumber }}</div>
                    <el-button v-else class="postBtn collectBtn" icon="el-icon-star-off" @click="toggleCollect(item)">{{ item.collectText }} {{ item.collectNumber }}</el-button>
                  </el-card>
                </div>
              </el-tab-pane>
              <el-tab-pane label="热门" name="hot">
                <div>
                  <el-card v-for="item in hotPosts" :key="item.cardId" @click.native="handlePostClick(item)">
                    <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete" @click="handleDelete(item)"/>
                    <el-button v-else class="postBtn followBtn" @click="toggleFollow(item)">
                      {{ item.followedText }}
                    </el-button>
                    <div class="cardDiv nickname">{{ item.nickname }}</div>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                    <div class="comment"><i class="el-icon-chat-line-square"></i>评论 {{ item.commentNumber }}</div>
                    <div v-if="item.isMyPost" class="infoRight postInfo"><i class="el-icon-chat-line-thumb"></i>赞 {{ item.likeNumber }}</div>
                    <el-button v-else class="postBtn likeBtn" icon="el-icon-thumb" @click="toggleLike(item)">{{ item.likeText }} {{ item.likeNumber }}</el-button>
                    <div v-if="item.isMyPost" class="infoRight postInfo"><i class="el-icon-chat-line-star-off"></i>收藏 {{ item.collectNumber }}</div>
                    <el-button v-else class="postBtn collectBtn" icon="el-icon-star-off" @click="toggleCollect(item)">{{ item.collectText }} {{ item.collectNumber }}</el-button>
                  </el-card>
                </div>
              </el-tab-pane>
              <el-tab-pane label="推荐" name="recommended">
                <div>
                  <el-card v-for="item in suggestedPosts" :key="item.cardId" @click.native="handlePostClick(item)">
                    <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete" @click="handleDelete(item)"/>
                    <el-button v-else class="postBtn followBtn" @click="toggleFollow(item)">
                      {{ item.followedText }}
                    </el-button>
                    <div class="cardDiv nickname">{{ item.nickname }}</div>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                    <div class="comment"><i class="el-icon-chat-line-square"></i>评论 {{ item.commentNumber }}</div>
                    <div v-if="item.isMyPost" class="infoRight postInfo"><i class="el-icon-chat-line-thumb"></i>赞 {{ item.likeNumber }}</div>
                    <el-button v-else class="postBtn likeBtn" icon="el-icon-thumb" @click="toggleLike(item)">{{ item.likeText }} {{ item.likeNumber }}</el-button>
                    <div v-if="item.isMyPost" class="infoRight postInfo"><i class="el-icon-chat-line-star-off"></i>收藏 {{ item.collectNumber }}</div>
                    <el-button v-else class="postBtn collectBtn" icon="el-icon-star-off" @click="toggleCollect(item)">{{ item.collectText }} {{ item.collectNumber }}</el-button>
                  </el-card>
                </div>
              </el-tab-pane>
              <el-tab-pane label="关注" name="following">
                <div>
                  <el-card v-for="item in followedPosts" :key="item.cardId" @click.native="handlePostClick(item)">
                    <el-button class="postBtn followBtn" @click="toggleFollow(item)">
                      {{ item.followedText }}
                    </el-button>
                    <div class="cardDiv nickname">{{ item.nickname }}</div>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                    <div class="comment"><i class="el-icon-chat-line-square"></i>评论 {{ item.commentNumber }}</div>
                    <el-button class="postBtn likeBtn" icon="el-icon-thumb" @click="toggleLike(item)">{{ item.likeText }} {{ item.likeNumber }}</el-button>
                    <el-button class="postBtn collectBtn" icon="el-icon-star-off" @click="toggleCollect(item)">{{ item.collectText }} {{ item.collectNumber }}</el-button>
                  </el-card>
                </div>
              </el-tab-pane>
              <el-tab-pane label="收藏" name="collected">
                <div>
                  <el-card v-for="item in collectedPosts" :key="item.cardId" @click.native="handlePostClick(item)">
                    <el-button class="postBtn followBtn" @click="toggleFollow(item)">
                      {{ item.followedText }}
                    </el-button>
                    <div class="cardDiv nickname">{{ item.nickname }}</div>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                    <div class="comment"><i class="el-icon-chat-line-square"></i>评论 {{ item.commentNumber }}</div>
                    <el-button class="postBtn likeBtn" icon="el-icon-thumb" @click="toggleLike(item)">{{ item.likeText }} {{ item.likeNumber }}</el-button>
                    <el-button class="postBtn collectBtn" icon="el-icon-star-off" @click="toggleCollect(item)">{{ item.collectText }} {{ item.collectNumber }}</el-button>
                  </el-card>
                </div>
              </el-tab-pane>
              <el-tab-pane label="赞过" name="liked">
                <div>
                  <el-card v-for="item in likedPosts" :key="item.cardId" @click.native="handlePostClick(item)">
                    <el-button class="postBtn followBtn" @click="toggleFollow(item)">
                      {{ item.followedText }}
                    </el-button>
                    <div class="cardDiv nickname">{{ item.nickname }}</div>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                    <div class="comment"><i class="el-icon-chat-line-square"></i>评论 {{ item.commentNumber }}</div>
                    <el-button class="postBtn likeBtn" icon="el-icon-thumb" @click="toggleLike(item)">{{ item.likeText }} {{ item.likeNumber }}</el-button>
                    <el-button class="postBtn collectBtn" icon="el-icon-star-off" @click="toggleCollect(item)">{{ item.collectText }} {{ item.collectNumber }}</el-button>
                  </el-card>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="box ads" v-loading="adsLoading">
            <div>
              <!-- <img :src="imga" alt="Base64 Image" class="adsImg"> -->
              <img v-for="(image, index) in ads" :key="index" :src="image" alt="Base64 Image" class="adsImg">
            </div>
          </div>
        </div>
      </el-main>
    
    </el-container>
  </div>
</template>   
<script>
  import {get_headline_news} from "@/network/home";
  import {get_notifications} from "@/network/notification";
  import {get_posts, get_followed_posts, get_hot_posts, get_suggest_posts, get_all_collected_posts, get_all_liked_posts, collect_post, uncollect_post, like_post
    , unlike_post, follow_user, unfollow_user, delete_post, get_ads} from "@/network/community";
  export default {
    name: "CommunityHome",
    data() {
      return {
        email: '',
        keywords: '',
        activeName: 'all',
        hotPosts: [],
        suggestedPosts: [],
        followedPosts: [],
        allPosts: [],
        likedPosts: [],
        collectedPosts: [],
        hotPostsAll: [],
        suggestedPostsAll: [],
        followedPostsAll: [],
        allPostsAll: [],
        likedPostsAll: [],
        collectedPostsAll: [],
        newsLoading: true,
        postsLoading: true,
        adsLoading: true,
        ads: [],
        hvNotice: false,
        headlineNews: [
          {
            "newsType": '',
            "fid": 0,
            "infoType": 0,
            "dpName": 0,
            "file_name": "加载中",
            "link": "https://www.xsaifor.com/"
          }
        ],
      };
    },
    methods: {
      handleClear() {
        this.resetDisplayPosts();
        // this.headlineNews = this.headlineNewsAll;
        // this.hadleGetFilesListApi();//刷新页面
      },
      handleSearch() {
        this.hotPosts = this.hotPostsAll.filter((p)=>{
          return p.file_name.indexOf(this.keywords) !== -1;
        })
        this.suggestedPosts = this.suggestedPostsAll.filter((p)=>{
          return p.file_name.indexOf(this.keywords) !== -1;
        })
        this.followedPosts = this.followedPostsAll.filter((p)=>{
          return p.file_name.indexOf(this.keywords) !== -1;
        })
        this.allPosts = this.allPostsAll.filter((p)=>{
          return p.file_name.indexOf(this.keywords) !== -1;
        })
        this.likedPosts = this.likedPostsAll.filter((p)=>{
          return p.file_name.indexOf(this.keywords) !== -1;
        })
        this.collectedPosts = this.collectedPostsAll.filter((p)=>{
          return p.file_name.indexOf(this.keywords) !== -1;
        })
      },
      resetDisplayPosts() {
        this.hotPosts = this.hotPostsAll;
        this.suggestedPosts = this.suggestedPostsAll;
        this.followedPosts = this.followedPostsAll;
        this.allPosts = this.allPostsAll;
        this.likedPosts = this.likedPostsAll;
        this.collectedPosts = this.collectedPostsAll;
      },
      newsRowClicked(row) {  //跳转到原文
        //location.href=row.link // 直接跳转
        window.open(row.link, '_blank')  //打开新页面跳转
      },
      handleClickNotification(command) {
        if (command === "system") {
          this.$router.push('/notification/');
        }
      },
      handleClickDropdown(command) {
        if (command === "logout") {
          this.$store.dispatch('LogOut').then(() => {
            this.$router.push('/login/');
          })
        }
        else if (command === "profile") {
          this.$router.push('/profile/');
        }
        else if (command === "resetPsd") {
          this.$router.push('/reset_password/');
        }
      },
      handleDelete(item) {
        event.stopPropagation();
        this.$confirm('是否要删除帖子', '系统提示', {
          confirmButtonText: '确认',
          cancelButtonText: '返回',
          type: 'info'
        }).then(() => {
          delete_post(this.email, item.cardId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已删除帖子'
              });
              this.fetchPosts();
            }
            else {
              this.$message({
                type: 'warning',
                message: '删除帖子失败，请稍后重试'
              });
            }
          })
        }).catch(() => {});
        document.activeElement.blur();
      },
      tabChange() {
        if (this.keywords !== '') {
          this.fetchPosts();
          this.keywords = '';
        }
      },
      toggleFollow(item) {
        event.stopPropagation();
        item.followedText = item.followedText === '已关注'? '+关注' : '已关注';
        if (item.isFollow === 0) {
          item.isFollow = 1;
          follow_user(this.email, item.ownerId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已关注'
              });
              this.fetchPosts();
            }
            else {
              item.isFollow = 0;
              item.followedText = item.followedText === '已关注'? '+关注' : '已关注';
              this.$message({
                type: 'warning',
                message: '关注失败，请稍后重试'
              });
            }
          })
        }
        else {
          item.isFollow = 0;
          unfollow_user(this.$store.getters.email, item.ownerId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已取消关注'
              });
              this.fetchPosts();
            }
            else {
              item.isFollow = 1;
              item.followedText = item.followedText === '已关注'? '+关注' : '已关注';
              this.$message({
                type: 'warning',
                message: '取消关注失败，请稍后重试'
              });
            }
          })
        }
        document.activeElement.blur();
      },
      toggleLike(item) {
        event.stopPropagation();
        item.likeText = (item.isLiked === 1 )? '点赞' : '已赞';
        if (item.isLiked === 0) {
          item.isLiked = 1;
          item.likeNumber ++;
          like_post(this.email, item.cardId, item.ownerId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已点赞'
              });
              this.fetchPosts();
            }
            else {
              item.isLiked = 0;
              item.likeNumber --;
              item.likeText = (item.isLiked === 1 )? '点赞' : '已赞';
              this.$message({
                type: 'warning',
                message: '取消点赞失败，请稍后重试'
              });
            }
          })
        }
        else {
          item.isLiked = 0;
          item.likeNumber --;
          unlike_post(this.email, item.cardId, item.ownerId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已取消点赞'
              });
              this.fetchPosts();
            }
            else {
              item.isLiked = 1;
              item.likeNumber ++;
              item.likeText = (item.isLiked === 1 )? '点赞' : '已赞';
              this.$message({
                type: 'warning',
                message: '取消点赞失败，请稍后重试'
              });
            }
          })
        }
        document.activeElement.blur();
      },
      toggleCollect(item) {
        event.stopPropagation();
        item.collectText = item.collectText === '已收藏'? '收藏' : '已收藏';
        if (item.isCollect === 0) {
          item.isCollect = 1;
          item.collectNumber ++;
          collect_post(this.email, item.cardId, item.ownerId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已收藏'
              });
              this.fetchPosts();
            }
            else {
              item.isCollect = 0;
              item.collectNumber --;
              item.collectText = item.collectText === '已收藏'? '收藏' : '已收藏';
              this.$message({
                type: 'warning',
                message: '收藏失败，请稍后重试'
              });
            }
          })
        }
        else {
          item.isCollect = 0;
          item.collectNumber --;
          uncollect_post(this.email, item.cardId, item.ownerId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已取消收藏'
              });
              this.fetchPosts();
            }
            else {
              item.isCollect = 1;
              item.collectNumber ++;
              item.collectText = item.collectText === '已收藏'? '收藏' : '已收藏';
              this.$message({
                type: 'warning',
                message: '取消收藏失败，请稍后重试'
              });
            }
          })
        }
        // item.isCollect = item.isCollect === 1 ? 0 : 1;
        document.activeElement.blur();
      },
      handlePostClick(item) {
        this.$router.push({
          name: 'post', params: { 
            post: item
          }
        });
      },
      handleMyPostsClick() {
        this.$router.push({
          name: 'posts_view', params: { 
            uid: this.email
          }
        });
      },
      fetchPosts() {
        get_hot_posts(this.email).then((res) => {
          if (res !== undefined && res.data !== undefined) {
            this.hotPosts = res.data
            this.hotPosts.forEach(post => {this.initPost(post)});
            this.hotPostsAll = this.hotPosts;
          }
          else {
            this.hotPosts = [];
          }
        }).catch((error) => {
          console.log(error);
        });
        get_followed_posts(this.email).then((res) => {
          if (res !== undefined && res.data !== undefined) {
            this.followedPosts = res.data
            this.followedPosts.forEach(post => {this.initPost(post)});
            this.followedPostsAll = this.followedPosts;
          }
          else {
            this.followedPosts = [];
          }
        }).catch((error) => {
          console.log(error);
        });
        get_suggest_posts(this.email).then((res) => {
          if (res !== undefined && res.data !== undefined) {
            this.suggestedPosts = res.data
            this.suggestedPosts.forEach(post => {this.initPost(post)});
            this.suggestedPostsAll = this.suggestedPosts;
          }
          else {
            this.suggestedPosts = [];
          }
        }).catch((error) => {
          console.log(error);
        });
        get_posts(this.email).then((res) => {
          if (res !== undefined && res.data !== undefined) {
            this.allPosts = res.data
            this.allPosts.forEach(post => {this.initPost(post)});
            this.allPostsAll = this.allPosts;
          }
          else {
            this.allPosts = [];
          }
          this.postsLoading = false;
        }).catch((error) => {
          console.log(error);
        });
        get_all_collected_posts(this.email).then((res) => {
          if (res !== undefined && res.data !== undefined) {
            this.collectedPosts = res.data
            this.collectedPosts.forEach(post => {this.initPost(post)});
            this.collectedPostsAll = this.collectedPosts;
          }
          else {
            this.collectedPosts = [];
          }
        }).catch((error) => {
          console.log(error);
        });
        get_all_liked_posts(this.email).then((res) => {
          if (res !== undefined && res.data !== undefined) {
            this.likedPosts = res.data
            this.likedPosts.forEach(post => {this.initPost(post)});
            this.likedPostsAll = this.likedPosts;
          }
          else {
            this.likedPosts = [];
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      initPost(post) {
        post['followedText'] = post.isFollow == 0 ? '+关注' : '已关注';
        post['collectText'] = post.isCollect == 0 ? '收藏' : '已收藏';
        post['likeText'] = post.isLiked == 0 ? '点赞' : '已赞';
        post['isMyPost'] = (post.ownerId === this.email);
      }
    },
    created() {
      this.email = this.$store.getters.email;
      get_headline_news(this.email).then((res) => {
        if (res !== undefined && res.files !== undefined) {
          this.headlineNews = res.files;//后端数据查询时以控制有序
        }
        else {
          this.headlineNews = [];
        }
        this.newsLoading = false;
      }).catch((error) => {
        console.log(error);
      });
      get_ads(this.email).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          var str = new TextDecoder().decode(res.data)
          var json = JSON.parse(str)
          json.forEach(ad => {
            let str = ad.replace(/\. +/g, '')
            str = str.replace(/[\r\n]/g, '')
            this.ads.push(str)
          });
        }
        else {
          this.ads = [];
        }
        this.adsLoading = false;
      }).catch((error) => {
        console.log(error);
      });
      get_notifications(this.email).then((res) => {
        let notices = res.data;
        notices.forEach(notice => {
          if (notice.isRead === 0) {
            this.hvNotice = true;
          }
        });
      })
      // console.log(this.ads);
      this.fetchPosts();   
    },
  }
</script>

<style scoped>
  /* .home {
    overflow: hidden;
  } */
  .el-container {
    margin: 0px;
  }
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .search ::v-deep .el-input__inner{
    height:40px;
    border-radius: 40px;
    width: 600px;
    float:right;
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  }
  .search ::v-deep .el-input__inner:focus{
    border-color: #7a557b;
  }
  .searchRow {
    height: 40px;
    /* margin-top: 60px; */
  }
  .el-header {
    position: fixed;
    top: 0;
    left:0;
    width:100%;
    justify-content: center;
    align-items: center;
    background-color: #7a557b;
    z-index: 1000;
    overflow: hidden;
  }

  .app_header .el-button {
    color: white;
    margin: 10px;
  }

  .el-main {
    /* background-color: #f3e6f6; */
    color: #333;
    text-align: center;
    flex: 1;
    overflow: hidden;
  }

  .belowTop {
    margin-top: 60px;
    height: calc(100vh - 60px);
    width: 100%;
    flex: 1;
  }

  #mainButton {
    position: absolute;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    left: 0;
    font-size: 30px;
    margin-left: 30px;
    top: 0;
    margin-top: 0;
  }

  .app_dropdown {
    position: absolute;
    right: 0;
    top: 20px;
    margin-right: 30px;
  }

  .el-dropdown-menu__item:hover {
    background-color: #f3e6f6;
    color: #7a517c;
  }

  .el-icon-user-solid {
    color: white;
    font-size: 20px;
  }

  .app_notification {
    position: absolute;
    right: 50px;
    top: 20px;
    margin-right: 30px;
  }
  
  ::v-deep .el-badge__content.is-dot {
    border: none;
  }

  .el-icon-bell {
    color: white;
    font-size: 20px;
  }

  #btnsCol {
    text-align: start;
  }

  .upperBtns {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    align-items: center;
    border: 1px#7a557b;
    height: 40px;
    color: #7a557b;
    border: 1px solid #7a557b;
    /* margin-top: 60px; */
  }

  .upperBtns:hover {
    color: #f3e6f6;
    background-color: #7a557b;
  }

  .boxes {
    margin-top: 40px;
    display: flex;
    flex: 1;
    gap: 10px;
  }

  .box {
    /* flex: 1; */
    border: 1px solid #ccc;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    border-radius: 10px;
  }

  .news, .ads {
    width: 25%;
  }
  

  .adsImg {
    max-width: 90%;
    margin: 10px;
  }

  .posts {
    width: 50%;
  }

  ::v-deep .el-tabs__item.is-active {
    color: #7a557b !important;
    background-color: #f3e6f6;
  }

  ::v-deep .el-tabs__item:hover {
    color: #7a557b !important;
  }

  /* Posts CSS */
  .cardDiv {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .el-card {
    text-align: start;
  }
  .postBtn {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    align-items: center;
    padding: 5px;
    float: right;
    font-size: small;
    border: 1px#7a557b;
    height: 25px;
    width: 50px;
    margin: 10px 0;
    color: #7a557b;
  }
  .followBtn {
    border: 1px dashed #7a557b;
  }
  .followBtn:hover {
    background-color: #7a557b;
    border: 0;
    color: #f3e6f6;
  }
  .likeBtn {
    width: 80px;
    border: 1px #7a557b;
  }
  .likeBtn:focus {
    background-color: white !important;
    border: 0;
  }
  .likeBtn:hover {
    background-color: #7a557b;
    border: 0;
    color: #f3e6f6;
  }
  .collectBtn {
    width: 80px;
    margin-right: 5px;
    border: 1px #7a557b;
  }
  .collectBtn:hover {
    background-color: #7a557b;
    border: 0;
    color: #f3e6f6;
  }
  .postTitle {
    font-size: 16px;
    margin-top: 10px;
  }
  .nickname {
    font-size: 12px;
  }
  .postTime {
    margin: 5px 0;
    font-size: 11px;
  }
  .comment {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    font-size: small;
    height: 25px;
    margin: 10px 0;
    color: #7a557b;
    align-content: center;
    float: left;
    color: #333;
  }
  .el-icon-chat-line-square {
    padding-right: 5px;
  }
  .postContent {
    margin-top: 20px;
    font-size: 13px;
  }
  .postInfo {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    font-size: small;
    height: 25px;
    margin: 10px 0;
    color: #7a557b;
    align-content: center;
    color: #333;
  }
  .infoRight {
    float: right;
    margin-left: 10px;
  }
</style>