import request from '@/utils/request'

// 获取用户所有通知
export function get_notifications(email) {
    const data = {
        email: email
    }
    return request({
        url: '/notification/get_all_notifications',
        method: 'post',
        data: data
    })
}

// 标记通知为已读
export function mark_notifications_read(email, notices) {
    const data = {
        email: email,
        notices: notices
    }
    return request({
        url: '/notification/update_notifications',
        method: 'post',
        data: data
    })
}