<template>
  <div class="motaichuang">
    <div class="kuang">
      <header>
        <span></span>
        <i @click="closeBox">x</i>
      </header>
      <article ref="box">
        <el-row>
          <el-col :offset="0">
            <h1>
              账号注册
            </h1>
          </el-col>
        </el-row>
        <el-row>
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="邮箱" prop="email" @keyup.enter.native="handleRegister">
              <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input type="password" v-model="ruleForm.password" autocomplete="off" @keyup.enter.native="handleRegister"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="rePassword">
              <el-input type="password" v-model="ruleForm.rePassword" autocomplete="off" @keyup.enter.native="handleRegister"></el-input>
            </el-form-item>
            <el-form-item>
              <el-col :span="6" :offset="2">
                <el-button type="primary" @click.native.prevent="handleRegister" :loading="btnLoading">注册</el-button>
              </el-col>
              <el-col :span="6" :offset="2">
                <el-button @click="skipPassword">登录已有账号</el-button>
              </el-col>
            </el-form-item>
          </el-form>
        </el-row>
      </article>
<!--      <footer>-->
<!--        <button @click="closeBox">确定</button>-->
<!--      </footer>-->
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import {register} from "@/network/login";
import {Message} from "element-ui";

export default {
  name: 'RegisterNew',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.rePassword !== '') {
          this.$refs.ruleForm.validateField('rePassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        password: '',
        rePassword: '',
        email: ''
      },
      rules: {
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        rePassword: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        email: [
          { required: true, message: "邮箱地址不能为空", trigger: "blur" },
          {
            type: "email",
            message: "'请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
      },
      btnLoading: false,
    };
  },
  methods: {
    closeBox() {
      this.$emit("closeBox");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleRegister(){
     this.btnLoading = true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          Cookies.set("email", this.ruleForm.email, { expires: 30 });
          Cookies.set("password", this.ruleForm.password, { expires: 30 });
          register(this.ruleForm).then(res=>{
            console.log(res);
            if(res.flag===2){
              Message({
                message: "注册成功",
                type: 'success',
                duration: 5 * 1000
              })
              this.$router.push({ path: "/" }).catch(()=>{});
            }
            else if(res.flag===0){
              Message({
                message: "邮箱已被注册",
                type: 'error',
                duration: 5 * 1000
              })
            }
            else{
              Message({
                message: "网络异常",
                type: 'error',
                duration: 5 * 1000
              })
            }
            this.btnLoading = false;
          })
        } else {
          this.btnLoading = false;
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    skipPassword(){
      this.$emit("closeBox");
      this.$emit("LoginPsd");
    }
  }
}
</script>

<style>
.motaichuang{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.5);
}
.kuang{
  width: 30vw; /* box 宽度 */
  height: 60vh;  /* box 高度 */
  background-color: rgba(255,255,255,1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 1vw;
  padding: 1em;
  box-sizing: border-box;
}

.kuang > header{
  width: 100%;
  height: 10%;
  font-size: 1.3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.kuang > header > i{
  font-size: 1.3em;
  cursor: pointer;
  color: #a3a3a3;
  transition: all 0.5s;
}
.kuang > header > i:hover{
  color: #333;
  transition: all 0.5s;
}

.kuang > article{
  width: 100%;
  height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 0.5em;
  display: flex;
  flex-direction: column;
}

.kuang > article::-webkit-scrollbar{
  width: 0.5em;
}
.kuang > article::-webkit-scrollbar-thumb{
  border-radius: 1em;
  background-color: rgba(144,147,153,.3);
}
.kuang > article::-webkit-scrollbar-thumb:hover{
  background-color: rgba(144,147,153,.6);
}

.bizhiBox{
  width: 100%;
  height: 100%;
  margin-top: 1em;
}

.bizhiBox > p{
  box-sizing: border-box;
  padding: 0.5em 1em;
  font-size: 1.1em;
  /*position: absolute;*/
  /*left: 10px;*/
  /*top: 0;*/
}

.bizhi{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content:flex-start
}

.bizhi > img{
  width: 30%;
  /*height: 35%;*/
  margin-bottom: 1em;
  cursor: pointer;
  border-radius: 0.5em;
  transition: all 0.5s;
}
.bizhi > img.active{
  transform: scale(1.1);
  border: 2px solid #409eff;
  transition: all 0.5s;
}

.kuang > footer{
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kuang > footer > button{
  height: 80%;
  width: 15%;
  outline: none;
  border: none;
  background-color: #409eff;
  color: #fff;
  border-radius: 2em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.5s;
}
.kuang > footer > button:hover{
  background-color: #66b1ff;
  transition: all 0.5s;
}
.kuang > footer > button:active{
  background-color: #3a8ee6;
  transition: all 0.5s;
}
</style>
