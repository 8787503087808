<template>
  <div class="container">
    <el-container class="home">
      <el-header class="app_header">
        <el-button class="mainButton homeBtn" type="text" @click="$router.push('/home')">DAWN</el-button>
        <el-button class="mainButton backBtn" icon="el-icon-back" @click="goBack">返回&nbsp | &nbsp&nbsp&nbsp我的上传</el-button>
      </el-header>
      <el-main class="belowTop">
        <div class="searchRow">
          <el-row type="flex" justify="start">
            <el-col id="btnsCol">
              <el-button class="upperBtns" icon="el-icon-circle-plus" @click="$router.push('/file/upload_file')">上传</el-button>
            </el-col>
            <el-col>
              <div class="search">
                <el-input placeholder="搜索" v-model="keywords" clearable @clear="handleClear"
                          @keyup.enter.native="handleSearch" suffix-icon="el-icon-search"></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="boxes">
          <div class="box news">
            <el-table :data="headlineNews" @row-click="newsRowClicked" class="clickable-rows" v-loading="newsLoading">
              <el-table-column prop="file_name" label="头条新闻" fixed="left"></el-table-column>
            </el-table>
          </div>
          <div class="box Files">
            <el-tabs type="border-card" v-model="activeName" v-loading="FilesLoading">
              <el-tab-pane label="全部" name="all">
                <div>
                  <el-card v-for="item in myFiles" :key="item.fileId">
                    <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete" @click="handleDelete(item)"/>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                    <el-button v-if="item.isApprove" class="download downloadBtn" icon="el-icon-download" :loading="downloading" @click="toggleDownload(item)">{{ item.downloadText }}  {{ item.downloadNumber }}</el-button>
                    <div v-if="item.isApprove" class="infoRight postInfo"><i class="el-icon-chat-line-thumb"></i>评分 {{ item.score }}</div>
                    <div v-if="item.isApprove" class="infoRight postInfo"><i class="el-icon-chat-line-star-off"></i>收藏 {{ item.collectNumber }}</div>
                  </el-card>
                </div>
              </el-tab-pane>
              <el-tab-pane label="正在审核" name="inReview">
                <div>
                  <el-card v-for="item in inReviewFiles" :key="item.fileId">
                    <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete" @click="handleDelete(item)"/>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                  </el-card>
                </div>
              </el-tab-pane>
              <el-tab-pane label="审核通过" name="approved">
                <div>
                  <el-card v-for="item in approvedFiles" :key="item.fileId">
                    <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete" @click="handleDelete(item)"/>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                    <el-button class="download downloadBtn" icon="el-icon-download" :loading="downloading" @click="toggleDownload(item)">{{ item.downloadText }}  {{ item.downloadNumber }}</el-button>
                    <div class="infoRight postInfo"><i class="el-icon-chat-line-thumb"></i>评分 {{ item.score }}</div>
                    <div class="infoRight postInfo"><i class="el-icon-chat-line-star-off"></i>收藏 {{ item.collectNumber }}</div>
                  </el-card>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="box ads" v-loading="adsLoading">
            <div>
              <!-- <img :src="imga" alt="Base64 Image" class="adsImg"> -->
              <img v-for="(image, index) in ads" :key="index" :src="image" alt="Base64 Image" class="adsImg">
            </div>
          </div>
        </div>
      </el-main>

    </el-container>
  </div>
</template>
<script>
import {get_headline_news} from "@/network/home";
import {get_my_files, get_my_in_review_files, get_my_approved_files, delete_file, download_file,
  collect_file, uncollect_file, get_ads} from "@/network/file";
export default {
  name: "CommunityHome",
  data() {
    return {
      email: '',
      keywords: '',
      activeName: 'all',
      inReviewFiles: [{ fileId: 1, file_name: '', create_date: '',
        content: '', isFollow: 0, followedText: '+关注', collectNumber: 0, score: 0,
        isCollect: 0, downloadText: '下载', collectText: '收藏', downloadNumber: 0}],
      approvedFiles: [{ fileId: 1, file_name: '', create_date: '',
        content: '', isFollow: 0, followedText: '+关注', collectNumber: 0, score: 0,
        isCollect: 0, downloadText: '下载', collectText: '收藏', downloadNumber: 0}],
      myFiles: [{ fileId: 1, file_name: '', create_date: '',
        content: '', isFollow: 0, followedText: '+关注', collectNumber: 0, score: 0,
        isCollect: 0, downloadText: '下载', collectText: '收藏',downloadNumber: 0, approve: 0

      }],
      downloadedFiles: [],
      collectedFiles: [],
      inReviewFilesAll: [],
      approvedFilesAll: [],
      myFilesAll: [],
      newsLoading: true,
      FilesLoading: true,
      adsLoading: true,
      downloading: false,
      ads: [],
      headlineNews: [
        {
          "newsType": '',
          "fid": 0,
          "infoType": 0,
          "dpName": 0,
          "file_name": "加载中",
          "link": "https://www.xsaifor.com/"
        }
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    handleClear() {
      this.resetDisplayFiles();
      // this.headlineNews = this.headlineNewsAll;
      // this.hadleGetFilesListApi();//刷新页面
    },
    handleSearch() {
      this.inReviewFiles = this.inReviewFilesAll.filter((p)=>{
        return p.file_name.indexOf(this.keywords) !== -1;
      })
      this.approvedFiles = this.approvedFilesAll.filter((p)=>{
        return p.file_name.indexOf(this.keywords) !== -1;
      })
      this.allFiles = this.allFilesAll.filter((p)=>{
        return p.file_name.indexOf(this.keywords) !== -1;
      })
      // this.headlineNews = this.headlineNewsAll.filter((p)=>{
      //   return p.file_name.indexOf(this.keywords) !== -1;
      // })
      //   this.hadleGetFilesListApi();//刷新页面
      // },
    },
    resetDisplayFiles() {
      this.inReviewFiles = this.inReviewFilesAll;
      this.approvedFiles = this.approvedFilesAll;
      this.myFiles = this.myFilesAll;
    },
    newsRowClicked(row) {  //跳转到原文
      //location.href=row.link // 直接跳转
      window.open(row.link, '_blank')  //打开新页面跳转
    },
    handleClickDropdown(command) {
      if (command === "logout") {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push('/login/');
        })
      }
      else if (command === "profile") {
        this.$router.push('/profile/');
      }
      else if (command === "resetPsd") {
        this.$router.push('/reset_password/');
      }
    },
    handleDelete(item) {
      event.stopPropagation();
      this.$confirm('是否要删除文件', '系统提示', {
        confirmButtonText: '确认',
        cancelButtonText: '返回',
        type: 'info'
      }).then(() => {
        delete_file(this.email, item.fileId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已删除文件'
            });
            this.fetchFiles();
          }
          else {
            this.$message({
              type: 'warning',
              message: '删除文件失败，请稍后重试'
            });
          }
        })
      }).catch(() => {});
      document.activeElement.blur();
    },
    tabChange() {
      if (this.keywords !== '') {
        this.fetchFiles();
        this.keywords = '';
      }
    },
    toggleDownload(item) {
      this.downloading = true;
      event.stopPropagation();
      download_file(this.email, item.fileId);
      //const url = "https://postgraduate.xsaifor.com/prod-api/files/download_file?email="+this.email+'&fid='+item.fileId;
      //window.open(url);
      //item.downloadNumber ++;
      setTimeout(() => {
        this.fetchFiles();
        // item.downloadNumber += item.downloadText === '已下载'? 0 : 1;
        // item.downloadText = item.downloadText === '已下载'? '下载' : '已下载';
        this.downloading = false;
      }, 8000)
      document.activeElement.blur();
    },
    toggleCollect(item) {
      event.stopPropagation();
      item.collectText = item.collectText === '已收藏'? '收藏' : '已收藏';
      if (item.isCollect === 0) {
        item.isCollect = 1;
        item.collectNumber ++;
        collect_file(this.email, item.fileId, item.ownerId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已收藏'
            });
            this.fetchFiles();
          }
          else {
            item.isCollect = 0;
            item.collectNumber --;
            item.collectText = item.collectText === '已收藏'? '收藏' : '已收藏';
            this.$message({
              type: 'warning',
              message: '收藏失败，请稍后重试'
            });
          }
        })
      }
      else {
        item.isCollect = 0;
        item.collectNumber --;
        uncollect_file(this.email, item.fileId, item.ownerId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已取消收藏'
            });
            this.fetchFiles();
          }
          else {
            item.isCollect = 1;
            item.collectNumber ++;
            item.collectText = item.collectText === '已收藏'? '收藏' : '已收藏';
            this.$message({
              type: 'warning',
              message: '取消收藏失败，请稍后重试'
            });
          }
        })
      }
      // item.isCollect = item.isCollect === 1 ? 0 : 1;
      document.activeElement.blur();
    },
    handlePostClick(item) {
      this.$router.push({
        name: 'post', params: {
          post: item
        }
      });
    },
    handleMyFilesClick() {
      this.$router.push({
        name: 'files_view', params: {
          uid: this.email
        }
      });
    },
    fetchFiles() {
      get_my_in_review_files(this.email).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          this.inReviewFiles = res.data
          this.inReviewFiles.forEach(post => {
            post['followedText'] = post.isFollow === 0 ? '+关注' : '已关注';
            post['collectText'] = post.isCollect === 0 ? '收藏' : '已收藏';
            post['downloadText'] = post.isDownloaded === 0 ? '下载' : '已下载';
            post['isMyPost'] = (post.ownerId === this.email);
            this.inReviewFilesAll = this.inReviewFiles;
          });
        }
        else {
          this.inReviewFiles = [];
        }
      }).catch((error) => {
        console.log(error);
      });
      get_my_approved_files(this.email).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          this.approvedFiles = res.data
          this.approvedFiles.forEach(post => {
            post['followedText'] = post.isFollow === 0 ? '+关注' : '已关注';
            post['collectText'] = post.isCollect === 0 ? '收藏' : '已收藏';
            post['downloadText'] = post.isDownloaded === 0 ? '下载' : '已下载';
            post['isMyPost'] = (post.ownerId === this.email);
            this.approvedFilesAll = this.approvedFiles;
          });
        }
        else {
          this.approvedFiles = [];
        }
      }).catch((error) => {
        console.log(error);
      });
      get_my_files(this.email).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          this.myFiles = res.data
          this.myFiles.forEach(post => {
            post['followedText'] = post.isFollow === 0 ? '+关注' : '已关注';
            post['collectText'] = post.isCollect === 0 ? '收藏' : '已收藏';
            post['downloadText'] = post.isDownloaded === 0 ? '下载' : '已下载';
            post['isApprove'] = post.approve;
            post['isMyPost'] = (post.ownerId === this.email);
            this.myFilesAll = this.myFiles;
          });
        }
        else {
          this.myFiles = [];
        }
        this.FilesLoading = false;
      }).catch((error) => {
        console.log(error);
      });
    },
  },
  created() {
    this.email = this.$store.getters.email;
    get_headline_news(this.email).then((res) => {
      if (res !== undefined && res.files !== undefined) {
        this.headlineNews = res.files;//后端数据查询时以控制有序
      }
      else {
        this.headlineNews = [];
      }
      this.newsLoading = false;
    }).catch((error) => {
      console.log(error);
    });
    get_ads(this.email).then((res) => {
      if (res !== undefined && res.data !== undefined) {
        var str = new TextDecoder().decode(res.data)
        var json = JSON.parse(str)
        json.forEach(ad => {
          let str = ad.replace(/\. +/g, '')
          str = str.replace(/[\r\n]/g, '')
          this.ads.push(str)
        });
      }
      else {
        this.ads = [];
      }
      this.adsLoading = false;
    }).catch((error) => {
      console.log(error);
    });
    // console.log(this.ads);
    this.fetchFiles();
  },
}
</script>

<style scoped>
/* .home {
  overflow: hidden;
} */
.el-container {
  margin: 0px;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.search ::v-deep .el-input__inner{
  height:40px;
  border-radius: 40px;
  width: 600px;
  float:right;
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
}
.search ::v-deep .el-input__inner:focus{
  border-color: #7a557b;
}
.searchRow {
  height: 40px;
  /* margin-top: 60px; */
}
.el-header {
  position: fixed;
  top: 0;
  left:0;
  width:100%;
  justify-content: center;
  align-items: center;
  background-color: #7a557b;
  z-index: 1000;
  overflow: hidden;
}

.app_header .el-button {
  color: white;
  margin: 10px;
}

.el-main {
  /* background-color: #f3e6f6; */
  color: #333;
  text-align: center;
  flex: 1;
  overflow: hidden;
}

.belowTop {
  margin-top: 60px;
  height: calc(100vh - 60px);
  width: 100%;
  flex: 1;
}

.mainButton {
  position: absolute;
  top: 0;
  margin-top: 0;
}

.homeBtn {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 30px;
  margin-left: 30px;
  padding: 0;
  top: 3px;
  left: 0;
}

.backBtn {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  color: white;
  background-color: transparent;
  margin-top: 20px;
  left: 100px;
  border: none;
  font-size: 15px;
}

.app_dropdown {
  position: absolute;
  right: 0;
  top: 20px;
  margin-right: 30px;
}

.el-dropdown-menu__item:hover {
  background-color: #f3e6f6;
  color: #7a517c;
}

.el-icon-user-solid {
  color: white;
  font-size: 20px;
}

#btnsCol {
  text-align: start;
}

.upperBtns {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  align-items: center;
  border: 1px#7a557b;
  height: 40px;
  color: #7a557b;
  border: 1px solid #7a557b;
  /* margin-top: 60px; */
}

.upperBtns:hover {
  color: #f3e6f6;
  background-color: #7a557b;
}

.boxes {
  margin-top: 40px;
  display: flex;
  flex: 1;
  gap: 10px;
}

.box {
  /* flex: 1; */
  border: 1px solid #ccc;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  border-radius: 10px;
}

.news, .ads {
  width: 25%;
}


.adsImg {
  max-width: 90%;
  margin: 10px;
}

.Files {
  width: 50%;
}

::v-deep .el-tabs__item.is-active {
  color: #7a557b !important;
  background-color: #f3e6f6;
}

::v-deep .el-tabs__item:hover {
  color: #7a557b !important;
}

/* Files CSS */
.cardDiv {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.el-card {
  text-align: start;
}
.postBtn {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  align-items: center;
  padding: 5px;
  float: right;
  font-size: small;
  border: 1px#7a557b;
  height: 25px;
  width: 50px;
  margin: 10px 0;
  color: #7a557b;
}
.followBtn {
  border: 1px dashed #7a557b;
}
.followBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}
.markBtn {
  width: 80px;
  border: 1px #7a557b;
}
.markBtn:focus {
  background-color: white !important;
  border: 0;
}
.markBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}
.collectBtn {
  width: 80px;
  margin-right: 5px;
  border: 1px #7a557b;
}
.collectBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}

.download {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  align-items: center;
  padding: 5px;
  float: left;
  font-size: small;
  border: 1px#7a557b;
  height: 25px;
  width: 50px;
  margin: 10px 0;
  color: #7a557b;
}
.downloadBtn {
  width: 80px;
  margin-right: 5px;
  border: 1px #7a557b;
}
.downloadBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}

.postTitle {
  font-size: 16px;
  margin-top: 10px;
}
.nickname {
  font-size: 12px;
}
.postTime {
  margin: 5px 0;
  font-size: 11px;
}
.comment {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  font-size: small;
  height: 25px;
  margin: 10px 0;
  color: #7a557b;
  align-content: center;
  float: left;
  color: #333;
}
.el-icon-chat-line-square {
  padding-right: 5px;
}
.postContent {
  margin-top: 20px;
  font-size: 13px;
}
.postInfo {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  font-size: small;
  height: 25px;
  margin: 10px 0;
  color: #7a557b;
  align-content: center;
  color: #333;
}
.infoRight {
  float: right;
  margin-left: 10px;
}
</style>