import request from '@/utils/request'

// 密码登录方法
export function login_with_password(email, password) {
  const data = {
    email,
    password
  }
  return request({
    url: '/login/login_password',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 邮箱验证登录方法
export function login_with_email(email, vercode, vercode_user) {
  const data = {
    email,
    vercode,
    vercode_user
  }
  return request({
    url: '/login/login_email',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 发送邮件验证码方法
export function send_email(email, vercode) {
  const data = {
    email,
    vercode
  }
  return request({
    url: '/login/sent_email',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: 'login/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 忘记密码
export function forget_password_request(email) {
  const data = {
    email: email
  }
  return request({
    url: '/login/reset_password_request',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 重置密码
export function forget_password(email, password) {
  const data = {
    email: email,
    password: password
  }
  return request({
    url: '/login/reset_password',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}


// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}