import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken, setToken, removeToken } from '@/utils/auth'
import fa from "element-ui/src/locale/lang/fa";

Vue.use(VueRouter)

const login = () => import('@/views/Login.vue')
const home = () => import('@/views/Home.vue')
const community = () => import('@/views/Community.vue')
const community_home = () => import('@/components/CommunityHome.vue')
const community_post = () => import('@/components/CommunityPost.vue')
const create_post = () => import('@/components/CreatePost.vue')
const ai = () => import('@/views/AI.vue')
const ai_home = () => import('@/components/AiHome.vue')
const ai_report = () => import('@/components/AiReport.vue')
const file = () => import('@/views/File.vue')
const file_home = () => import('@/components/FileHome.vue')
const upload_file = () => import('@/components/UploadFile.vue')
const my_files = () => import('@/components/MyFiles.vue')
const contact_us = () => import('@/components/ContactUs.vue')
const notice_announcement = () => import('@/components/NoticeAnnouncement.vue')
const headline_news = () => import('@/components/HeadlineNews.vue')
const comprehensive_news = () => import('@/components/ComprehensiveNews.vue')
const training_units = () => import('@/components/trainingUnits.vue')
const department_summary = () => import('@/components/DepartmentSummary.vue')
const collected_news = () => import('@/components/CollectedNews.vue')
const collected_dps = () => import('@/components/CollectedDepartments.vue')
const notification = () => import('@/views/Notification.vue')
const profile = () => import('@/views/Profile.vue')
const my_info = () => import('@/components/MyInfo.vue')
const my_follow = () => import('@/components/MyFollow.vue')
const my_drafts = () => import('@/components/MyDrafts.vue')
const my_posts = () => import('@/components/MyPosts.vue')
const membership = () => import('@/components/Membership.vue')
const reset_email = () => import('@/components/ResetEmail.vue')
const reset_password = () => import('@/components/ResetPassword.vue')
const reset_information = () => import('@/components/ResetInformation.vue')
const forget_password = () => import('@/components/forgetPassword.vue')
const forget_password_request = () => import('@/components/forgetPassword_request.vue')
const start = () => import('@/views/Start.vue')
const course = () => import('@/views/Course.vue')
const course_home = () => import('@/components/CourseHome.vue')
const intelligence = () => import('@/views/Intelligence.vue')
const mail = () => import('@/components/Mail.vue')
const mail_templates = () => import('@/components/MailTemplates.vue')
const plans = () => import('@/components/Plans.vue')
const new_plan = () => import('@/components/PlanNew.vue')
const timeline = () => import('@/components/PlansTimeline.vue')

const routes = [
  {
    path:'',
    redirect:'/start'
  },
  {
    path:'/start',
    component:start,
  },
  {
    path: '/login',
    component:login,
    children:[
      {
        path:'',
        redirect:'/start'
      },
      {
        path:'reset_password_request',
        component:forget_password_request
      },
      {
        path:'reset_password',
        component:forget_password
      },
    ]
  },
  {
    path:'/contact_us',
    component:contact_us
  },
  {
    path: '/home', //主页
    component:home,
    children:[
      {
        path:'',
        redirect:'headline_news'
      },
      {
        path:'headline_news',
        component:headline_news
      },
      // {
      //   path:'comprehensive_news',
      //   component:comprehensive_news
      // },
      {
        path:'training_units',
        component:training_units
      },
      {
        path:'department_summary/:dpId',
        component:department_summary,
      },
      {
        path: 'collected_news',
        component: collected_news,
      },
      {
        path: 'collected_dps',
        component: collected_dps,
      },
    ]
  },
  {
    path: '/intelligence',
    component:intelligence,
    children:[
      {
        path:'',
        redirect:'mail'
      },
      {
        path:'mail',
        component:mail
      },
      {
        path:'mail_templates',
        component:mail_templates
      },
      {
        path:'timeline',
        component:timeline
      },
      {
        path:'plans',
        component:plans
      },
      {
        path:'new_plan',
        name :'new_plan',
        component:new_plan
      },
    ]
  },
  {
    path: '/community',
    component:community,
    children:[
      {
        path:'',
        redirect:'home'
      },
      {
        path:'home',
        component: community_home
      },
      {
        path:'post',
        name: 'post',
        component: community_post
      },
      {
        path:'create_post',
        name: 'draft_post',
        component: create_post
      },
      {
        path:'create_post',
        name: 'draft_post',
        component: create_post
      },
      {
        path:'posts_view',
        name: 'posts_view',
        component: my_posts
      },
    ]
  },
  {
    path: '/file',
    component: file,
    children:[
      {
        path:'',
        redirect:'home'
      },
      {
        path:'home',
        component: file_home
      },
      {
        path:'post',
        name: 'post',
        component: community_post
      },
      {
        path:'upload_file',
        component: upload_file
      },
      {
        path:'files_view',
        name: 'files_view',
        component: my_files
      },
    ]
  },
  {
    path: '/course',
    component: course,
    children:[
      {
        path:'',
        redirect:'home'
      },
      {
        path:'home',
        component: course_home
      },
    ]
  },
  {
    path: '/notification',
    component:notification,
  },
  {
    path:'/reset_password',
    component:reset_password
  },
  {
    path:'/ai',
    component:ai,
    children:[
      {
        path:'',
        redirect:'home'
      },
      {
        path:'home',
        component: ai_home
      },
      {
        path:'personal_report',
        component: ai_report
      },
    ]
  },
  {
    path: '/profile',
    component:profile,
    children:[
      {
        path:'',
        redirect:'my_info'
      },
      {
        path:'my_info',
        component:my_info
      },
      {
        path:'my_drafts',
        component:my_drafts
      },
      {
        path:'reset_email',
        component:reset_email
      },
      {
        path:'reset_information',
        component:reset_information
      },
      {
        path:'my_follow',
        name: 'my_follow',
        component: my_follow
      },
      {
        path:'membership',
        name: 'membership',
        component: membership
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history', //网页
  //mode: 'hash', //electron
  base: process.env.BASE_URL,
  routes
})

//鉴权
router.beforeEach((to, from, next) => {
  if (to.path === '/start'||to.path === '/login/login_password'||to.path === '/login/login_email'||to.path === '/login/register'
      ||to.path === '/login/reset_password_request'||to.path === '/login/reset_password') {
    next();
  } else {
    let token = getToken();
    if (token === 'null' || token === ''|| typeof token ==='undefined') {
      next(''); //鉴权
      //next(); // 不鉴权
    } else {
      next();
    }
  }
})
export default router

//前端路由的path是网站的真实url，与后端的接口url可以不一样，但为了方便区分不用网页，最好成一样的
