<template>
  <el-dialog title="评分" :visible.sync="visible" :before-close="handleClose" :append-to-body="true">
    <div class="popup-content">
      <div class="stars">
        <h3>选择分数后提交即可评分</h3>
        <div v-for="mark in 10" :key="mark" @click="rate(mark)" :class="{ filled: mark <= selectedMark }"
            class="markDiv">{{mark}}</div>
      </div>
      <el-button class="closeBtn" @click="closePopup">关闭</el-button>
      <el-button class="submitBtn" @click="submitRating">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {mark_file} from "@/network/file";
export default {
  name: 'ScorePopup',
  data() {
    return {
      visible: false,
      selectedMark: 0,
      fileId: -1,
      updateInsertOpen: false,
    };
  },
  methods: {
    openPopup(fileId) {
      this.visible = true;
      this.$nextTick(() => {
        this.selectedMark = 0; // 重置评分
        this.fileId = fileId;
      })
    },
    initPopup() {
      this.visible = false;
    },
    handleClose(done) {
      this.visible = false;
      done();
    },
    closePopup() {
      this.visible = false;
      this.$forceUpdate();
      // 这里可以添加提交评分的逻辑
    },
    submitRating() {
      let email = this.$store.getters.email;
      event.stopPropagation();
      this.$confirm('是否确认提交评分', '系统提示', {
        confirmButtonText: '确认',
        cancelButtonText: '返回',
        type: 'info'
      }).then(() => {
        mark_file(email, this.fileId, this.selectedMark) .then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已提交评分'
            });
          }
          else {
            this.$message({
              type: 'warning',
              message: '提交评分失败，请稍后重试'
            });
          }
        })
        this.$forceUpdate();
      }).catch(() => {});
      document.activeElement.blur();
      this.visible = false;
    },
    rate(mark) {
      this.selectedMark = mark;
    },
  },
};
</script>

<style scoped>
.score-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f3e6f6;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.markDiv {
  border: solid 1px #ccc;
  display: inline-block;
  border-radius: 3px;
  width: 30px;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
  color: #ccc;
  font-size: 24px;
}

.markDiv.filled {
  background-color: #7a557b;
  border-color: #7a557b;
}

.markDiv:hover {
  background-color: #f3e6f6;
  color: #7a557b
}

.closeBtn {
  background-color: white;
  color: #7a557b;
  border: #7a557b solid 1px;
}

.submitBtn {
  background-color: #7a557b;
  color: #f3e6f6;
  border: #7a557b solid 1px;
}

.el-button {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  margin-top: 20px;
}

.el-button:hover {
  background-color: #f3e6f6;
  border-color: #f3e6f6;
  color: #7a557b;
}

.popup-content {
  text-align: center;
}
</style>