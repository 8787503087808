<template>
  <el-dialog class="dialog" :visible.sync="visible" :before-close="handleClose" :append-to-body="true">
    <div >
    <el-row type="flex" justify="center" class="formRow">
      <div class="title">{{ temp.title }}</div>
    </el-row>
    <div v-loading="loading" class="templatesDiv">
      {{ temp.content }}
    </div>
  </div>
  <div class="btnDiv">
    <el-button class="btns closeBtn" @click="handleClose">关闭</el-button>
    <el-button class="btns useBtn" @click="handleUseTemplateClick">使用</el-button>
  </div>
  </el-dialog>
</template>

<script>

export default {
  name: "MailTemplateSingle",
  props: { template: Object },
  data() {
    return {
      visible: false,
      loading: true,
      email: '',
      temp: {},
      mailRowIdx: -1,
    }
  },
  methods: {
    handleUseTemplateClick(item) {
      event.stopPropagation();
      this.$emit("childFn", this.mailRowIdx, this.temp.temId);
      this.handleClose();
    },
    openPopup(template, idx) {
      this.visible = true;
      this.temp = template;
      this.mailRowIdx = idx;
    },
    initPopup() {
      this.visible = false;
    },
    handleClose() {
      this.visible = false;
    },
  },
  created() {
    this.email = this.$store.getters.email;
    this.loading = false;
  },
}
</script>

<style scoped>
.btnDiv {
  text-align: center;
}
.btns {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  font-size: 15px;
  width: 100px;
}
.closeBtn {
  margin-left: 20px;
  background-color: white;
  color: #7a557b;
}

.btns:hover {
  background-color: #f3e6f6;
  color: #7a557b;
  border: #f3e6f6 1px solid;
}
::v-deep .el-dialog {
  margin: 40px auto;
  width: 85%;
  max-height: 600px;
  overflow-y: auto;
}
.useBtn {
  background-color: #7a557b;
  color: #f3e6f6;
  border: #7a557b 1px solid;
}
.template {
  margin-top: 20px;
  width: 85%;
  height: 200px;
  padding: 10px;
  position: relative;
}
.templatesDiv {
  margin: 10px 0;
  white-space: pre-wrap;
  line-height: 1.6;
  text-align: start;
}
.backBtn {
  height: 40px;
  align-items: center;
  margin-right: 20px;
  border: none;
}
.backBtn:hover {
  color: #f3e6f6;
  background-color: #7a557b;
}
.title {
  margin-right: 20px;
  border-radius: 10px;
  border: 1px #7a557b solid;
  background-color: white;
  color: #7a557b;
  text-align: center;
  align-content: center;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  width: 85%;
}
</style>