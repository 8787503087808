import request from '@/utils/request'

// 修改邮箱
export function request_reset_email(email, new_email, password) {
    const data = {
        email: email,
        new_email: new_email,
        password: password
    }
    return request({
        url: '/profile/reset_email',
        method: 'post',
        data: data
    })
}

// 重置密码
export function request_reset_password(email, password, new_password) {
    const data = {
        email: email,
        password: password,
        new_password: new_password
    }
    return request({
        url: '/profile/reset_password',
        method: 'post',
        data: data
    })
}

// 重置信息
export function request_reset_information(email, nickname, name, sex, birthday, school, major, studentNumber, password) {
    const data = {
        email: email,
        nickname: nickname,
        name: name,
        sex: sex,
        birthday: birthday,
        school: school,
        major: major,
        studentNumber: studentNumber,
        password: password,
    }
    return request({
        url: '/profile/reset_information',
        method: 'post',
        data: data
    })
}

// 获取信息
export function request_get_information(email) {
    const data = {
        email: email
    }
    return request({
        url: '/profile/get_information',
        method: 'post',
        data: data
    })
}

//更新头像
export function request_upload_head_portrait(email, head_Portrait) {
    const data = {
        email: email,
        head_Portrait: head_Portrait
    }
    return request({
        url: '/profile/reset_head_Portrait',
        method: 'post',
        data: data
    })
}


// 请求头像
export function request_head_portrait(email) {
    return request({
        url: '/profile/get_head_Portrait',
        method: 'get', //必须写成get， 而get方法向后端传参也只能是params，而不是json；若为post，则前端得不到后端传来的数据流
        // responseType:"blob",
        responseType:"arraybuffer",
        params: {
            email: email,
        }
    })
}