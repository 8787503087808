<template>
  <div class = "notice_main">
    <el-container>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>

export default {
  name: "Profile.vue",
  data() {
    return {
      activeIndex: this.$route.path,
      pageName: "AIFOR - 通知",
      notices: [],
      items: [
        { type: '', label: '消息一\n内容' },
        { type: 'success', label: '标签二' },
        { type: 'info', label: '标签三' },
        { type: 'danger', label: '标签四' },
        { type: 'warning', label: '标签五' }
      ]
    }
  },
  methods: {
    handleSelect(key) {
      if (this.activeIndex !== key) {
        this.activeIndex = key;
        this.$router.push(key);
      }
    },
    goBack(){
      this.$router.back();
      this.$store.commit("SET_OPEN_STATE",'exist');
      console.log('go back');
    },
  },
  created() {
    const payload = this.$store.getters.email;
    // get_notifications(payload).then((res) => {
    //   if (res !== undefined && res.notices !== undefined) {
    //     this.notices = res.notices;
    //   }
    //   else {
    //     this.notices = []
    //   }
    // }).then(() =>{
    //   mark_notifications_read(payload, this.notices);//请求标记为已读
    // }).catch((error) => {
    //   console.log(error);
    // })
  }
}
</script>

<style scoped>
  .el-main {
    margin: 0;
    padding: 0;
  }

  .el-container {
    margin: 0;
  }
</style>
