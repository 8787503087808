<template>
  <div class="page">
    <el-page-header @back="goBack" content="DAWN - 联系我们"/>
    <el-container>
      <el-main>
        <el-row>
          <h1 class="slogan">研路上岸</h1>
        </el-row>
<!--        <el-row><br></el-row>-->
        <el-row><h1>联系我们</h1></el-row>
        <el-row>联系邮箱：support@xsaifor.com</el-row>
        <el-row>QQ：3844186998</el-row>
        <el-row>公众号：研路上岸</el-row>
<!--        <el-row>-->

<!--          企业微信：暂无-->
<!--        </el-row>-->
        <el-row><h1>加入我们</h1></el-row>
        <el-row>HR邮箱：hr@xsaifor.com</el-row>
        <el-row>招兼职：算法实习生、保研讲师、教研老师</el-row>
      </el-main>
      <!--      <el-aside width="10%"></el-aside>-->
    </el-container>
    <el-footer>
      <p>版权所有 © 2024 夏岁智能科技&nbsp;&nbsp;主办单位：北京夏岁智能科技有限公司</p>
<!--      <p>-->
<!--        主办单位：北京中科方遒科技有限公司&nbsp;&nbsp;-->
<!--        &lt;!&ndash;        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_parent">京ICP备xxxxxxx号</a>&ndash;&gt;-->
<!--      </p>-->
      <p>ICP备案号：
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP备2022011413号</a>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo/"><img src="/img/备案图标.png" style="float:left;"/>京公网安备 11010802039480号</a>
      </p>
    </el-footer>
  </div>
</template>

<script>

export default {
  name: "ContactUs",
  data() {

    return {

    };

  },
  methods: {
    jump_to_csfqiu() {
      //location.href="https://www.xsaifor.com/" // 直接跳转
      window.open("https://www.xsaifor.com/", '_blank')  //打开新页面跳转
    },
    jump_to_department_summary() {
      //location.href="https://www.ucas.ac.cn/site/18"
      window.open("https://www.ucas.ac.cn/site/18", '_blank')
    },
    jump_to_training_unit() {
      // location.href="https://www.ucas.ac.cn/site/3"
      window.open("https://www.ucas.ac.cn/site/3", '_blank')
    },
    goBack() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>

/* Top Bar CSS Start */
.el-page-header {
  position: fixed;
  top: 0;
  left:0;
  padding-left: 20px;
  width:100%;
  height: 60px;
  justify-content: start;
  align-items: center;
  background-color: #7a557b;
  color: white;
  overflow-y:hidden;
}

::v-deep .el-page-header__content {
  color: white;
}

.el-main {
  color: #303133;
  text-align: center;
  margin-top: 60px;
  overflow-x:hidden;
  height: calc(100vh - 60px - 60px);
}

.slogan {
  color: #676767;
  font-family: '华文行楷';
  font-weight: 60;
  font-size: 50px;
}

p {
  font-size: 12px;
  font-weight: bold;
}

a {
  display:inline-block;
  text-decoration:none;
  height:20px;
  line-height:20px;
  font-weight: 300px;
}

.el-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px !important;
  background-color: #d6c5db;
  color: white;
  overflow-y:hidden;
}

.el-row {
  margin-bottom: 20px;
}

</style>