import request from '@/utils/request'

// 上传文件
// reutrn: flag: int
export function upload_file(formdata, config) {
    return request({
        url: '/files/upload_file',
        method: 'post',
        data: formdata,
        headers:config.headers
    })
}

// 获取自己所有关注用户发的文件，对应的是前端Tab“关注”栏展示的数据
// return: data: []
export function get_followed_files(email) {
    const data = {
        email: email
    }
    return request({
        url: '/files/get_followed_files',
        method: 'post',
        data: data
    })
}

// 获取热门的文件，对应的是前端Tab“热门”栏展示的数据
// return: data: []
export function get_popular_files(email) {
    const data = {
        email: email
    }
    return request({
        url: '/files/get_popular_files',
        method: 'post',
        data: data
    })
}


// // 获取指定用户发布的文件
// // return: data: []
// export function get_user_files(email, uid) {
//     const data = {
//         email: email,
//         uid: uid
//     }
//     return request({
//         url: '/community/get_user_cards',
//         method: 'post',
//         data: data
//     })
// }

// 获取所有的文件
// return: data: []
export function get_files(email) {
    const data = {
        email: email
    }
    return request({
        url: '/files/get_all_files',
        method: 'post',
        data: data
    })
}

// 获取自己发布的文件
// return: data: []
export function get_my_files(email) {
    const data = {
        email: email
    }
    return request({
        url: '/files/get_my_upload_files',
        method: 'post',
        data: data
    })
}

// 获取自己发布待审核的文件
// return: data: []
export function get_my_in_review_files(email) {
    const data = {
        email: email
    }
    return request({
        url: '/files/get_my_in_review_files',
        method: 'post',
        data: data
    })
}


// 获取自己发布已审核通过的文件
// return: data: []
export function get_my_approved_files(email) {
    const data = {
        email: email
    }
    return request({
        url: '/files/get_my_approved_files',
        method: 'post',
        data: data
    })
}

// 删除文件
// return: flag: int
export function delete_file(email, fileId) {
    const data = {
        email: email,
        fid: fileId
    }
    return request({
        url: '/files/delete_file',
        method: 'post',
        data: data
    })
}

// 给某文件打分
// return: flag: int
export function mark_file(email, fileId, score) {
    const data = {
        email: email,
        fid: fileId,
        score: score
    }
    return request({
        url: '/files/mark_file',
        method: 'post',
        data: data
    })
}

// 收藏某文件
// return: flag: int
export function collect_file(email, fileId) {
    const data = {
        email: email,
        fid: fileId
    }
    return request({
        url: '/files/collect_file',
        method: 'post',
        data: data
    })
}

// 取消收藏某文件
// return: flag: int
export function uncollect_file(email, fileId) {
    const data = {
        email: email,
        fid: fileId
    }
    return request({
        url: '/files/quit_collect_file',
        method: 'post',
        data: data
    })
}

// 获取自己收藏的文件
// return: data: []
export function get_all_collected_files(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/files/get_collected_files',
        method: 'post',
        data: data
    })
}

// 点赞某文件
// return: flag: int
export function download_file(email, fileId) {
    const data = {
        email: email,
        fid: fileId,
    }
    return request({
        url: '/files/download_file',
        method: 'get',
        params: data,
        responseType: 'blob'
    }).then(res => {
        // let mimeType;
        // switch (res.data.type) {
        //     case 'pdf':
        //         mimeType = 'application/pdf';
        //         break;
        //     case 'doc':
        //         mimeType = 'application/msword';
        //         break;
        //     case 'docx':
        //         mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        //         break;
        //     case 'txt':
        //         mimeType = 'text/plain;charset=utf-8';
        //         break;
        //     default:
        //         throw new Error('Unsupported file type');
        // }
        // let blob = new Blob([res.data],{type: mimeType});
        let blob = res.data;
        // 创建新的URL并指向File对象或者Blob对象的地址
        const blobURL = window.URL.createObjectURL(blob)
        // 创建a标签，用于跳转至下载链接
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        const contentDisposition = res.headers['content-disposition'] || 'attachment;filename=report';
        tempLink.setAttribute('download', decodeURI(contentDisposition.split(';')[1].split('=')[1]))
        // 兼容：某些浏览器不支持HTML5的download属性
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
        }
        // 挂载a标签
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        // 释放blob URL地址
        window.URL.revokeObjectURL(blobURL)
    });
}


// 获取自己点赞的文件
// return: data: []
export function get_all_downloaded_files(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/files/get_downloaded_files',
        method: 'post',
        data: data
    })
}


// 关注指定用户
// return: flag: int
export function follow_user(email, uid) {
    const data = {
        email: email,
        uid: uid
    }
    return request({
        url: '/community/follow_user',
        method: 'post',
        data: data
    })
}

// 取消关注指定用户
// return: flag: int
export function unfollow_user(email, uid) {
    const data = {
        email: email,
        uid: uid
    }
    return request({
        url: '/community/quit_followed_user',
        method: 'post',
        data: data
    })
}


// ：获取所有图片格式的广告，尺寸不用前端调整，后端会控制广告图的宽是一致的，但高度可能不一致。
// return: 这里返回的不是JSON 了，因为base64 数据不允许以JSON 格式返回。返回的内容直接是一个base64 图片列表
export function get_ads(email) {
    const data = {
        email: email
    }
    return request({
        url: '/community/get_all_ads',
        method: 'get',
        responseType: 'arraybuffer'
    })
}
