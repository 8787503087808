import { render, staticRenderFns } from "./ScorePopup.vue?vue&type=template&id=4c26f774&scoped=true"
import script from "./ScorePopup.vue?vue&type=script&lang=js"
export * from "./ScorePopup.vue?vue&type=script&lang=js"
import style0 from "./ScorePopup.vue?vue&type=style&index=0&id=4c26f774&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c26f774",
  null
  
)

export default component.exports