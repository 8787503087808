import request from '@/utils/request'

// 发布帖子
// reutrn: flag: int
export function create_post(email, content, filename) {
    const data = {
        email: email,
        content: content,
        filename: filename
    }
    return request({
        url: '/community/create_card',
        method: 'post',
        data: data
    })
}

// 获取自己所有关注用户发的帖子，对应的是前端Tab“关注”栏展示的数据
// return: data: []
export function get_followed_posts(email) {
    const data = {
        email: email
    }
    return request({
        url: '/community/get_all_followed_cards',
        method: 'post',
        data: data
    })
}

// 获取热门的帖子，对应的是前端Tab“热门”栏展示的数据
// return: data: []
export function get_hot_posts(email) {
    const data = {
        email: email
    }
    return request({
        url: '/community/get_hot_cards',
        method: 'post',
        data: data
    })
}

// 获取推荐的帖子，对应的是前端Tab“推荐”栏展示的数据
// return: data: []
export function get_suggest_posts(email) {
    const data = {
        email: email
    }
    return request({
        url: '/community/get_recommend_cards',
        method: 'post',
        data: data
    })
}

// 获取指定用户发布的帖子，对应的是前端“个人经验库”的数据
// return: data: []
export function get_user_posts(email, uid) {
    const data = {
        email: email,
        uid: uid
    }
    return request({
        url: '/community/get_user_cards',
        method: 'post',
        data: data
    })
}

// 获取所有的帖子
// return: data: []
export function get_posts(email) {
    const data = {
        email: email
    }
    return request({
        url: '/community/get_all_cards',
        method: 'post',
        data: data
    })
}

// 获取自己发布的帖子
// return: data: []
export function get_my_posts(email) {
    const data = {
        email: email
    }
    return request({
        url: '/community/get_my_cards',
        method: 'post',
        data: data
    })
}

// 删除帖子
// return: flag: int
export function delete_post(email, cardId) {
    const data = {
        email: email,
        cardId: cardId
    }
    return request({
        url: '/community/delete_card',
        method: 'post',
        data: data
    })
}

// 收藏某帖子
// return: flag: int
export function collect_post(email, cardId, ownerId) {
    const data = {
        email: email,
        cardId: cardId,
        ownerId: ownerId
    }
    return request({
        url: '/community/collect_card',
        method: 'post',
        data: data
    })
}

// 取消收藏某帖子
// return: flag: int
export function uncollect_post(email, cardId, ownerId) {
    const data = {
        email: email,
        cardId: cardId,
        ownerId: ownerId
    }
    return request({
        url: '/community/quit_collected_card',
        method: 'post',
        data: data
    })
}

// 获取自己收藏的帖子
// return: data: []
export function get_all_collected_posts(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/community/get_all_collected_cards',
        method: 'post',
        data: data
    })
}

// 点赞某帖子
// return: flag: int
export function like_post(email, cardId, ownerId) {
    const data = {
        email: email,
        cardId: cardId,
        ownerId: ownerId
    }
    return request({
        url: '/community/like_card',
        method: 'post',
        data: data
    })
}

// 取消点赞某帖子
// return: flag: int
export function unlike_post(email, cardId, ownerId) {
    const data = {
        email: email,
        cardId: cardId,
        ownerId: ownerId
    }
    return request({
        url: '/community/quit_liked_card',
        method: 'post',
        data: data
    })
}

// 获取自己点赞的帖子
// return: data: []
export function get_all_liked_posts(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/community/get_all_liked_cards',
        method: 'post',
        data: data
    })
}

// 保存草稿帖子
// return: flag: int
export function save_draft_post(email, content, filename, moId) {
    const data = {
        email: email,
        content: content,
        filename: filename,
        moId: moId,
    }
    return request({
        url: '/community/modify_drafts_cards',
        method: 'post',
        data: data
    })
}

// 创建新草稿帖子
// return: flag: int
export function save_new_draft_post(email, content, filename) {
    const data = {
        email: email,
        content: content,
        filename: filename
    }
    return request({
        url: '/community/create_drafts_card',
        method: 'post',
        data: data
    })
}

// 删除草稿帖子
// return: flag: int
export function delete_draft_post(email, cardId) {
    const data = {
        email: email,
        cardId: cardId
    }
    return request({
        url: '/community/delete_drafts_card',
        method: 'post',
        data: data
    })
}

// 获取自己的草稿帖子
// return: data: []
export function get_all_draft_posts(email) {
    const data = {
        email: email
    }
    return request({
        url: '/community/get_all_drafts_cards',
        method: 'post',
        data: data
    })
}

// 保存评论
// return: flag: int
export function make_comment(email, cardId, ownerId, content, fatherNode) {
    const data = {
        email: email,
        cardId: cardId,
        ownerId: ownerId,
        content: content,
        fatherNode: fatherNode
    }
    return request({
        url: '/community/comment_card',
        method: 'post',
        data: data
    })
}

// 删除指定评论
// return: flag: int
export function delete_comment(email, cardId, ownerId, comId) {
    const data = {
        email: email,
        cardId: cardId,
        ownerId: ownerId,
        comId: comId
    }
    return request({
        url: '/community/delete_commented_card',
        method: 'post',
        data: data
    })
}

// 获取指定帖子的所有评论
// return: data: []
export function get_all_comments(email, cardId) {
    const data = {
        email: email,
        cardId: cardId
    }
    return request({
        url: '/community/get_comments',
        method: 'post',
        data: data
    })
}

// 关注指定用户
// return: flag: int
export function follow_user(email, uid) {
    const data = {
        email: email,
        uid: uid
    }
    return request({
        url: '/community/follow_user',
        method: 'post',
        data: data
    })
}

// 取消关注指定用户
// return: flag: int
export function unfollow_user(email, uid) {
    const data = {
        email: email,
        uid: uid
    }
    return request({
        url: '/community/quit_followed_user',
        method: 'post',
        data: data
    })
}

// 获取自己关注的用户
// return: data: []
export function get_all_following(email) {
    const data = {
        email: email
    }
    return request({
        url: '/community/get_all_followed_users',
        method: 'post',
        data: data
    })
}

// 获取自己的所有粉丝
// return: data: []
export function get_all_followers(email) {
    const data = {
        email: email
    }
    return request({
        url: '/community/get_all_fans',
        method: 'post',
        data: data
    })
}

// ：获取所有图片格式的广告，尺寸不用前端调整，后端会控制广告图的宽是一致的，但高度可能不一致。
// return: 这里返回的不是JSON 了，因为base64 数据不允许以JSON 格式返回。返回的内容直接是一个base64 图片列表
export function get_ads(email) {
    const data = {
        email: email
    }
    return request({
        url: '/community/get_all_ads',
        method: 'get',
        responseType: 'arraybuffer'
    })
}
